import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useSnackbar } from "notistack";
import AppLoader from "components/AppLoader/Index";
import { sendInviteManagerApiCall } from "../../actions/Operations";
import {
  getMerchantListData,
  getManagerListData,
  getEmailList,
  getEmailData,
  emptyEmailListReduxState
} from "../../Redux/Actions/genrateOfferPatient";
import EmailAutofillManager from "../../views/UserProfile/EmailAutofillManager";
import { profileCodePermissionCheck } from "../../Utils/commonUtils";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    borderRadius: 8,
    maxWidth: 600,
    width: "90%",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(3),
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  },
}));

function formatPhoneNumber(v) {
  let r = v?.replace(/\D/g, "");
  if (r?.length > 11) {
    r = r?.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    return r;
  }
  if (r?.length > 6) {
    r = r?.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
  } else if (r?.length > 3) {
    r = r?.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
  } else {
    r = r?.replace(/^(\d*)/, "$1");
  }
  return r;
}

const InviteManagerModal = ({
  onClose,
  props,
  rows,
  Bussines_id,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = useState("");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [businessName, setBusinessName] = useState(null);
  const [businessType, setBusinessType] = useState();
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedUser, setSelectedUser] = React.useState({});
  const dispatch = useDispatch();
  const Profile_id = localStorage.getItem("profileId");
  const profileCode = menu?.Role_Permission_Data?.Profile_Code;

  const Manager_uid = JSON.parse(localStorage.getItem("Manager_uid"));
  const User_Id = localStorage.getItem("User_Id");
  const businessNameData = localStorage.getItem("businessName");
  React.useEffect(() => {
    if (Profile_id) {
      const payload = {
        Profile_id: Profile_id,
        recordPerPage: 999999,
      };
      props.getMerchantListData(payload);
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 999999,
      };
      props.getMerchantListData(payload);
    }
  }, [Profile_id]);

  React.useEffect(() => {
    if (businessNameData) {
      setBusinessName(
        <Select
          labelId="business-name"
          id="business-name"
          disabled
          value={businessNameData}
          required
        >
          {rows.map((row, index) => (
            <MenuItem value={`${row.Business_Name}`}>
              {row.Business_Name}
            </MenuItem>
          ))}
        </Select>
      );
    }
  }, [Bussines_id, businessNameData]);
  const onFormSubmit = () => {
    if (!firstName || !lastName || !email || !phoneNumber || !businessName) {
      enqueueSnackbar("Please fill out all required fields.", {
        variant: "error",
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      enqueueSnackbar("Please enter a valid email address.", {
        variant: "error",
      });
      return;
    }
    const startsWithNumberRegex = /^\d/;
    if (
      startsWithNumberRegex.test(firstName) ||
      startsWithNumberRegex.test(lastName)
    ) {
      enqueueSnackbar("First/Last name can not start with a number.", {
        variant: "error",
      });
      return;
    }
    setLoading(true);
    const businessId = Bussines_id == null ? selectedBusiness._id : Bussines_id;
    props.sendInviteManagerApiCall(
      {
        firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
        lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
        email: email.toLowerCase(),
        phoneNumber,
        businessName,
        Status_Code: 1,
        Bussines_id: businessId,
        Profile_id: Manager_uid,
        User_Id: User_Id,
        Type: `${profileCode === profileCodePermissionCheck.profileCode.VCS ? businessType : "sub"}`,
      },
      (res) => {
        setLoading(false);
        if (res.status === true) {
          enqueueSnackbar(`${res.message}`, {
            variant: "success",
          });
          props.history.push(
            `/${props.mainData.app.layout}/ManagerList${props.location.search}`
          );
          window.location.reload();
        }
      },
      (error) => {
        setLoading(false);
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      }
    );
  };
  const businessNameHandle = (e) => {
    setBusinessName(e.target.value);
    const selectedBusinessName = e.target.value;
    const selectedBusiness = props?.merchantListData?.merchantListData?.data.find(row => row.Business_Name === selectedBusinessName);
    setSelectedBusiness(selectedBusiness);
  };

  const handleEmailChange = (email, agentsList) => {
    const normalizedEmail = email?.toLowerCase();
    setEmail( normalizedEmail );

    if (normalizedEmail?.length >= 4) {
      fetchEmailList(normalizedEmail);
    } else {
      setFirstName((prevValues) => ({ ...prevValues, firstName: "" }));
      setLastName((prevValues) => ({ ...prevValues, lastName: "" }));
      setPhone((prevValues) => ({ ...prevValues, phoneNumber: "" }));
      dispatch(emptyEmailListReduxState());
    }

    const emailExists = agentsList?.includes(normalizedEmail);

    if (emailExists && /^\S+@\S+\.\S+$/.test(normalizedEmail)) {
      dispatch(getEmailData(normalizedEmail));
    }
  };
  
  const fetchEmailList = (email) => {
    const type = businessType === "main" ? "main" : "sub";
    dispatch(getEmailList(email, type));
  };
  
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setFirstName(user?.First_Name || "");
    setLastName(user?.Last_Name || "");
    setPhone(user?.Phone_Number || "");
  };

  React.useEffect(() => {
    if (props?.merchantListData?.emailListData?.data && email) {
      const emailExists = props.merchantListData.emailListData.data.includes(
        email
      );
      if (emailExists) {
        dispatch(getEmailData(email));
      }
    }
  }, [props?.merchantListData?.emailListData?.data, email]);

  React.useEffect(() => {
    if (props?.merchantListData?.emailData) {
      const {
        First_Name,
        Last_Name,
        Phone_Number,
      } = props?.merchantListData?.emailData;
      setFirstName(First_Name || "");
      setLastName(Last_Name || "");
      setPhone(Phone_Number || "");
    }
  }, [props?.merchantListData?.emailData]);

  React.useEffect(() => {
    return () => {
      dispatch(emptyEmailListReduxState());
    };
  }, [dispatch]);

  const handleEmailBlur = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      const response = await dispatch(getEmailData(email));
      if (response && response.data) {
        const { First_Name, Last_Name, Phone_Number } = response.data;
        setFirstName(First_Name || "");
        setLastName(Last_Name || "");
        setPhone(Phone_Number || "");
      }
    }
    if(!email){
      clearAllFields();
    }
  };

  const clearAllFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
  };

  return (
    <Modal open onClose={onClose}>
      <div className={classes.paper}>
        <AppLoader loader={loading} />
        <h2>Send Invitation</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className={classes.inputContainer}>
            <div className={classes.inputRow}>
              <FormControl fullWidth>
                <InputLabel htmlFor="business-name">Business Name</InputLabel>
                {Bussines_id == null ? (
                  <Select
                    labelId="business-name"
                    id="business-name"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    PopoverProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      PaperProps: {
                        style: {
                          marginTop: "100px",
                          top: "119px",
                        },
                      },
                    }}
                    value={businessName}
                    onChange={(e) => businessNameHandle(e)}
                    required
                  >
                    {props?.merchantListData?.merchantListData &&
                      props?.merchantListData?.merchantListData?.data
                        .filter((item) => item.Status_Code === 3)
                        .map((row) => (
                          <MenuItem value={`${row.Business_Name}`}>
                            {row.Business_Name}
                          </MenuItem>
                        ))}
                  </Select>
                ) : (
                  businessName
                )}
              </FormControl>
            </div>
            <div>
              {profileCode === profileCodePermissionCheck.profileCode.VCS && (
                <div>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="business-name">
                      Manager Type
                    </InputLabel>
                    <Select
                      labelId="business-name"
                      id="business-name"
                      onChange={(e) => {
                        setBusinessType(e.target.value);
                        clearAllFields(); 
                      }}
                      required
                    >
                      <MenuItem value={`main`}>Manager</MenuItem>
                      <MenuItem value={`sub`}>Sub Manager</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
            <div
              className={classes.inputRow}
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              {profileCode === profileCodePermissionCheck.profileCode.VCS ?
                <div style={{ width: '100%' }}>
                  <EmailAutofillManager
                    email={email}
                    onEmailChange={handleEmailChange}
                    agentsList={props?.merchantListData?.emailListData?.data || []}
                    selectedUser={selectedUser}
                    setSelectedUser={handleUserSelect}
                    allowManualInput={!props?.merchantListData?.emailData}
                    values={{ businessType }}
                  />
                </div> :
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  fullWidth
                  InputProps={{
                    inputProps: {
                      style: { textTransform: "lowercase" },
                    },
                  }}
                  disabled = {!businessName}
                  onBlur={handleEmailBlur}
                />
              }
              <TextField
                label="Phone Number"
                variant="outlined"
                required
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                  required: true,
                }}
                value={formatPhoneNumber(phoneNumber)}
                onChange={(e) => {
                  const formattedNumber = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 10);
                  setPhone(formattedNumber);
                }}
                fullWidth
                disabled = {profileCode === profileCodePermissionCheck.profileCode.VCS? !businessType : !businessName}
              />
            </div>
            <div
              className={classes.inputRow}
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <TextField
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                fullWidth
                InputProps={{
                  inputProps: {
                    style: { textTransform: "capitalize" },
                  },
                }}
                disabled = {profileCode === profileCodePermissionCheck.profileCode.VCS? !businessType : !businessName}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                fullWidth
                InputProps={{
                  inputProps: {
                    style: { textTransform: "capitalize" },
                  },
                }}
                disabled = {profileCode === profileCodePermissionCheck.profileCode.VCS? !businessType : !businessName}
              />
            </div>
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onFormSubmit}
          >
            Send Invite
          </Button>
        </form>
      </div>
    </Modal>
  );
};

InviteManagerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  sendInviteManagerApiCall: PropTypes.func,
  getManagerListData: PropTypes.func,
  getMerchantListData: PropTypes.func,
  getEmailList: PropTypes.func,
  getEmailData: PropTypes.func,
  emptyEmailListReduxState: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app,
  roles: app.roles,
  app: app.app,
  signUpData: app,
  merchantListData: app.genrateOfferPatient,
  managerListData: app.generateOfferPatient,
});

export default connect(mapStateToProps, {
  sendInviteManagerApiCall,
  getMerchantListData,
  getManagerListData,
  getEmailList,
  getEmailData,
  emptyEmailListReduxState
})(InviteManagerModal);
