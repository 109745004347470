import { post, put, get, postFile } from "./httpClient";
import {
  UPDATE_GROUP_DATA_URL,
  CREATE_NEW_GROUP_URL,
  QUESTION_REPORT_CHANGE_STATUS,
  QUESTION_REPORT_KYC_CHANGE_STATUS,
  KYC_STATUS_APPROVE_CALL,
  MPA_STATUS_APPROVE_CALL,
  MPA_ATTECHMENT_CHECKLIST_REVIEW_SAVE,
  KYC_ATTECHMENT_CHECKLIST_REVIEW_SAVE,
  CHECKLIST_COMMENT_SAVE,
  ROLE_LIST_BY_SEARCH_URL,
  GET_PROVIDER_STATUS_URL,
  GET_KYC_RESPONSE_JSON_DATA,
  AUTO_GENERATED_REPO_CODE,
  PATIENT_FORGET_PASSWORD_URL,
  USER_REGISTRATION_URL,
  SAVE_QUESTION_DATA_URL,
  UPDATE_TEMPLETE_UNDER_PROVIDER_ID,
  DELETE_BANK_DOCUMENT_API_CALL,
  PROVIDE_REGISTRATION_URL,
  UPDATE_PRICING_TEMPLATE_CALL,
  PROVIDER_KYC_CHECKLIST_SUB_QUESTIONS,
  GET_EQUIPMENT_CATEGORY_DATA,
  ADDTIONAL_INFORMATION_SAVE,
  USER_LIST_BY_SEARCH_URL,
  DELETE_OWNER_API_CALL,
  UPDATE_ADDTIONAL_INFORMATION_CALL,
  PRICING_TEMPLETE_CURD,
  GET_PRICING_TEMPLTE_DATA,
  CREATE_NOTES,
  GET_USER_PROFILE_IMAGE,
  SIGN_UP_URL,
  SUBMIT_API_URL,
  SENT_INVITE_MANAGER_CALL,
  MANAGER_UPDATE_CALL,
  ADD_INDIVIDUAL_API_CALL,
  SEND_OTP_CALL,
} from "../Uintls/Urls";
import * as urls from "../Uintls/Urls";
import CryptoJS from "crypto-js";

export const createNewGroupApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(CREATE_NEW_GROUP_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateGroupApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(UPDATE_GROUP_DATA_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusPreameter =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(QUESTION_REPORT_CHANGE_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updatePricingTemplete =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(UPDATE_TEMPLETE_UNDER_PROVIDER_ID, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusPreameterKyc =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(QUESTION_REPORT_KYC_CHANGE_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusApproveMpa =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(MPA_STATUS_APPROVE_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusApproveKyc =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(KYC_STATUS_APPROVE_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const mpaChecklistComment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(CHECKLIST_COMMENT_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const kycChecklistAttechment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(KYC_ATTECHMENT_CHECKLIST_REVIEW_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const mpaChecklistAttechment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(MPA_ATTECHMENT_CHECKLIST_REVIEW_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const confirmForgetPassword =
  (payload, successCallback, failureCallback) => async () => {
    try {
      // const url = `${PATIENT_FORGET_PASSWORD_URL}?email=${payload.email}&countryCode=${payload.countryCode}`;
      const data = await get(PATIENT_FORGET_PASSWORD_URL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const autoGeneratedRepCode =
  (successCallback, failureCallback) => async () => {
    try {
      // const url = `${PATIENT_FORGET_PASSWORD_URL}?email=${payload.email}&countryCode=${payload.countryCode}`;
      const data = await get(AUTO_GENERATED_REPO_CODE);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const createNewUserApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(USER_REGISTRATION_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateUserApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(USER_REGISTRATION_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getKycResponseFromAlloy =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(GET_KYC_RESPONSE_JSON_DATA, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getUpdatePricingTemplate =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(UPDATE_PRICING_TEMPLATE_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

// export const getKycResponseUserList = (payload, successCallback, failureCallback) => async () => {
//   try {
//     const data = await get(PROVIDER_USER_LIST_URL, payload);

//     if (typeof successCallback === "function") {
//       successCallback(data);
//     }
//   } catch (err) {
//     if (typeof failureCallback === "function") {
//       failureCallback(err);
//     }
//   }
// };

export const postKycSubQuestions =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(PROVIDER_KYC_CHECKLIST_SUB_QUESTIONS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getProviderStatusById =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(GET_PROVIDER_STATUS_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const postKycQuestionsCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(SAVE_QUESTION_DATA_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getEquipmentCategory =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(GET_EQUIPMENT_CATEGORY_DATA, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const postAddtionalInformation =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(ADDTIONAL_INFORMATION_SAVE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const postRegistrationCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(PROVIDE_REGISTRATION_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const putRegistrationCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(PROVIDE_REGISTRATION_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateAddtionalInformation =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(UPDATE_ADDTIONAL_INFORMATION_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const deleteBankDocumentApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(DELETE_BANK_DOCUMENT_API_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const deleteOwnerApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(DELETE_OWNER_API_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const createPricingTemplate =
  (id, payload, successCallback, failureCallback) => async () => {
    try {
      const url = `${PRICING_TEMPLETE_CURD}?processorId=${id.processorId}&applicationId=${id.applicationId}`;
      const data = await post(url, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updatePricingTemplate =
  (id, payload, successCallback, failureCallback) => async () => {
    try {
      const url = `${PRICING_TEMPLETE_CURD}?processorId=${id.processorId}&applicationId=${id.applicationId}&templateId=${id.templateId}`;
      const data = await put(url, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const templateFieldsDetailsCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(PRICING_TEMPLETE_CURD, payload);

      if (typeof successCallback === "function") {
        if (data.status) {
          const updatedData = data.data.map((cat) => {
            cat.categoryItem.sort(
              (a, b) => a.displayOrderNo - b.displayOrderNo
            );
            return cat;
          });
          data.data = [...updatedData];
        }
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getUserExport =
  (payload, successCallback, failureCallback) => async () => {
    const finalData = {
      ...payload,
      ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
      LoginEmail: localStorage.getItem("email"),
    };
    try {
      const data = await get(USER_LIST_BY_SEARCH_URL, finalData);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getRoleExport =
  (payload, successCallback, failureCallback) => async () => {
    const finalData = {
      ...payload,
      ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
      LoginEmail: localStorage.getItem("email"),
    };
    try {
      const data = await get(ROLE_LIST_BY_SEARCH_URL, finalData);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getPricingTemplateExport =
  (payload, successCallback, failureCallback) => async () => {
    const finalData = {
      ...payload,
      ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
      LoginEmail: localStorage.getItem("email"),
    };
    try {
      const data = await get(GET_PRICING_TEMPLTE_DATA, finalData);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const userImportFile =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await postFile(urls.USER_IMPORT_FILE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const PSImportFile =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await postFile(urls.PRICINGSCHEDULE_IMPORT_FILE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const PSNewItemsFile =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await postFile(urls.PRICINGSCHEDULE_NEWITEMS_FILE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const PSUpdateFile =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await postFile(urls.PRICINGSCHEDULE_UPDATE_FILE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const createNotes =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(CREATE_NOTES, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateProfileImage =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(GET_USER_PROFILE_IMAGE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const sendInviteApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(SIGN_UP_URL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const sendMoneyCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
      const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
      const encryptedPayload = CryptoJS.AES.encrypt(
        JSON.stringify(payload),
        secretKey
      ).toString();
      let payloadNew = {};
      if (encryptionKey == "NO") {
        payloadNew = payload;
      } else {
        payloadNew = { payload: encryptedPayload };
      }
      const data = await post(urls.SEND_MONEY_CALL, payloadNew);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const sendInviteManagerApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(SENT_INVITE_MANAGER_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const submitApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(SUBMIT_API_URL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const addIndividualApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(ADD_INDIVIDUAL_API_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const managerUpdateCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(MANAGER_UPDATE_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const managerActionsLink =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(urls.GET_MANAGER_ACTIONS, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const sendOTP =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(SEND_OTP_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
