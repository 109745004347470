export default {
  clientID: "",
};

export const checkLayoutName = (value) => {
  let valueFinal = "admin";
  if (value === "SuperAdmin") {
    valueFinal = "admin";
  }
  if (value === "Ag") {
    valueFinal = "admin";
  }
  if (value === "Mg") {
    valueFinal = "manager";
  }
  if (value === "Sub-Mg") {
    valueFinal = "manager";
  }
  if (value === "CFO") {
    valueFinal = "cfo";
  }
  if (value === "FrontDesk") {
    valueFinal = "frontdesk";
  }
  if (value === "Patient") {
    valueFinal = "patient";
  }
  return valueFinal;
};
