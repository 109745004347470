import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import { useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import { getRoutingNumberData } from "Redux/Actions/genrateOfferPatient";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import EventNoteIcon from "@material-ui/icons/EventNote";

import { emptyReduxState } from "Redux/Actions/genrateOfferPatient";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import AppLoader from "components/AppLoader/Index";
import Modal from "@material-ui/core/Modal";
import closeImage from "assets/img/Close_Image.png";
import { CardActions } from "@material-ui/core";
import CryptoJS from "crypto-js";
import { getMerchantListData } from "../../../../Redux/Actions/genrateOfferPatient";
import Validations from "../../../../Utils/Validations";
import { sendMoneyCall } from "../../../../actions/Operations.js";
import PaymentCompletePage from "../../../../views/OfferDetails/PaymentCompletePage";

const useStyles = makeStyles(styles);

function RoutingNumber(props) {
  const theme = useTheme();
  const [validationModal, setValidationModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [value, setValue] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwdConfirm, setIsRevealPwdConfirm] = useState(false);
  const Profile_id = localStorage.getItem("profileId");

  const [businesses, setBusinesses] = useState([]);
  const activeBusinesses = businesses.filter(
    (business) => business.ManagerStatus.Status === "Active"
  );
  const [isRoutingNumberInvalid, setIsRoutingNumberInvalid] =
    React.useState(false);
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  React.useEffect(() => {
    const payload = {
      Profile_id: Profile_id,
    };
    props.getMerchantListData(payload);
  }, []);
  React.useEffect(() => {
    if (props?.merchantListData?.merchantListData?.data?.length > 0) {
      const filteredBusinesses =
        props?.merchantListData?.merchantListData?.data.filter(
          (business) => business.Status_Code === 3
        );
      setBusinesses(filteredBusinesses);
    }
  }, [props.merchantListData]);

  function formatPhoneNumber(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r.length > 6) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 3) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }
  const [initialValues, handleInput] = React.useState({
    routingNumber: "",
    network: "",
    accountNumber: "",
    confirmAccountNumber: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Phone: "",
    Email: "",
    address_one: "",
    address_tow: "",
    Amount: "",
  });

  const [validationError, setVlidationError] = React.useState({
    businessName: false,
    legalBusiness: false,
    businessStartDate: false,
    businessStartDateMessage: false,
    businessType: false,
    businessWebsite: false,
    businessDiscrition: false,
    ownerShipType: false,
    businessPhoneNumber: false,
    businessEmail: false,
    businessTaxId: false,
    businessStock: false,
    businessAddress: false,
    businessSuite: false,
    businessCity: false,
    businessState: false,
    USID: false,
    incorportionDocument: false,
    businessPostalCode: false,
    businessInterNational: false,
    Phone: false,
    accountNumber: false,
    routingNumber: false,
    confirmAccountNumber: false,
    cardPresent: false,
    masterContactName: false,
    foreignEntityNonresident: false,
    fax: false,
    mailingAddress: false,
    transmitCardholderOption: false,
    transmitCardholderName: false,
    seasonalSales: false,
    comments: false,
    locationAddress: false,
    discription: false,
    cardNotPresent: false,
    eCommers: false,
    cardVolumeMonth: false,
    averageTicketSize: false,
    highTicket: false,
    ussidTypeInput: false,
    eCheckVolumeMonth: false,
    eCheckAverage: false,
    status: "",
    checkedB: false,
    Owner_First_Name: false,
    titleTermsCondition: false,
    nameTermsCondition: false,
    First_Name: false,
    Last_Name: false,
  });

  const handleSubmit = () => {
    handleClose();
    setValidationModal(false);
    setIsRoutingNumberInvalid(false);
    const hasErrors = Object.values(validationError).some((error) => error);
    if (hasErrors) {
      setValidationModal(true);
      return;
    }
    if (props.routingNumberData && !props.routingNumberData.available) {
      setIsRoutingNumberInvalid(true);
      setValidationModal(true);
      return;
    }
    for (let item of activeBusinesses) {
      if (item._id === value.selectBusiness) {
        const payload = {
          ...initialValues,
          Module_Name: "Make Payment Page",
          Module_Code: 3,
          network: props.routingNumberData && props.routingNumberData.network,
          Business_uid: item.uid,
          Manager_id: item.Manager_uid,
          selectBusiness: item._id,
          Requested_for_id: item?._id,
          Requested_by_id: item?.Manager_Details._id,
        };
        setLoading(true);
        props.sendMoneyCall(
          payload,
          (res) => {
            setLoading(false);
            if (res.status === true) {
              const bytes = CryptoJS.AES.decrypt(res.data, secretKey);
              const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              const parsedData =
              encryptionKey == "NO" ? res.data : JSON.parse(decryptedData);
              if (parsedData.transactionStatus === "Cancelled") {
                localStorage.setItem(
                  "transactionData",
                  JSON.stringify(parsedData)
                );
                enqueueSnackbar("Transaction Unsuccessful", {
                  variant: "error",
                });
                props.history.replace(
                  `/${props.app.app.layout}/paymentDetails`
                );
              } else {
                localStorage.setItem(
                  "transactionData",
                  JSON.stringify(parsedData)
                );
                enqueueSnackbar("Transaction Successful", {
                  variant: "success",
                });
                props.history.replace(
                  `/${props.app.app.layout}/paymentDetails`
                );
              }
            } else {
              let errorMessage = res.error_description || res.error;
              if (res.data) {
                const bytes = CryptoJS.AES.decrypt(res.data, secretKey);
                errorMessage = bytes.toString(CryptoJS.enc.Utf8);
              }
              enqueueSnackbar(errorMessage.error_description + "HHHHHHHHHH", {
                variant: "error",
              });
            }
          },
          (err) => {
            setLoading(false);
            console.log("err", err);
            const bytes = CryptoJS.AES.decrypt(err.data, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            const parsedData =
              encryptionKey == "NO" ? err.data : JSON.parse(decryptedData);
            enqueueSnackbar(
              parsedData.error === "insufficient_scope"
                ? "You do not have withdrawal permissions enabled. Please contact the Viking Administrator."
                : parsedData.error_description,
              {
                variant: "error",
              }
            );
          }
        );
      }
    }
  };

  const handleClose = () => {
    setValidationModal(false);
  };

  const onChange = (value, type) => {
    const allState = { ...initialValues };
    const error = { ...validationError };
    error[type] = false;
    allState[type] = value;
    if (type === "routingNumber" || type === "businessTaxId") {
      if (!/^[0-9]{9}$/.test(value)) {
        if (value.length !== 9) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 9) {
          error[type] = false;
          allState[type] = value;
          setIsRoutingNumberInvalid(false);
        }
      }
    }
    if (type === "Phone") {
      if (/^\d{0,10}$/.test(value)) {
        if (value.length !== 10) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 10) {
          error[type] = false;
          allState[type] = value;
        }
      }
    }
    if (type === "selectBusiness") {
      setValue(value);
    }
    if (type === "Amount") {
      if (!/^(?!0*(\.0+)?$)(\d*(?:\.[0-9]{0,4})?)$/.test(value)) {
        if (value.length !== 10) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 10) {
          error[type] = false;
          allState[type] = value;
        }
      }
    }
    if (type === "accountNumber") {
      if (value.length < 8 || value.length > 18) {
        error[type] = true;
        allState[type] = value;
      }
      if (value.length === 8) {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "confirmAccountNumber") {
      if (value !== initialValues.accountNumber) {
        error[type] = true;
        allState[type] = value;
      }
      if (value === initialValues.accountNumber) {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "First_Name" || type === "Last_Name") {
      if (/^\d/.test(value)) {
        error[type] = true;
      } else {
        error[type] = false;
      }
    }
    handleInput(allState);
    setVlidationError(error);
  };

  React.useEffect(() => {
    props.emptyReduxState();
  }, []);

  React.useEffect(() => {
    if (initialValues.accountNumber !== initialValues.confirmAccountNumber) {
      const error = { ...validationError, confirmAccountNumber: true };
      setVlidationError(error);
    }
  }, [initialValues]);

  React.useEffect(() => {
    if (props.routingNumberData && props.routingNumberData.available) {
      setIsRoutingNumberInvalid(false);
    }
  }, [props?.routingNumberData?.available]);

  const clearRoutingNumberData = () => {
    props.emptyReduxState();
  };

  const handleInputBlur = (e) => {
    const value = e.target.value;
    if (value === "") {
      onChange("", "Amount");
    } else {
      const parsedValue = parseFloat(value).toFixed(2);
      if (!isNaN(parsedValue)) {
        onChange(parsedValue, "Amount");
      }
    }
  };

  const handleErrorMessage = () => {
    setLoading(false);
    setValidationModal(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const regex = /^(?!0*(\.0+)?$)(\d*(?:\.[0-9]{0,4})?)$/;

    if (regex.test(value) || value === "") {
      onChange(value, "Amount");
    }
  };

  return (
    <div
      className="dashboardTiles"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <AppLoader loader={loading} />
      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card
            style={{
              width: "50%",
              maxWidth: "40%",
              height: "auto",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                minInlineSize: "-webkit-fill-available",
                textAlign: "start",
                height: "auto",
                maxHeight: "70vh",
                display: "flex",
                overflow: "auto",
                flexWrap: "nowrap",
                marginTop: "10%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={closeImage}
                style={{ width: "10%", marginBottom: "3%" }}
                alt=""
              />
              <div className={classes.successText}>
                <Typography
                  style={{ textAlign: "center" }}
                  color="error"
                  variant="subtitle2"
                  gutterBottom
                >
                  Please correct all validation errors before proceeding. Note
                  the highlighted fields.
                </Typography>
              </div>
            </div>

            <CardActions style={{ margin: "5%" }}>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="primary"
                size="sm"
                onClick={() => handleErrorMessage()}
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </div>
      </Modal>
      <div style={{ display: "none" }}>
        <PaymentCompletePage />
      </div>
      <Card
        style={{
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
            backgroundColor: theme.palette.primary.main,
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Typography className={classes.tittleTextColor}>
            Make Payment
          </Typography>
        </Box>
        <Formik
          validationSchema={Validations.makePaymentPage}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, touched }) => (
            <Form>
              <Box style={{ paddingTop: "18px" }} className={classes.marginBox}>
                <Grid container>
                  <Grid container spacing={0} justifyContent="center">
                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xs={12}
                      style={{ marginBottom: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <AccountBalanceIcon
                            className={classes.inputBox}
                            fontSize="small"
                          />
                          <TextField
                            select
                            required
                            disabled={activeBusinesses.length === 0}
                            fullWidth
                            className={classes.root}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused",
                              },
                            }}
                            onChange={(e) => {
                              setValue({
                                ...value,
                                selectBusiness: e.target.value,
                              });
                            }}
                            label="Select Business"
                            name="selectBusiness"
                            id="selectBusiness"
                          >
                            {activeBusinesses.map((business) => (
                              <MenuItem key={business._id} value={business._id}>
                                {business.Business_Name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        {businesses.length === 0 &&
                        activeBusinesses.length === 0 ? (
                          <Typography
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "13px !important",
                              color: "red",
                              marginTop: "6px",
                            }}
                          >
                            Please submit business before initiating a
                            transaction
                          </Typography>
                        ) : businesses.length !== 0 &&
                          activeBusinesses.length === 0 ? (
                          <Typography
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "13px !important",
                              color: "red",
                              marginTop: "6px",
                            }}
                          >
                            You are currently not active in any business
                          </Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    justifyContent="center"
                    style={{ marginBottom: "15px" }}
                  >
                    <Grid item lg={4} sm={4} xs={12}>
                      <AttachMoneyRoundedIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        required
                        className={classes.root}
                        inputProps={{ maxLength: 10 }}
                        helperText={
                          validationError.Amount ? "Amount is Required" : ""
                        }
                        error={validationError.Amount}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        label="Amount to be Transfer"
                        name="Amount"
                        id="Amount"
                      />
                    </Grid>
                  </Grid>

                  <Typography
                    spacing={0}
                    style={{
                      marginLeft: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Recipient Account Details
                  </Typography>

                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: "15px",
                      borderBottom: "1px solid #cbcbcb",
                    }}
                  >
                    <Grid item lg={4} sm={4} xs={12}>
                      <AccountBalanceIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <TextField
                        disabled={!value?.selectBusiness}
                        required
                        className={classes.root}
                        InputLabelProps={{
                          inputProps: { min: 0 },
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        label="Routing Number"
                        error={
                          validationError.routingNumber ||
                          isRoutingNumberInvalid
                        }
                        value={initialValues.routingNumber}
                        onChange={(e) => {
                          if (e.target.value.length === 9) {
                            for (let item of activeBusinesses) {
                              if (item._id === value.selectBusiness) {
                                const data = {
                                  routingNumber: e.target.value,
                                  Module_Name: "Make Payment Page",
                                  Module_Code: 3,
                                  Business_uid: item.uid,
                                  Requested_for_id: item._id,
                                  Requested_by_id: item.Manager_Details._id,
                                  Manager_id: item.Manager_uid,
                                  Business_Name: item.Business_Name,
                                  Manager_Name: `${item.Manager_Details.First_Name}${item.Manager_Details.Last_Name}`,
                                };
                                // const encryptedPayload = CryptoJS.AES.encrypt(
                                //   JSON.stringify(data),
                                //   secretKey
                                // ).toString();
                                props.getRoutingNumberData(data);
                              }
                            }
                          }
                          (/^[0-9]\d*$/g.test(e.target.value) ||
                            e.target.value === "") &&
                            onChange(e.target.value, "routingNumber");
                          if (e.target.value.length !== 9) {
                            clearRoutingNumberData();
                          }
                        }}
                        helperText={
                          (validationError.routingNumber ||
                            isRoutingNumberInvalid) &&
                          "(Invalid routing number. Please enter correct 9-digit Routing number.)"
                        }
                        name="routingNumber"
                        id="routingNumber"
                        type="text"
                      />
                      {initialValues.routingNumber.length === 9 && (
                        <Typography
                          style={{ paddingLeft: "38px", color: "#e67452" }}
                        >
                          {props.routingNumberData &&
                            !props.routingNumberData?.available &&
                            "This routing number is not supported"}
                          {props.routingNumberData &&
                            props.routingNumberData?.network}
                          &nbsp;&nbsp;
                          {props.routingNumberData &&
                            props.routingNumberData?.rdfi_name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <AccountBalanceIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        required
                        className={classes.root}
                        error={
                          validationError.accountNumber &&
                          initialValues.accountNumber !==
                            initialValues.confirmAccountNumber
                        }
                        helperText={
                          validationError.accountNumber &&
                          "Min 8 to Max 18 Digit Account Number is required."
                        }
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        onFocus={() => {
                          setIsRevealPwd(true);
                        }}
                        onBlur={() => {
                          setIsRevealPwd(false);
                        }}
                        onClick={() => {
                          setIsRevealPwd(true);
                        }}
                        value={initialValues.accountNumber}
                        onChange={(e) =>
                          (/^[0-9]\d*$/g.test(e.target.value) ||
                            e.target.value === "") &&
                          onChange(e.target.value, "accountNumber")
                        }
                        type={isRevealPwd ? "text" : "password"}
                        label="Account Number"
                        name="accountNumber"
                        id="accountNumber"
                        onPaste={(e) => e.preventDefault()}
                        onCopy={(e) => e.preventDefault()}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={4}
                      xs={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      <AccountBalanceIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        required
                        className={classes.root}
                        error={
                          validationError.confirmAccountNumber &&
                          initialValues.accountNumber !==
                            initialValues.confirmAccountNumber
                        }
                        onFocus={() => {
                          setIsRevealPwdConfirm(true);
                        }}
                        onBlur={() => {
                          setIsRevealPwdConfirm(false);
                        }}
                        onClick={() => {
                          setIsRevealPwdConfirm(true);
                        }}
                        type={isRevealPwdConfirm ? "text" : "password"}
                        helperText={
                          validationError.confirmAccountNumber &&
                          initialValues.accountNumber !==
                            initialValues.confirmAccountNumber &&
                          "Account number does not match."
                        }
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        onChange={(e) =>
                          (/^[0-9]\d*$/g.test(e.target.value) ||
                            e.target.value === "") &&
                          onChange(e.target.value, "confirmAccountNumber")
                        }
                        label="Re-type Account Number"
                        name="confirmAccountNumber"
                        id="confirmAccountNumber"
                        onPaste={(e) => e.preventDefault()}
                        onCopy={(e) => e.preventDefault()}
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    spacing={0}
                    style={{
                      marginLeft: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Recipient Personal Details
                  </Typography>

                  <Grid
                    container
                    spacing={0}
                    style={{
                      marginBottom: "15px",
                    }}
                  >
                    <Grid item lg={4} sm={4} xs={12}>
                      <PersonIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        required
                        className={classes.root}
                        helperText={
                          validationError.First_Name
                            ? "First Name is required and can not start with a number"
                            : ""
                        }
                        error={validationError.First_Name}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        onChange={(e) => {
                          onChange(e.target.value, "First_Name");
                        }}
                        label="First Name"
                        name="First_Name"
                        id="First_Name"
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <PersonIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        className={classes.root}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                        }}
                        onChange={(e) => {
                          onChange(e.target.value, "Middle_Name");
                        }}
                        label="Middle Name"
                        name="Middle_Name"
                        id="Middle_Name"
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <PersonIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        required
                        className={classes.root}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        onChange={(e) => {
                          onChange(e.target.value, "Last_Name");
                        }}
                        label="Last Name"
                        name="Last_Name"
                        helperText={
                          validationError.Last_Name
                            ? "Last Name is required and can not start with a number"
                            : ""
                        }
                        error={validationError.Last_Name}
                        id="Last_Name"
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <StayCurrentPortraitIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        required
                        className={classes.root}
                        helperText={
                          validationError.Phone &&
                          "Enter a Valid Phone Number with 10 Digits Only"
                        }
                        error={validationError.Phone}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                          required: true,
                        }}
                        value={formatPhoneNumber(initialValues.Phone)}
                        onChange={(e) => {
                          const formattedNumber = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 10);
                          onChange(formattedNumber, "Phone");
                        }}
                        label="Phone"
                        name="Phone"
                        id="Phone"
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <EmailIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        required
                        className={classes.root}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                        }}
                        onChange={(e) => {
                          onChange(e.target.value, "Email");
                        }}
                        label="Email"
                        name="Email"
                        helperText={
                          errors.Email && touched.Email
                            ? "Enter a valid email address. "
                            : ""
                        }
                        error={errors.Email && touched.Email}
                        id="Email"
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <EventNoteIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        className={classes.root}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                        }}
                        onChange={(e) => {
                          onChange(e.target.value, "address_one");
                        }}
                        label="Address 1"
                        name="address_one"
                        id="address_one"
                      />
                    </Grid>
                    <Grid item lg={4} sm={4} xs={12}>
                      <EventNoteIcon
                        className={classes.inputBox}
                        fontSize="small"
                      />
                      <Field
                        as={TextField}
                        fullWidth
                        className={classes.root}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                        }}
                        onChange={(e) => {
                          onChange(e.target.value, "address_tow");
                        }}
                        label="Address 2"
                        name="address_tow"
                        id="address_tow"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "space-evenly",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item sm={3} xs={8}>
                    <Button
                      className={classes.buttonStyle}
                      style={{ backgroundColor: theme.palette.primary.main }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Send Money
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
  // }
}

RoutingNumber.propTypes = {
  getRoutingNumberData: PropTypes.func,
  sendMoneyCall: PropTypes.func,
  emptyReduxState: PropTypes.func,
  getMerchantListData: PropTypes.func,
};

const mapStateToProps = (app) => ({
  generatedFinanceList: app.genrateOfferPatient.generatedFinanceList,
  app,
  formData: app,
  routingNumberData: app.genrateOfferPatient.routingNumberData,
  sendMoneyData: app.genrateOfferPatient.sendMoneyData,
  merchantListData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  getRoutingNumberData,
  sendMoneyCall,
  emptyReduxState,
  getMerchantListData,
})(RoutingNumber);
