import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import AppLoader from "components/AppLoader/Index";
import { useSnackbar } from "notistack";
import noDataImage from "../../assets/img/noDataImage.png";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import {
  sendInviteApiCall,
  sendInviteManagerApiCall,
  managerActionsLink,
} from "../../actions/Operations";
import {
  getMerchantListData,
  getManagerListData,
  emptyReduxState,
  getManagerListDataByID,
  getManagerActionsData,
} from "../../Redux/Actions/genrateOfferPatient";

const useStyles = makeStyles(tableStyle);
function createData(
  Business_Name,
  Business_Uid,
  Manager_name,
  Manager_uid,
  Email,
  Updated_date,
  Status_Code,
  UpdateMgId,
  UpdateBsId,
  uniqueId
) {
  return {
    Business_Name,
    Business_Uid,
    Manager_name,
    Manager_uid,
    Email,
    Updated_date,
    Status_Code,
    UpdateMgId,
    UpdateBsId,
    uniqueId,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    count, page, rowsPerPage, onPageChange,
  } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page ? page + 1 : 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "Business_Name",
      numeric: false,
      disablePadding: true,
      label: "Business Name",
      headClassName: classes.DBA_Name,
    },
    {
      id: "Business_Uid",
      numeric: false,
      disablePadding: true,
      label: "Business UID",
      headClassName: classes.DBA_Name,
    },
    {
      id: "Manager_name",
      numeric: false,
      disablePadding: false,
      label: "Manager Name",
      headClassName: classes.Assign_To,
    },
    {
      id: "Manager_uid",
      numeric: false,
      disablePadding: false,
      label: "Manager UID",
      headClassName: classes.Assign_To,
    },
    {
      id: "Business_Email",
      numeric: true,
      disablePadding: false,
      label: "Email Address",
      headClassName: classes.email,
    },
    {
      id: "Updated_date",
      numeric: true,
      disablePadding: false,
      label: "Updated Date",
      headClassName: classes.Phone,
    },
    {
      id: "Status_Code",
      numeric: false,
      disablePadding: false,
      label: "Manager Status",
      headClassName: classes.status,
    },
    {
      id: "blank",
      numeric: false,
      disablePadding: false,
      label: "",
      headClassName: classes.status,
    },
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const dropdownStyles = makeStyles(theme => ({
  select: {
    fontSize: "0.875rem",
  },
  menuPaper: {
    maxHeight: "100px",
  },
}));
const ManagerActions = props => {
  const dropdownClasses = dropdownStyles();
  const classes = useStyles();
  const [page, setPage] = React.useState(
    props.managerListData?.managerActionsData?.page
  );
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Updated_date");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [id, setId] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState({});

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("id");

    setId(idFromUrl);
  }, []);

  React.useEffect(() => {
    setPage(1);
    const payload = {
      pageNo: 1,
      recordPerPage: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
    };
    props.getManagerActionsData(payload);
  }, []);

  React.useEffect(() => {
    if (props?.managerListData?.managerActionsData?.page !== page) {
      setPage(props?.managerListData?.managerActionsData?.page);
    }
    if (props?.managerListData?.managerActionsData?.limit !== rowsPerPage) {
      setRowsPerPage(props?.managerListData?.managerActionsData?.limit);
    }
  }, [props]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const requestData = {
      recordPerPage: rowsPerPage,
      sortColumn: orderBy,
      sortOrder: order,
      pageNo: newPage + 1,
    };
    props.getManagerActionsData(requestData);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = {
      recordPerPage: parseInt(event.target.value, 10),
      sortColumn: orderBy,
      sortOrder: order,
      pageNo: 1,
    };
    props.getManagerActionsData({ ...requestData });
  };

  rows = [];

  const result = props.managerListData.managerActionsData?.data?.map(
    items => ({
      Business_Name: items?.Business_Details?.Business_Name,
      Business_Uid: items?.Business_Details?.uid,
      Email: items?.Email,
      Manager_uid: items.Manager_uid,
      _id: items?._id,
      First_Name: items?.First_Name,
      Last_Name: items?.Last_Name,
      Updated_date: items?.ManagerStatus?.Updated_date,
      Status_Code: items?.Status_Code,
      User_Id: items.Manager_Details?.User_Id,
      ManagerStatus: items?.ManagerStatus,
      UpdateMgId: items?._id,
      UpdateBsId: items?.Business_Details?._id,
      uniqueId: items?.ManagerStatus?._id,
    })
  );
  const resultWhenId = props.managerListDataById.managerActionsData?.data?.map(
    items => ({
      Business_Name: items?.Business_Details?.Business_Name,
      Business_Uid: items?.Business_Details?.uid,
      Email: items?.Email,
      Manager_uid: items.Manager_uid,
      _id: items?._id,
      First_Name: items?.First_Name,
      Last_Name: items?.Last_Name,
      Status_Code: items.Status_Code,
      User_Id: items.Manager_Details?.User_Id,
      Updated_date: items?.ManagerStatus?.Updated_date,
      UpdateMgId: items?._id,
      UpdateBsId: items?.Business_Details?._id,
      uniqueId: items?.ManagerStatus?._id,
    })
  );
  const newResult =
    id == null &&
      props.managerListData &&
      props.managerListData?.managerActionsData?.status === true
      ? result
      : resultWhenId;
  newResult &&
    newResult.forEach((v, i) => {
      const Business_Name = v.Business_Name;
      const Business_Uid = v.Business_Uid;
      const Email = v.Email;
      const Updated_date = moment(v.Updated_date).tz('America/New_York').format("MM/DD/YYYY");
      const Manager_name = `${v.First_Name} ${v.Last_Name}`;
      const Manager_uid = v.Manager_uid;
      // const Status_Code = v?.ManagerStatus?.Status;
      const Status_Code = v?.ManagerStatus?.Status[0]?.serviceStatus
        ? v?.ManagerStatus?.Status[0]?.serviceStatus
        : v?.ManagerStatus?.Status
          ? v?.ManagerStatus?.Status
          : null;
      const UpdateMgId = v?.UpdateMgId;
      const UpdateBsId = v?.UpdateBsId;
      const uniqueId = v?.uniqueId;
      rows.push(
        createData(
          Business_Name,
          Business_Uid,
          Manager_name,
          Manager_uid,
          Email,
          Updated_date,
          Status_Code,
          UpdateMgId,
          UpdateBsId,
          uniqueId
        )
      );
    });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    const requestData = {
      recordPerPage: rowsPerPage,
      sortColumn: property,
      sortOrder: newOrder,
      pageNo: page,
    };
    props.getManagerActionsData(requestData);
  };

  const handleStatusChange = (value, row) => {
    const payload = {
      Business_uid: row.Business_Uid,
      Manager_uid: row.Manager_uid,
      UpdateMgId: row?.UpdateMgId,
      UpdateBsId: row?.UpdateBsId,
      Link: value === "Link" ? true : false,
    };
    setLoading(true);
    props.managerActionsLink(
      payload,
      res => {
        setLoading(false);
        if (res.status === true) {
          enqueueSnackbar(`${res.message}`, {
            variant: "success",
          });
          window.location.reload();
        }
      },
      error => {
        setLoading(false);
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      }
    );
  };

  const copyToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar(`${text} copied to clipboard`, { variant: "success" });
      })
      .catch(error => {
        enqueueSnackbar("Failed to copy text to clipboard", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <AppLoader loader={loading} />
      <TableContainer className={classes.TableContainer} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow className={classes.cellHeadSign} key={index}>
                  <TableCell className={classes.cellText}>
                    {row.Business_Name}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    <div onClick={() => copyToClipboard(row.Business_Uid)}>
                      {row.Business_Uid}
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Manager_name}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    <div onClick={() => copyToClipboard(row.Manager_uid)}>
                      {row.Manager_uid}
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Email}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Updated_date}
                  </TableCell>
                  <TableCell className={classes.cellText} align="left">
                    {row.Status_Code === "Invited" ||
                      row.Status_Code === "Awaiting Business Submission" ? (
                      <TableCell style={{ padding: 2 }} align="left">
                        {row.Status_Code}
                      </TableCell>
                    ) : (
                      <Select
                        value={row.Status_Code}
                        open={openDropdown[row.uniqueId] || false}
                        onClose={() =>
                          setOpenDropdown(prevState => ({
                            ...prevState,
                            [row.uniqueId]: false,
                          }))
                        }
                        onOpen={() =>
                          setOpenDropdown(prevState => ({
                            ...prevState,
                            [row.uniqueId]: true,
                          }))
                        }
                        onClick={e => {
                          const selectedValue = e.target.textContent;
                          if (
                            selectedValue === "Link" ||
                            selectedValue === "Delink"
                          ) {
                            handleStatusChange(selectedValue, row);
                            setOpenDropdown(prevState => ({
                              ...prevState,
                              [row.uniqueId]: false,
                            }));
                          }
                        }}
                        className={dropdownClasses.select}
                        MenuProps={{
                          classes: { paper: dropdownClasses.menuPaper },
                        }}
                      >
                        <MenuItem value={row.Status_Code}>
                          {row.Status_Code}
                        </MenuItem>
                        {["Inactive", "Active", "Pending Activation"].includes(row.Status_Code) &&
                          ([
                            row.Status_Code === "Inactive" && (
                              <MenuItem
                                key="Link"
                                value="Link"
                                onClick={() => {
                                  handleStatusChange("Link", row);
                                  setOpenDropdown(prevState => ({
                                    ...prevState,
                                    [row.uniqueId]: false,
                                  }));
                                }}
                              >
                                Link
                              </MenuItem>
                            ),
                            row.Status_Code === "Active" && (
                              <MenuItem
                                key="Delink"
                                value="Delink"
                                onClick={() => {
                                  handleStatusChange("Delink", row);
                                  setOpenDropdown(prevState => ({
                                    ...prevState,
                                    [row.uniqueId]: false,
                                  }));
                                }}
                              >
                                Delink
                              </MenuItem>
                            ),
                            row.Status_Code === "Pending Activation" && (
                              <MenuItem
                                key="Link"
                                value="Link"
                                onClick={() => {
                                  handleStatusChange("Link", row);
                                  setOpenDropdown(prevState => ({
                                    ...prevState,
                                    [row.uniqueId]: false,
                                  }));
                                }}
                              >
                                Link
                              </MenuItem>
                            )
                          ].filter(Boolean))}
                      </Select>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value:
                        id == null && props.managerListData
                          ? props.managerListData.managerActionsData?.total
                          : props.managerListDataById.managerListDataById
                            ?.total,
                    },
                  ]}
                  colSpan={12}
                  count={
                    id == null && props.managerListData
                      ? props.managerListData.managerActionsData?.total
                      : props.managerListDataById.managerListDataById?.total
                  }
                  rowsPerPage={rowsPerPage || 5}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ManagerActions.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  userDetails: PropTypes.object,
  mainData: PropTypes.object,
  getMerchantListData: PropTypes.func,
  getManagerListData: PropTypes.func,
  getManagerListDataByID: PropTypes.func,
  sendInviteApiCall: PropTypes.func,
  emptyReduxState: PropTypes.func,
  sendInviteManagerApiCall: PropTypes.func,
  getManagerActionsData: PropTypes.func,
  managerActionsLink: PropTypes.func,
};

const mapStateToProps = app => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.roles,
  merchantListData: app.genrateOfferPatient,
  managerListData: app.genrateOfferPatient,
  managerListDataById: app.genrateOfferPatient,
});
export default connect(mapStateToProps, {
  getMerchantListData,
  getManagerListData,
  getManagerListDataByID,
  sendInviteApiCall,
  emptyReduxState,
  sendInviteManagerApiCall,
  getManagerActionsData,
  managerActionsLink,
})(ManagerActions);
