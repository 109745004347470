import qs from "qs";
import CryptoJS from "crypto-js";

function checkHTTPStatus(status) {
  if (status === 401) {
    localStorage.setItem("sessionExpired", true);
  }
}

export const post = async ({
  url,
  success,
  failure,
  dispatch,
  body,
  auth,
  payment,
}) => {
  let data;
  const bodyValue = body;
  const clientId = localStorage.getItem("clientId")
    ? localStorage.getItem("clientId")
    : null;
  if (!auth) {
    bodyValue.ClientId = clientId;
  }
  try {
    let mainBody = "";
    if (bodyValue === "") {
      mainBody = "";
    } else {
      mainBody = JSON.stringify(bodyValue);
    }
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth && payment === undefined) {
      customHeaders.authorization = auth;
    }
    const res = await fetch(url, {
      method: "POST",
      headers: customHeaders,
      body: mainBody,
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};

export const postLogin = async ({
  url,
  success,
  failure,
  dispatch,
  body,
  auth,
  payment,
}) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let data;
  const bodyValue = body;
  // const clientId = localStorage.getItem("clientId")
  //   ? localStorage.getItem("clientId")
  //   : null;
  // if (!auth) {
  //   bodyValue.ClientId = clientId;
  // }
  try {
    let mainBody = "";
    if (bodyValue === "") {
      mainBody = "";
    } else {
      mainBody = JSON.stringify(bodyValue);
    }
    // const customHeaders = {
    //   "Content-Type": "application/json",
    // };
    // const auth = localStorage.getItem("token");
    // if (auth && payment === undefined) {
    //   customHeaders.authorization = "";
    // }
    const res = await fetch(url, {
      method: "POST",
      headers: myHeaders,
      body: mainBody,
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};

export const postSync = async ({
  url,
  bannerNumber,
  success,
  failure,
  dispatch,
  body,
}) => {
  let data;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    const res = await fetch(url, {
      method: "POST",
      headers: customHeaders,
      body: JSON.stringify(body),
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data, bannerNumber });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data, bannerNumber });
    }
  }
};

export const getExport = async (url, body) => {
  let data;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    let res = null;
    if (body) {
      res = await fetch(`${url}?${qs.stringify(body)}`, {
        method: "get",
        headers: customHeaders,
      });
    } else {
      res = await fetch(url, {
        method: "get",
        headers: customHeaders,
      });
    }
    const responseText = await res.json();
    checkHTTPStatus(res.status);
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
    const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
    const bytes1 = CryptoJS.AES.decrypt(responseText.data, secretKey);
    const decryptedData1 =
      encryptionKey == "NO"
        ? responseText.data
        : bytes1.toString(CryptoJS.enc.Utf8);
    data = {
      ...responseText,
      data: JSON.parse(decryptedData1),
    };
    return data;
  } catch (e) {
    if (e) {
      throw e;
    }
  }
};

export const getExport1 = async (url, body) => {
  let data;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    let res = null;
    if (body) {
      res = await fetch(`${url}?${qs.stringify(body)}`, {
        method: "get",
        headers: customHeaders,
      });
    } else {
      res = await fetch(url, {
        method: "get",
        headers: customHeaders,
      });
    }
    data = await res.json();
    checkHTTPStatus(res.status);
    return data;
  } catch (e) {
    if (e) {
      throw e;
    }
  }
};

export const get = async ({ url, success, failure, dispatch, body }) => {
  let data;
  let bodyValue = body;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    if (body === "default" || !body) {
      bodyValue = "";
    }
    let finalurl = "";
    if (bodyValue === "") {
      finalurl = `${url}`;
    } else {
      finalurl = `${url}?${qs.stringify(bodyValue)}`;
    }
    const res = await fetch(finalurl, {
      method: "get",
      headers: customHeaders,
    });
    data = await res.json();
    data.initialCall = true;
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
    return data;
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};

export const put = async ({ url, success, failure, dispatch, body }) => {
  let data;
  const bodyValue = body;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    const res = await fetch(url, {
      method: "PUT",
      headers: customHeaders,
      body: JSON.stringify(bodyValue),
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};

export const del = async ({ url, success, failure, dispatch, body }) => {
  let data;
  try {
    const customHeaders = {
      "Content-Type": "application/json",
    };
    const auth = localStorage.getItem("token");
    if (auth) {
      customHeaders.authorization = auth;
    }
    const res = await fetch(url, {
      method: "DELETE",
      headers: customHeaders,
      body: JSON.stringify(body),
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};
