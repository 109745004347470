import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { Checkbox } from "@material-ui/core";
import { createTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  getApiLogsData,
  setFormDataApiLogs,
  clearFormDataApiLogs,
} from "../../Redux/Actions/genrateOfferPatient";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#003c6e",
      light: "#003c6e",
      dark: "#003c6e",
    },
    secondary: {
      main: "#003c6e",
    },
  },
  overrides: {
    MuiPickersBasePicker: {
      container: {
        width: "300px",
        height: "370px",
      },
      pickerView: {
        width: "280px",
        height: "280px",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: "0.9rem",
      },
      dayLabel: {
        fontSize: "0.7rem",
      },
    },
    MuiPickersDay: {
      day: {
        fontSize: "0.8rem",
      },
    },
    MuiTypography: {
      h4: {
        fontSize: "1rem",
      },
    },
  },
});

const useStyles = makeStyles(styles);
const searchButtonStyles = makeStyles((theme) => ({
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow:
        "0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)",
    },
  },
}));

function ApiLogsSearch(props) {
  const classes = useStyles();
  const searchButtonClasses = searchButtonStyles();
  const [showDateRange, setShowDateRange] = React.useState(true);
  const [date1, setDate1] = React.useState("");
  const [date2, setDate2] = React.useState("");
  const [apiType, setApiType] = React.useState("");
  const [apiTypeValue, setApiTypeValue] = React.useState("");
  const [module, setModule] = React.useState("");
  const [searchStatus, setSearchStatus] = React.useState("");
  const [requestedFor, setRequestedFor] = React.useState(null);
  const [moduleValue, setModuleValue] = React.useState("");
  console.log("props", props);
  console.log("date1", date1);
  console.log("date2", date2);
  console.log("showDateRange", showDateRange);
  const menu = JSON.parse(localStorage.getItem("menu"));
  const clearFormData = () => {
    const initialFormData = {
      searchRequestedFor: "",
      searchApiType: "",
      searchApiTypeValue: "",
      searchModule: "",
      searchModuleValue: "",
      searchResponseDate: "",
      searchStartDate: "",
      searchEndDate: "",
      searchStatus: null,
      searchStatusValue: "",
    };
    setRequestedFor("");
    setShowDateRange(false);
    setDate1("");
    setDate2("");
    props.setFormDataApiLogs(initialFormData);
  };

  const handleClose = () => {
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    const payload = {
      Profile_Code: profileCode,
      pageNo: 1,
      recordPerPage: 5,
    };
    props.getApiLogsData(payload);
    props.clearFormDataApiLogs();
    clearFormData();
    props.clearChips();
  };

  const handleApiTypeChange = (value) => {
    let Type_Code = null;

    switch (value) {
      case "RTN API":
        Type_Code = 1;
        break;
      case "Send Fund API":
        Type_Code = 2;
        break;
      default:
        Type_Code = null;
        break;
    }
    setApiType(Type_Code);
    setApiTypeValue(value);
  };
  const handleSearchEndDateChange = (date) => {
    let formattedDate = null;
    if (date instanceof Date) {
      formattedDate = format(date, "yyyy-MM-dd");
    } else if (typeof date === "string") {
      const parts = date.split("/");
      if (parts.length === 3) {
        formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    }
    setDate2(formattedDate);
  };

  const handleDateCheckboxChange = (event) => {
    setShowDateRange(event.target.checked);
    if (event.target.checked) {
      setDate1("");
      setShowDateRange(true);
    } else {
      setDate1("");
      setDate2("");
      setShowDateRange(false);
    }
  };
  const handleModuleChange = (value) => {
    let Module_Code = null;

    switch (value) {
      case "Invite Page":
        Module_Code = 1;
        break;
      case "Merchant Submit Page":
        Module_Code = 2;
        break;
      case "Make Payment Page":
        Module_Code = 3;
        break;
      case "Merchant SignUp Page":
        Module_Code = 4;
        break;
      default:
        Module_Code = null;
        break;
    }
    setModule(Module_Code);
    setModuleValue(value);
  };

  const handleSearchStartDateChange = (date) => {
    let formattedDate = null;
    if (date instanceof Date) {
      formattedDate = format(date, "yyyy-MM-dd");
    } else if (typeof date === "string") {
      const parts = date.split("/");
      if (parts.length === 3) {
        formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    }
    setDate1(formattedDate);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "searchApiType") {
      handleApiTypeChange(value);
    } else if (name === "searchModule") {
      handleModuleChange(value);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value.length === 0) {
      // Allow clearing the input
      setRequestedFor(value);
    } else if (isNaN(value[0])) {
      // First character is a string
      if (/^[a-zA-Z]*$/.test(value)) {
        setRequestedFor(value);
      }
    } else {
      // First character is a number
      if (/^\d*$/.test(value)) {
        let intValue = value;
        intValue = parseInt(intValue);
        setRequestedFor(value);
      }
    }
    // setRequestedFor(e.target.value);
  };
  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setSearchStatus(value);
  };
  React.useEffect(() => {
    if (props.formData.searchRequestedFor) {
      setRequestedFor(props.formData.searchRequestedFor);
    }
    if (props.formData.searchApiType) {
      setApiType(props.formData.searchApiType);
    }
    if (props.formData.searchApiTypeValue) {
      setApiTypeValue(props.formData.searchApiTypeValue);
    }
    if (props.formData.searchModule) {
      setModule(props.formData.searchModule);
    }
    if (props.formData.searchModuleValue) {
      setModuleValue(props.formData.searchModuleValue);
    }
    if (props.formData.searchStatus) {
      setSearchStatus(props.formData.searchStatus);
    }
    if (props.formData.searchStartDate) {
      setDate1(props.formData.searchStartDate);
    }
    if (props.formData.searchEndDate) {
      setDate2(props.formData.searchEndDate);
    }
    if (props.formData.dateRange == false) {
      setShowDateRange(props.formData.dateRange);
    }
  }, [props.formData]);

  const onFormSubmit = (event) => {
    event.preventDefault();
    props.setFormDataApiLogs({
      ...props.formData,
      searchRequestedFor: requestedFor,
      searchApiType: apiType,
      searchApiTypeValue: apiTypeValue,
      searchModule: module,
      searchModuleValue: moduleValue,
      searchStartDate: date1 ? date1 : null,
      searchEndDate: date2 ? date2 : null,
      searchStatus: searchStatus.trim(),
      dateRange: showDateRange,
    });
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    const payload = {
      Profile_Code: profileCode,
      pageNo: 1,
      recordPerPage: 5,
      sortColumn: props.orderBy,
      sortOrder: props.order,
      // searchStartDate: date1,
      // searchEndDate: date2,
      searchRequestedFor: requestedFor,
      dateRange: showDateRange,
      searchApiType: apiType,
      searchModule: module,
      searchStartDate: date1,
      searchEndDate: date2,
      searchStatus: searchStatus.trim(),
    };
    props.getApiLogsData(payload);
    props.seacrhChipData(payload);
    props.close();
  };

  return (
    <MuiThemeProvider theme={customTheme}>
      <form onSubmit={(e) => onFormSubmit(e)}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Requested For"
              value={requestedFor}
              onChange={handleInputChange}
              name="searchRequestedFor"
              id="Requested For"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select API Type"
              label="API Type"
              value={apiTypeValue || ""}
              onChange={onChange}
              name="searchApiType"
              id="searchApiType"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem className={classes.dialogBoxDropDown} value="RTN API">
                RTN API
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Send Fund API"
              >
                Send Fund API
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select Module"
              label="Module"
              value={moduleValue || ""}
              onChange={onChange}
              name="searchModule"
              id="searchModule"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Invite Page"
              >
                Invite Page
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Merchant Submit Page"
              >
                Merchant Submit Page
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Make Payment Page"
              >
                Make Payment Page
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="Merchant SignUp Page"
              >
                Merchant SignUp Page
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        {/* <Grid container className={classes.margin} style={{ marginBottom: "5%" }} >
          <Grid item xs>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                variant="inline"
                label="Request Date"
                value={props.formData.searchRequestDate ? new Date(props.formData.searchRequestDate) : null}
                onChange={handleSearchStartDateChange}
                format="yyyy/MM/dd"
                maxDate={new Date()}
                className={`${classes.rootDialog}`}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid> */}
        <Grid container className={classes.margin}>
          <Grid item xs container alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                variant="inline"
                label={showDateRange ? "From" : "Date"}
                value={date1 ? new Date(date1) : null}
                onChange={handleSearchStartDateChange}
                format="yyyy/MM/dd"
                maxDate={new Date()}
                style={{
                  width: showDateRange ? "50%" : "100%",
                }}
              />

              {(inputProps) => (
                <TextField
                  {...inputProps}
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLabelColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  label={showDateRange ? "From" : "Date"}
                  style={{
                    width: showDateRange ? "50%" : "100%",
                    fontSize: "100%",
                  }}
                />
              )}
            </MuiPickersUtilsProvider>
            {showDateRange && (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    label="To"
                    value={date2 ? new Date(date2) : null}
                    onChange={handleSearchEndDateChange}
                    format="yyyy/MM/dd"
                    maxDate={new Date()}
                    style={{
                      width: "50%",
                    }}
                  />
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      className={classes.rootDialog}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLabelColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize,
                        },
                      }}
                      label="To"
                      style={{ width: "50%", fontSize: "82%" }}
                    />
                  )}
                </MuiPickersUtilsProvider>
              </>
            )}
            <Checkbox
              checked={showDateRange}
              onChange={handleDateCheckboxChange}
              color="primary"
              style={{ marginLeft: "auto" }}
            />
            <label style={{ fontSize: "12px", marginBottom: 0 }}>
              Search In Range
            </label>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select Status"
              label="Status"
              value={searchStatus || ""}
              onChange={handleStatusChange}
              name="searchStatus"
              id="Current_Status"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem className={classes.dialogBoxDropDown} value="Success">
                Success
              </MenuItem>
              <MenuItem className={classes.dialogBoxDropDown} value="Failure">
                Failure
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end" }}
        >
          <Button
            variant="contained"
            className={classes.clearButton}
            disabled={
              !requestedFor &&
              !apiTypeValue &&
              !moduleValue &&
              !searchStatus &&
              !date1 &&
              !date2
            }
            onClick={() => handleClose()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.buttonStyle} ${searchButtonClasses.searchButton}`}
            disabled={
              !requestedFor &&
              !apiTypeValue &&
              !moduleValue &&
              !searchStatus &&
              !date1 &&
              !date2
            }
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </form>
    </MuiThemeProvider>
  );
}

ApiLogsSearch.propTypes = {
  close: PropTypes.func,
  seacrhChipData: PropTypes.func,
  getApiLogsData: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.genrateOfferPatient,
  tableData: app.provideRegistration,
  userDetails: app.app,
  apiLogsData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  setFormDataApiLogs,
  clearFormDataApiLogs,
  getApiLogsData,
})(ApiLogsSearch);
