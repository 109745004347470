import "./style.scss";
import "../../../assets/css/CountryCode.scss";

import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import OTPInput from "otp-input-react";
import { connect, useDispatch } from "react-redux";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
} from "formik";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import jwt from "jsonwebtoken";
import { useSnackbar } from "notistack";
import React from "react";
import AppLoader from "components/AppLoader/Index";
import isEmpty from "lodash/isEmpty";
import CryptoJS from "crypto-js";
import {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
  getUserLeftMenuCall,
  clientThemeCall,
  clientPortalConfig,
} from "../../../Redux/Actions/LoginActions";
import {
  getUserDetailsById,
  getUserProfileImage,
} from "../../../Redux/Actions/UserRegistration";
import { getOtpAction, getCountryList } from "../../../actions/login.action";
import { checkLayoutName } from "../../../Uintls/gloables";
import { managerUpdateCall } from "../../../actions/Operations";
import Validation from "../../../Utils/Validations";
import useStyles from "./Style";
import { getEmailData1 } from "../../../Redux/Actions/genrateOfferPatient";
import { profileCodePermissionCheck } from "../../../Utils/commonUtils";
const adminInitialValues = {
  clientId: "H001",
  id: "",
  password: "",
  countryCode: 1,
};
const patientInitialValues = {
  clientId: "",
  id: "",
  password: "",
  userType: "PT",
  countryCode: 1,
};

function Login(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [onClickOtp, setOnClickOtp] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const version = process.env.REACT_APP_BUILD_VERSION_NUMBER;
  const vikingUrl = process.env.REACT_APP_API_BASE_LOGIN_PATH;
  const [loginType, setloginType] = React.useState("admin");
  const [initialValues, setInitialValues] = React.useState({});
  const [countryCode, setCountryCode] = React.useState([]);
  const [email, setEmail] = React.useState();
  const [apiCalled, setApiCalled] = React.useState(false);
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const handleOtp = value => {
    setOtp(value);
  };

  React.useEffect(() => {
    if (props.userData !== false) {
      if (props.userData && props.userData.status) {
        localStorage.setItem("token", `bearer ${props.userData.token}`);
        jwt.verify(
          props.userData.token,
          "Support@Vaminfosyshserus",
          (err, decoded) => {
            if (decoded !== undefined) {
              if (props.userData && props.userData.OPN_BASIC_TOKEN) {
                setLoading(true);
                const bytes = props.userData.status !== false
                  ? CryptoJS.AES.decrypt(
                    props.userData.OPN_BASIC_TOKEN,
                    secretKey
                  )
                  : null;
                const decryptedData = props.userData.status !== false
                  ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
                  : null;

                localStorage.setItem("opnToken", `${decryptedData}`);
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Basic ${decryptedData}`);

                const raw = JSON.stringify({
                  uid: `wingcash:${decoded.Profile_Id}`,
                  source: "web",
                  concurrent: true,
                  device_name: "",
                  device_uuid: "",
                  permissions: [
                    "view_history",
                    "view_wallet",
                    "send_to_account",
                    "withdraw_from_account",
                    "send_cash",
                  ],
                });

                const requestOptions = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };

                fetch(
                  `${vikingUrl}/rest-api/profile-access/token`,
                  requestOptions
                )
                  .then(async response => {
                    const newResult = await response.json();
                    if (newResult.access_token) {
                      const myHeaders = new Headers();
                      myHeaders.append(
                        "Authorization",
                        `Bearer ${newResult.access_token}`
                      );

                      const requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        redirect: "follow",
                      };

                      fetch(
                        `${vikingUrl}/rest-api/wallet/info?version=&access_token=`,
                        requestOptions
                      )
                        .then(async response => {
                          const newResult = await response.json();
                          localStorage.setItem(
                            "fistName",
                            newResult?.profile?.first_name
                          );
                          localStorage.setItem(
                            "lastName",
                            newResult?.profile?.last_name
                          );
                        })
                        .catch(error => {
                          setLoading(false);
                          enqueueSnackbar(
                            "System Error. Please contact the system administrator.",
                            {
                              variant: "error",
                            }
                          );
                          console.error(error);
                        });
                    }
                  })
                  .catch(error => {
                    setLoading(false);
                    enqueueSnackbar(error.message, {
                      variant: "error",
                    });
                  });
              }
              const idealPath = "";
              let LandingPage = "/";
              let MainCat = "";
              if (idealPath) {
                LandingPage = idealPath.landingPageName;
              } else {
                LandingPage = decoded.Role_Permission_Data.LandingPage;
                MainCat = decoded.Role_Permission_Data.Main_Category_LandingPage;
              }
              props.setAppBarName(
                MainCat ? `${MainCat} / ${LandingPage}` : LandingPage
              );
              const layoutName = checkLayoutName(
                decoded.Role_Permission_Data.Profile_Code
              );
              props.setLayoutName(layoutName);
              if (idealPath) {
                props.history.push(idealPath.path);
              } else {
                props.history.push(
                  `/${layoutName}/${LandingPage.replace(/\s/g, "")}`
                );
              }
              localStorage.setItem("menu", JSON.stringify(decoded));
              localStorage.setItem("clientId", decoded.ClientID);
              localStorage.setItem("userId", decoded.UserID);
              localStorage.setItem("profileId", decoded.Profile_Id);
              localStorage.setItem(
                "userProfileImage",
                decoded.userProfileImage
              );
              localStorage.setItem(
                "sendApiClientId",
                JSON.stringify([decoded.ClientID])
              );
              if(!apiCalled){
                props.getUserDetailsById(decoded.UserID);
                setApiCalled(true);
              }
              props.setUserRole(decoded);
              props.getUserLeftMenuCall();
            }
          }
        );
      }
    }
  }, [props]);

  React.useEffect(() => {
    if (props.userData.token && email) {
      dispatch(getEmailData1(email));
    }
  }, [props.userData.token, email]);

  React.useEffect(() => {
    if (props.userData.status === false) {
      enqueueSnackbar(props.userData.message, {
        variant: "error",
      });
    }
  }, [props]);

  const passwordLogin = function (values) {
    setEmail(values?.id);
    setLoading(true);
    const clientId = process.env.REACT_APP_TEMP_CLIENTID;
    const updatedValues = {
      Email: values?.id,
      clientId: clientId || "",
      usertype: profileCodePermissionCheck.profileCode.SA,
      password: values?.password,
    };

    setLoading(false);
    props.loginRequest(updatedValues);
  };

  const otpLogin = function () {
    const updatedValues = {
      Email: initialValues.id,
      clientId: initialValues.clientId || "",
      loginBy: "OTP",
      password: otp,
    };
    if (/^\d+$/.test(initialValues.id)) {
      updatedValues.countryCode = initialValues.countryCode;
    }
    props.loginRequest(updatedValues);
  };

  React.useEffect(() => {
    if (isEmpty(countryCode) && !props.app.defaultTheme === false) {
      props.getCountryList(data => {
        setCountryCode(data.Country_Data);
      });
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem("loginPath", window.location.pathname);
    localStorage.removeItem("adminLoginPath");
  }, []);

  React.useEffect(() => {
    if (loginType === "admin") {
      setInitialValues(adminInitialValues);
    }
    if (loginType === "patient") {
      setInitialValues(patientInitialValues);
    }
  }, [loginType]);

  React.useEffect(() => {
    setOtp("");
  }, [onClickOtp]);
  if (props.app.defaultTheme.status === true) {
    var image = props.app.defaultTheme && props.app.defaultTheme.data[0].Logo_Image;
  }
  return (
    <div className="dashboardTiles" style={{ position: "relative" }}>
      <AppLoader loader={loading} />
      <Container component="main" className={classes.rootNew}>
        <CssBaseline />
        <Paper
          className={!windowWidth ? classes.paperNewM : classes.paperNew}
          elevation={5}
        >
          <Box
            height="100%"
            p={{
              xs: 3,
              sm: 4,
              md: 5,
              xl: 6,
            }}
            display={{ xs: "none", sm: "none", md: "flex" }}
            className={`${classes.panelWidth} auth-left-panel`}
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.brandLogo}>
                <img className="imgf" src={image} alt="appName" />
              </div>
              <Box mt={2} className={classes.brandTag}>
                <Typography component="h6" variant="subtitle1" align="center" />
              </Box>
            </Box>
          </Box>
          <Box
            display={{ xs: "flex", sm: "flex", md: "none" }}
            className="mobileHeader"
            container="true"
            justify="center"
          >
            <div className={classes.brandLogo}>
              <img className="imgm" src={image} alt="appName" />
            </div>
          </Box>
          <Box
            height="100%"
            pt={4}
            pb={4}
            pl={{
              xs: 4,
              sm: 6,
              md: 2,
              xl: 8,
            }}
            pr={{
              xs: 4,
              sm: 6,
              md: 2,
              xl: 8,
            }}
            display="flex"
            alignItems="center"
            className={
              !windowWidth
                ? `${classes.panelWidth} auth-right-panelM`
                : `${classes.panelWidth} auth-right-panel`
            }
          >
            {isEmpty(loginType) && (
              <Grid
                container
                justifyContent="center"
                spacing={2}
                className="serviceCardList"
              >
                <Grid
                  className="serviceCard serviceCard_ loginAdmin"
                  xs={9}
                  onClick={() => setloginType("admin")}
                  item
                >
                  <Link>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      className={`${classes.tilesCard} tiles-capsule`}
                    >
                      <Box p={3} className="tiles-head">
                        <Typography variant="h4" component="h2">
                          Admin Login
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>

                <Grid
                  className="serviceCard serviceCard_ loginPatient"
                  xs={9}
                  onClick={() => setloginType("patient")}
                  item
                >
                  <Link>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      className={`${classes.tilesCard} tiles-capsule`}
                    >
                      <Box p={3} className="tiles-head">
                        <Typography variant="h4" component="h2">
                          Patient Login
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Grid>
              </Grid>
            )}
            {!isEmpty(loginType) && (
              <>
                {!onClickOtp ? (
                  <>
                    <div className={classes.authInnerForm}>
                      <Formik
                        validationSchema={
                          loginType === "patient"
                            ? Validation.loginPatient
                            : Validation.loginAdmin
                        }
                        enableReinitialize
                        onSubmit={passwordLogin}
                        initialValues={initialValues}
                      >
                        {({ errors }) => (
                          <>
                            <Form>
                              <Typography component="p" variant="subtitle2">
                                Log in to your account
                              </Typography>
                              <div className={classes.form}>
                                <Field
                                  as={TextField}
                                  margin="normal"
                                  fullWidth
                                  id="id"
                                  required
                                  autoComplete="new-password"
                                  error={errors.id ? true : false}
                                  label="Enter Email"
                                  name="id"
                                  autoFocus={loginType !== "admin"}
                                />
                                <ErrorMessage name="id">
                                  {msg => (
                                    <Typography
                                      component="span"
                                      className="error"
                                      variant="body2"
                                    >
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>
                                <ErrorMessage name="countryCode">
                                  {msg => (
                                    <Typography
                                      component="span"
                                      className="error"
                                      variant="body2"
                                    >
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>
                                <Field
                                  as={TextField}
                                  margin="normal"
                                  fullWidth
                                  name="password"
                                  error={errors.password ? true : false}
                                  label={"Password *"}
                                  type="password"
                                  id="password"
                                  autoComplete="new-password"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Button
                                          className={classes.forgotButton}
                                          color="primary"
                                          onClick={() => {
                                            props.history.push("resetPassword");
                                          }}
                                        >
                                          Forgot?
                                        </Button>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <ErrorMessage name="password">
                                  {msg => (
                                    <Typography
                                      component="span"
                                      className="error"
                                      variant="body2"
                                    >
                                      {msg}
                                    </Typography>
                                  )}
                                </ErrorMessage>

                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="center"
                                  className={classes.spaceTop3}
                                >
                                  <Grid sm={12} xs={12} item>
                                    <Button
                                      size="large"
                                      fullWidth
                                      type="submit"
                                      variant="contained"
                                      color="primary"
                                      className={classes.submit}
                                      style={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: "#fff",
                                      }}
                                    >
                                      Login
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </>
                ) : (
                  <div className={classes.authInnerForm}>
                    <Typography component="p" variant="body1" gutterBottom>
                      Please enter the one time code sent to
                    </Typography>
                    <Typography component="p" variant="body1">
                      {initialValues.id}
                    </Typography>
                    <Link onClick={() => setOnClickOtp(false)} color="primary">
                      <b>Change</b>
                    </Link>
                    <div className={classes.form}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        className={classes.spaceTop}
                      >
                        <OTPInput
                          value={otp}
                          onChange={handleOtp}
                          OTPLength={6}
                          inputStyles={{
                            border: 0,
                            width: "14px",
                            borderBottom: "1px solid #cbcbcb",
                          }}
                          otpType="number"
                          disabled={false}
                        />
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{ marginTop: "20px" }}
                        className={classes.spaceTop}
                      >
                        <Grid sm={10} xs={10} item>
                          <Button
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={otp.toString().length < 6}
                            className={classes.submit}
                            onClick={otpLogin}
                          >
                            Verify
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        className={classes.spaceTop}
                      >
                        <Grid item>
                          <Typography component="p" variant="subtitle2">
                            Not received your code?
                            <Button color="primary">
                              <b>Resend code</b>
                            </Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                )}
              </>
            )}
          </Box>
        </Paper>
      </Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 2rem",
          position: "absolute",
          bottom: "2rem",
          color: "white",
        }}
      >
        <i>Version: {version}</i>
        <i
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "7px",
          }}
        >
          <div>Powered By:</div>

          <img
            style={{
              width: "154px",
            }}
            className="imgf"
            src="/images/tsc_logo.png"
            alt="appName"
          />
        </i>
      </div>
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.object,
  loginRequest: PropTypes.func,
  userData: PropTypes.object,
  setUserRole: PropTypes.func,
  setAppBarName: PropTypes.func,
  setLayoutName: PropTypes.func,
  getCountryList: PropTypes.func,
  app: PropTypes.object,
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails,
  app,
  formData: app,
});

export default connect(mapStateToProps, {
  loginRequest,
  setUserRole,
  setAppBarName,
  clientPortalConfig,
  setLayoutName,
  getOtpAction,
  getCountryList,
  clientThemeCall,
  getUserDetailsById,
  getUserProfileImage,
  getUserLeftMenuCall,
  managerUpdateCall,
  getEmailData1,
})(Login);
