/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-useless-path-segments */
import CryptoJS from "crypto-js";
import * as types from "./ActionTypes";
import { post, get, put, getExport, getExport1 } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const patientSearch = (data) => (dispatch) => {
  const finalData = {
    ...data,
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
  };
  dispatch({ type: types.GENRATE_OFFER_PATIENT_SEARCH_CALL });
  get({
    url: `${urls.GENRATE_OFFER_PATIENT_SEARCH_URL}`,
    success: types.GENRATE_OFFER_PATIENT_SEARCH_SUCCESS,
    failure: types.GENRATE_OFFER_PATIENT_SEARCH_ERROR,
    dispatch,
    body: finalData,
  });
};

export const uploadOfferDocument = (data) => (dispatch) => {
  dispatch({ type: types.UPLOAD_DOCUMNET_FINAL_OFFER_CALL });
  post({
    url: `${urls.UPLOAD_DOCUMNET_FINAL_OFFER}`,
    success: types.UPLOAD_DOCUMNET_FINAL_OFFER_SUCCESS,
    failure: types.UPLOAD_DOCUMNET_FINAL_OFFER_ERROR,
    dispatch,
    body: data,
    auth: true,
  });
};

export const sendEmailApiCall = (data) => (dispatch) => {
  dispatch({ type: types.SEND_OFFER_EMAIL_CALL });
  get({
    url: `${urls.SEND_OFFER_EMAIL_ID_URL}`,
    success: types.SEND_OFFER_EMAIL_SUCCESS,
    failure: types.SEND_OFFER_EMAIL_ERROR,
    dispatch,
    body: data,
  });
};

export const getPatientDetailsEmail = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_EMAIL_PATIENT_DETAILS_CALL });
  get({
    url: `${urls.SHOW_EMAIL_PATIENT_DETAILS_URL}`,
    success: types.SHOW_EMAIL_PATIENT_DETAILS_SUCCESS,
    failure: types.SHOW_EMAIL_PATIENT_DETAILS_ERROR,
    dispatch,
    body: data,
  });
};

export const updatePatientDetailsOffer = (data) => (dispatch) => {
  dispatch({ type: types.UPDATE_PATIENT_DETAILS_OFFER_CALL });
  put({
    url: `${urls.UPDATE_PATIENT_DETAILS_OFFER_URL}`,
    success: types.UPDATE_PATIENT_DETAILS_OFFER_SUCCESS,
    failure: types.UPDATE_PATIENT_DETAILS_OFFER_ERROR,
    dispatch,
    body: data,
  });
};

export const getGenrateOfferData = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_GENRATE_OFFER_CALL });
  get({
    url: `${urls.PATIENT_GENRATE_OFFER_URL}`,
    success: types.PATIENT_GENRATE_OFFER_SUCCESS,
    failure: types.PATIENT_GENRATE_OFFER_ERROR,
    dispatch,
    body: data,
  });
};

export const getPersonalOfferData = (data) => (dispatch) => {
  dispatch({ type: types.PERSONAL_LOAN_OFFER_CALL });
  get({
    url: `${urls.PERSONAL_LOAN_OFFER_URL}`,
    success: types.PERSONAL_LOAN_OFFER_SUCCESS,
    failure: types.PERSONAL_LOAN_OFFER_ERROR,
    dispatch,
    body: data,
  });
};

export const getRoutingNumberData = (data) => (dispatch) => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  console.log("logsDatapayload", data);
  const payload = { payload: encryptedPayload };
  dispatch({ type: types.ROUTING_NUMBER_CALL });
  get({
    url: `${urls.ROUTING_NUMBER_URL}`,
    success: types.ROUTING_NUMBER_SUCCESS,
    failure: types.ROUTING_NUMBER_ERROR,
    dispatch,
    body: encryptionKey == "NO" ? data : payload,
  });
};

export const getAccountNumberData = (data) => (dispatch) => {
  dispatch({ type: types.ACCOUNT_NUMBER_CALL });
  get({
    url: `${urls.ACCOUNT_NUMBER_URL}`,
    success: types.ACCOUNT_NUMBER_SUCCESS,
    failure: types.ACCOUNT_NUMBER_ERROR,
    dispatch,
    body: data,
  });
};

export const createOfferCall = (data) => (dispatch) => {
  dispatch({ type: types.CREATE_OFFER_PATIENT_CALL });
  post({
    url: `${urls.CREATE_OFFER_PATIENT_URL}`,
    success: types.CREATE_OFFER_PATIENT_SUCCESS,
    failure: types.CREATE_OFFER_PATIENT_ERROR,
    dispatch,
    body: data,
    auth: true,
  });
};

export const printOfferCall = (data) => (dispatch) => {
  dispatch({ type: types.PRINT_OFFER_PATIENT_CALL });
  get({
    url: `${urls.PRINT_OFFER_PATIENT_URL}`,
    success: types.PRINT_OFFER_PATIENT_SUCCESS,
    failure: types.PRINT_OFFER_PATIENT_ERROR,
    dispatch,
    body: data,
  });
};

export const genrateOfferHyperLink = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_HYPER_LINK_GENRATE_OFFER_CALL });
  get({
    url: `${urls.PATIENT_GENRATE_OFFER_URL}`,
    success: types.PATIENT_HYPER_LINK_GENRATE_OFFER_SUCCESS,
    failure: types.PATIENT_HYPER_LINK_GENRATE_OFFER_ERROR,
    dispatch,
    body: data,
  });
};

export const deleteDocumentCall = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_PATIENT_OFFER_DOCUMENTS_CALL });
  get({
    url: `${urls.DELETE_PATIENT_OFFER_DOCUMENTS_URL}`,
    success: types.DELETE_PATIENT_OFFER_DOCUMENTS_SUCCESS,
    failure: types.DELETE_PATIENT_OFFER_DOCUMENTS_ERROR,
    dispatch,
    body: data,
  });
};

export const patientPaymentDetailsCall = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_INFO_BILLING_DETAILS_CALL });
  get({
    url: `${urls.PATIENT_INFO_BILLING_DETAILS_URL}`,
    success: types.PATIENT_INFO_BILLING_DETAILS_SUCCESS,
    failure: types.PATIENT_INFO_BILLING_DETAILS_ERROR,
    dispatch,
    body: data,
  });
};

export const patientMakePayment = (data) => (dispatch) => {
  dispatch({ type: types.MAKE_PATIENT_PAYMENT_CALL });
  post({
    url: `${urls.MAKE_PATIENT_PAYMENT_URL}`,
    success: types.MAKE_PATIENT_PAYMENT_SUCCESS,
    failure: types.MAKE_PATIENT_PAYMENT_ERROR,
    dispatch,
    body: data,
  });
};

export const makePaymentCall = (data) => (dispatch) => {
  dispatch({ type: types.MAKE_PATIENT_CALL });
  post({
    url: `${urls.MAKE_PAYMENT_URL}`,
    success: types.MAKE_PATIENT_CALL,
    failure: types.MAKE_PAYMENT_ERROR,
    dispatch,
    body: data,
  });
};

export const getPatientInfoEmail = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_EMAIL_PATIENT_INFO_CALL, data });
  get({
    url: `${urls.SHOW_EMAIL_PATIENT_INFO_URL}`,
    success: types.SHOW_EMAIL_PATIENT_INFO_SUCCESS,
    failure: types.SHOW_EMAIL_PATIENT_INFO_ERROR,
    dispatch,
    body: data,
  });
};

export const patientRegister = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_REGISTER_CALL });
  get({
    url: `${urls.PATIENT_REGISTER_URL}`,
    success: types.PATIENT_REGISTER_SUCCESS,
    failure: types.PATIENT_REGISTER_ERROR,
    dispatch,
    body: data,
  });
};

export const getPatientOfferData = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_OFFER_GRAPH_CALL });
  get({
    url: `${urls.PATIENT_OFFER_GRAPH_URL}`,
    success: types.PATIENT_OFFER_GRAPH_SUCCESS,
    failure: types.PATIENT_OFFER_GRAPH_ERROR,
    dispatch,
    body: data,
  });
};

export const emptyGraphData = () => ({
  type: types.PATIENT_OFFER_GRAPH_DATA_EMPTY,
});

export const setEmailRedirect = () => ({
  type: types.PATIENT_OFFER_EMAIL_SET,
});

export const confirmForgetPassword = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_FORGET_PASSWORD_CALL });
  get({
    url: `${urls.PATIENT_FORGET_PASSWORD_URL}`,
    success: types.PATIENT_FORGET_PASSWORD_SUCCESS,
    failure: types.PATIENT_FORGET_PASSWORD_ERROR,
    dispatch,
    body: data,
  });
};

export const checkResetPasswordAuthCall = (data) => (dispatch) => {
  dispatch({ type: types.CHECK_RESET_PASSWORD_AUTH_CHECK_CALL });
  get({
    url: `${urls.CHECK_RESET_PASSWORD_AUTH_CHECK_URL}`,
    success: types.CHECK_RESET_PASSWORD_AUTH_CHECK_SUCCESS,
    failure: types.CHECK_RESET_PASSWORD_AUTH_CHECK_ERROR,
    dispatch,
    body: data,
  });
};

export const changePasswordCall = (data) => (dispatch) => {
  dispatch({ type: types.CHANGE_PATIENT_PASSWORD_CALL });
  get({
    url: `${urls.CHANGE_PATIENT_PASSWORD_URL}`,
    success: types.CHANGE_PATIENT_PASSWORD_SUCCESS,
    failure: types.CHANGE_PATIENT_PASSWORD_ERROR,
    dispatch,
    body: data,
  });
};

export const cptCodeDataCall = () => (dispatch) => {
  dispatch({ type: types.SERVICES_CPT_CODE_PATIENT_CALL });
  get({
    url: `${urls.SERVICES_CPT_CODE_PATIENT_URL}`,
    success: types.SERVICES_CPT_CODE_PATIENT_SUCCESS,
    failure: types.SERVICES_CPT_CODE_PATIENT_ERROR,
    dispatch,
    body: "",
  });
};

export const addPatientCall = (data) => (dispatch) => {
  dispatch({ type: types.ADD_PATIENT_DETAILS_OFFER_CALL });
  post({
    url: `${urls.ADD_PATIENT_DETAILS_OFFER_URL}`,
    success: types.ADD_PATIENT_DETAILS_OFFER_SUCCESS,
    failure: types.ADD_PATIENT_DETAILS_OFFER_ERROR,
    dispatch,
    body: data,
  });
};

export const editPatientCall = (data) => (dispatch) => {
  dispatch({ type: types.UPDATE_PATIENT_DETAILS_CALL });
  put({
    url: `${urls.UPDATE_PATIENT_DETAILS_URL}`,
    success: types.UPDATE_PATIENT_DETAILS_SUCCESS,
    failure: types.UPDATE_PATIENT_DETAILS_ERROR,
    dispatch,
    body: data,
  });
};

export const getAllActivePlanList = (data) => (dispatch) => {
  dispatch({ type: types.ACTIVE_PLAN_PATIENT_LIST_CALL });
  get({
    url: `${urls.ACTIVE_PLAN_PATIENT_LIST_URL}`,
    success: types.ACTIVE_PLAN_PATIENT_LIST_SUCCESS,
    failure: types.ACTIVE_PLAN_PATIENT_LIST_ERROR,
    dispatch,
    body: data,
  });
};

export const getAllDocumentList = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_DOCUMENT_LIST_CALL });
  get({
    url: `${urls.PATIENT_DOCUMENT_LIST_URL}`,
    success: types.PATIENT_DOCUMENT_LIST_SUCCESS,
    failure: types.PATIENT_DOCUMENT_LIST_ERROR,
    dispatch,
    body: data,
  });
};

export const offerDownloadFile = (data) => (dispatch) => {
  dispatch({ type: types.OFFER_DOWNLOAD_FILE_CALL });
  get({
    url: `${urls.OFFER_DOWNLOAD_FILE_URL}`,
    success: types.OFFER_DOWNLOAD_FILE_SUCCESS,
    failure: types.OFFER_DOWNLOAD_FILE_ERROR,
    dispatch,
    body: data,
  });
};

export const addProposalCall = (data) => (dispatch) => {
  dispatch({ type: types.ADD_PROPOSAL_DETAILS_CALL });
  post({
    url: `${urls.ADD_PROPOSAL_DETAILS_URL}`,
    success: types.ADD_PROPOSAL_DETAILS_SUCCESS,
    failure: types.ADD_PROPOSAL_DETAILS_ERROR,
    dispatch,
    body: data,
  });
};

export const addSetUpMonthlyPayment = (data) => (dispatch) => {
  dispatch({ type: types.SETUP_MONTHLY_PAYMENT_CALL });
  put({
    url: `${urls.SETUP_MONTHLY_PAYMENT_URL}`,
    success: types.SETUP_MONTHLY_PAYMENT_SUCCESS,
    failure: types.SETUP_MONTHLY_PAYMENT_ERROR,
    dispatch,
    body: data,
  });
};

export const getAllCompletedPlanList = (data) => (dispatch) => {
  dispatch({ type: types.COMPLETED_PLAN_PATIENT_LIST_CALL });
  get({
    url: `${urls.COMPLETED_PLAN_PATIENT_LIST_URL}`,
    success: types.COMPLETED_PLAN_PATIENT_LIST_SUCCESS,
    failure: types.COMPLETED_PLAN_PATIENT_LIST_ERROR,
    dispatch,
    body: data,
  });
};

export const setUpMonthlyOffer = (data) => ({
  type: types.SETUP_MONTHLY_FROM_OFFER,
  data,
});

export const setLoadingFalse = (data) => ({
  type: types.GENRATE_OFFER_LOADING_FALSE,
  data,
});

export const setDefaultOfferValue = (data) => ({
  type: types.SET_DEFAULT_VALUE_OFFER,
  data,
});

export const setSelectPatientOffer = (data) => ({
  type: types.SET_PATIENT_OFFER_SELECT_PATINET,
  data,
});

export const setBackToSearchFromDetails = () => ({
  type: types.SET_BACK_TO_SEARCH_FROM_OFFER,
});

export const addPreposalidsArray = (data) => ({
  type: types.SET_PREPOSAL_IDS_ARRAY,
  data,
});

export const emptyPatientSelect = () => ({
  type: types.EMPTY_OFFER_SELECT_PATIENT,
});

export const beforePaymentSendOTP = (data) => (dispatch) => {
  dispatch({ type: types.BEFORE_PAYMENT_SEND_OTP_CALL });
  get({
    url: `${urls.BEFORE_PAYMENT_SEND_OTP_URL}`,
    success: types.BEFORE_PAYMENT_SEND_OTP_SUCCESS,
    failure: types.BEFORE_PAYMENT_SEND_OTP_ERROR,
    dispatch,
    body: data,
  });
};

export const verifyPaymentOTP = (data) => (dispatch) => {
  dispatch({ type: types.VERIFY_PAYMENT_OTP_CALL });
  get({
    url: `${urls.VERIFY_PAYMENT_OTP_URL}`,
    success: types.VERIFY_PAYMENT_OTP_SUCCESS,
    failure: types.VERIFY_PAYMENT_OTP_ERROR,
    dispatch,
    body: data,
  });
};

export const setUserIdResetPaassword = (data) => ({
  type: types.SET_USER_ID_FORGET_PASSWORD,
  data,
});

export const patientTpoScore = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_TPO_SCORE_CALL });
  get({
    url: `${urls.PATIENT_TPO_SCORE_URL}`,
    success: types.PATIENT_TPO_SCORE_SUCCESS,
    failure: types.PATIENT_TPO_SCORE_ERROR,
    dispatch,
    body: data,
  });
};

export const generatePatientTpoScore = (data) => (dispatch) => {
  dispatch({ type: types.GENERATE_PATIENT_TPO_SCORE_CALL });
  get({
    url: `${urls.GENERATE_PATIENT_TPO_SCORE_URL}`,
    success: types.GENERATE_PATIENT_TPO_SCORE_SUCCESS,
    failure: types.GENERATE_PATIENT_TPO_SCORE_ERROR,
    dispatch,
    body: data,
  });
};

export const paymentTokenCheckout = (data) => (dispatch) => {
  dispatch({ type: types.PAYMENT_CHECKOUT_CALL });
  post({
    url: `${urls.PAYMENT_CHECKOUT_URL}`,
    success: types.PAYMENT_CHECKOUT_SUCCESS,
    failure: types.PAYMENT_CHECKOUT_ERROR,
    dispatch,
    body: data,
    auth: false,
    payment: true,
  });
};

export const cernerPatientSearch = (data) => (dispatch) => {
  const finalData = {
    ...data,
    ClientId: JSON.stringify(localStorage.getItem("sendApiClientId")),
  };
  dispatch({ type: types.GLOBAL_PATIENT_SEARCH_CALL });
  get({
    url: `${urls.GLOBAL_PATIENT_SEARCH_URL}`,
    success: types.GLOBAL_PATIENT_SEARCH_SUCCESS,
    failure: types.GLOBAL_PATIENT_SEARCH_ERROR,
    dispatch,
    body: finalData,
  });
};

export const getMerchantListData = (data) => (dispatch) => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  const payload = { payload: encryptedPayload };
  dispatch({ type: types.GET_MERCHANT_LIST });
  get({
    url: `${urls.GET_MERCHANT_LIST}`,
    success: types.GET_MERCHANT_LIST_SUCCESS,
    failure: types.GET_MERCHANT_LIST_ERROR,
    dispatch,
    body: encryptionKey == "NO" ? data : payload,
  });
};

export const getMerchantListDataName = (data) => (dispatch) => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  const payload = { payload: encryptedPayload };
  dispatch({ type: types.GET_MERCHANT_LIST_NAMES });
  get({
    url: `${urls.GET_MERCHANT_LIST_NAMES}`,
    success: types.GET_MERCHANT_LIST_NAMES_SUCCESS,
    failure: types.GET_MERCHANT_LIST_NAMES_ERROR,
    dispatch,
    body: encryptionKey == "NO" ? data : payload,
  });
};

export const setFormData = (formData) => ({
  type: types.SET_FORM_DATA,
  payload: formData,
});

export const clearFormData = () => ({
  type: types.CLEAR_FORM_DATA,
});

export const getManagerListData = (data) => (dispatch) => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  const payload = { payload: encryptedPayload };
  dispatch({ type: types.GET_MANAGER_LIST });
  get({
    url: `${urls.GET_MANAGER_LIST}`,
    success: types.GET_MANAGER_LIST_SUCCESS,
    failure: types.GET_MANAGER_LIST_ERROR,
    dispatch,
    body: encryptionKey == "NO" ? data : payload,
  });
};

export const getManagerActionsData = (data) => (dispatch) => {
  dispatch({ type: types.GET_MANAGER_ACTIONS_LIST });
  get({
    url: `${urls.GET_MANAGER_ACTIONS_LIST}`,
    success: types.GET_MANAGER_ACTIONS_LIST_SUCCESS,
    failure: types.GET_MANAGER_ACTIONS_LIST_ERROR,
    dispatch,
    body: data,
  });
};

export const getManagerListDataByID = (data) => (dispatch) => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  const payload = { payload: encryptedPayload };
  dispatch({ type: types.GET_MANAGER_LIST_BY_ID });
  get({
    url: `${urls.GET_MANAGER_LIST}`,
    success: types.GET_MANAGER_LIST_SUCCESS_BY_ID,
    failure: types.GET_MANAGER_LIST_ERROR_BY_ID,
    dispatch,
    body: encryptionKey == "NO" ? data : payload,
  });
};

export const getManagerListDataNames = (data) => (dispatch) => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  const payload = { payload: encryptedPayload };
  dispatch({ type: types.GET_MANAGER_LIST_NAMES });
  get({
    url: `${urls.GET_MANAGER_LIST}`,
    success: types.GET_MANAGER_LIST_NAMES_SUCCESS,
    failure: types.GET_MANAGER_LIST_NAMES_ERROR,
    dispatch,
    body: encryptionKey == "NO" ? data : payload,
  });
};

export const setFormDataManager = (formDataManager) => ({
  type: types.SET_FORM_DATA_MANAGER,
  payload: formDataManager,
});

export const clearFormDataManager = () => ({
  type: types.CLEAR_FORM_DATA_MANAGER,
});

export const getTransactionListData = (data) => (dispatch) => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  const payload = { payload: encryptedPayload };
  dispatch({ type: types.GET_MANAGER_LIST });
  get({
    url: `${urls.GET_TRANSACTION_LIST}`,
    success: types.GET_TRANSACTION_LIST_SUCCESS,
    failure: types.GET_TRANSACTION_LIST_ERROR,
    dispatch,
    body: encryptionKey == "NO" ? data : payload,
  });
};

export const getApiLogsData = (data) => (dispatch) => {
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptedPayload = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  console.log("logsDatapayload", data);
  const payload = { payload: encryptedPayload };
  dispatch({ type: types.GET_API_LOGS });
  get({
    url: `${urls.GET_API_LOGS}`,
    success: types.GET_API_LOGS_SUCCESS,
    failure: types.GET_API_LOGS_ERROR,
    dispatch,
    body: encryptionKey == "NO" ? data : payload,
  });
};

export const getErrorCodeData = (data) => (dispatch) => {
  dispatch({ type: types.GET_ERROR_CODE });
  get({
    url: `${urls.GET_ERROR_CODE}`,
    success: types.GET_ERROR_CODE_SUCCESS,
    failure: types.GET_ERROR_CODE_ERROR,
    dispatch,
    body: data,
  });
};

export const getErrorCodeExport =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    try {
      const data = await getExport1(urls.GET_ERROR_CODE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
export const getApiLogsDataExport =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
    const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      secretKey
    ).toString();
    let payloadNew = {};
    if (encryptionKey == "NO") {
      payloadNew = payload;
    } else {
      payloadNew = { payload: encryptedPayload };
    }
    console.log("payloadExport", payload);
    try {
      const data = await getExport1(urls.GET_API_LOGS, payloadNew);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getApiLogsDataJson =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
    const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      secretKey
    ).toString();
    let payloadNew = {};
    if (encryptionKey == "NO") {
      payloadNew = payload;
    } else {
      payloadNew = { payload: encryptedPayload };
    }
    try {
      const data = await getExport1(urls.GET_API_LOGS, payloadNew);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getRefundHistoryDataExport =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    try {
      const data = await getExport1(urls.GET_TRANSACTION_HISTORY, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getTransactionListDataExport =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    debugger;
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
    const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      secretKey
    ).toString();
    let payloadNew = {};
    if (encryptionKey == "NO") {
      payloadNew = payload;
    } else {
      payloadNew = { payload: encryptedPayload };
    }

    try {
      const data = await getExport1(urls.GET_TRANSACTION_LIST, payloadNew);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
export const getMerchantSetupDataExport =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
    const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      secretKey
    ).toString();
    let payloadNew = {};
    if (encryptionKey == "NO") {
      payloadNew = payload;
    } else {
      payloadNew = { payload: encryptedPayload };

      // payloadNew = { encryptedPayload };
    }
    try {
      const data = await getExport(urls.GET_MERCHANT_LIST, payloadNew);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getMerchantListDataExport =
  (payload, successCallback, failureCallback) => async (dispatch) => {
    const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      secretKey
    ).toString();
    let payloadNew = {};
    if (encryptionKey == "NO") {
      payloadNew = payload;
    } else {
      payloadNew = { payload: encryptedPayload };

      // payloadNew = { encryptedPayload };
    }
    try {
      const data = await getExport(urls.GET_MANAGER_LIST, payloadNew);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const setFormDataApiLogs = (formDataApiLogs) => ({
  type: types.SET_FORM_DATA_API_LOGS,
  payload: formDataApiLogs,
});

export const clearFormDataApiLogs = () => ({
  type: types.CLEAR_FORM_DATA_API_LOGS,
});

export const setFormDataErrorCodes = (formDataErrorCodes) => ({
  type: types.SET_FORM_DATA_ERROR_CODES,
  payload: formDataErrorCodes,
});

export const clearFormDataErrorCodes = () => ({
  type: types.CLEAR_FORM_DATA_ERROR_CODES,
});

export const emptyReduxState = () => ({
  type: types.EMPTY_REDUX_STATE,
});

export const emptyMerchantRTNReduxState = () => ({
  type: types.EMPTY_MERCHANT_RTN_REDUX_STATE,
});

export const emptyMerchantAccReduxState = () => ({
  type: types.EMPTY_MERCHANT_ACC_REDUX_STATE,
});

export const getTransactionHistoryData = (data) => (dispatch) => {
  dispatch({ type: types.GET_TRANSACTION_HISTORY });
  get({
    url: `${urls.GET_TRANSACTION_HISTORY}`,
    success: types.GET_TRANSACTION_HISTORY_SUCCESS,
    failure: types.GET_TRANSACTION_HISTORY_ERROR,
    dispatch,
    body: data,
  });
};

export const setFormDataTransactionHistory = (
  searchDataTransactionHistory
) => ({
  type: types.SET_FORM_DATA_TRANSACTION_HISTORY,
  payload: searchDataTransactionHistory,
});

export const clearFormDataTransactionHistory = () => ({
  type: types.CLEAR_FORM_DATA_TRANSACTION_HISTORY,
});

export const setTransactionListPage = (page) => ({
  type: "SET_TRANSACTION_LIST_PAGE",
  payload: page,
});

export const setTransactionListRowsPerPage = (rowsPerPage) => ({
  type: "SET_TRANSACTION_LIST_ROWS_PER_PAGE",
  payload: rowsPerPage,
});

export const getEmailList = (query, type) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "GET_EMAIL_LIST" });

    const encodedQuery = encodeURIComponent(query);

    get({
      url: `${urls.GET_EMAIL_LIST_URL}?q=${encodedQuery}&type=${type}`,
      success: types.GET_EMAIL_LIST_SUCCESS,
      failure: types.GET_EMAIL_LIST_ERROR,
      dispatch,
    })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getEmailData = (query) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "GET_EMAIL_DATA" });

    const encodedQuery = encodeURIComponent(query);

    get({
      url: `${urls.GET_EMAIL_DATA_URL}?email=${encodedQuery}`,
      success: types.GET_EMAIL_DATA_SUCCESS,
      failure: types.GET_EMAIL_DATA_ERROR,
      dispatch,
    })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getEmailData1 = (query) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "GET_EMAIL_DATA1" });

    const encodedQuery = encodeURIComponent(query);

    get({
      url: `${urls.GET_EMAIL_DATA_URL}?email=${encodedQuery}`,
      success: types.GET_EMAIL_DATA1_SUCCESS,
      failure: types.GET_EMAIL_DATA1_ERROR,
      dispatch,
    })
      .then((data) => {
        resolve(data);
        localStorage.setItem("emailData1", JSON.stringify(data));
      })
      .catch((error) => reject(error));
  });
};

export const emptyEmailListReduxState = () => ({
  type: types.EMPTY_EMAIL_LIST_REDUX_STATE,
});

export const setMerchantListPage = (page) => ({
  type: "SET_MERCHANT_LIST_PAGE",
  payload: page,
});

export const setMerchantListRowsPerPage = (rowsPerPage) => ({
  type: "SET_MERCHANT_LIST_ROWS_PER_PAGE",
  payload: rowsPerPage,
});
