import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import { exportToPDF } from "modules/reports/utils/common";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch } from "react-redux";
import { exportToCSVProvider } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Providers";
import MerchantSetupMenu from "../../components/Menu/MerchantSetupMenu";
import {
  changeProviderFormMode,
  changeSingleStatus,
} from "../../Redux/Actions/ProvideRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { actionPermission, permissionCheck } from "../../Utils/commonUtils";
import { ReactComponent as ExportIcon } from "../../assets/svgIcons/ExportIcon.svg";
import { ReactComponent as AddIconSvg } from "../../assets/svgIcons/AddIconSvg.svg";
import {
  getMerchantListData,
  emptyReduxState,
  setFormData,
  clearFormData,
  getMerchantListDataName,
  getMerchantSetupDataExport,
  setMerchantListPage,
  setMerchantListRowsPerPage
} from "../../Redux/Actions/genrateOfferPatient";
import SearchFields from "../../components/SearchComponent/SearchFields";
import SearchChips from "../../components/SearchComponent/SearchChips";
import SyncIcon from "@material-ui/icons/Sync";
import { useSnackbar } from "notistack";
import { profileCodePermissionCheck } from "../../Utils/commonUtils";
const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

const useStyles = makeStyles(tableStyle);

function createData(
  Business_Name,
  Dba,
  uid,
  Account_id,
  Manager_name,
  Creation_Date,
  Update_Date,
  Status_Code,
  Id
) {
  return {
    Business_Name,
    Dba,
    uid,
    Account_id,
    Manager_name,
    Creation_Date,
    Update_Date,
    Status_Code,
    Id,
  };
}


function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    count, page, rowsPerPage, onPageChange,
  } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page ? page + 1 : 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const menu = JSON.parse(localStorage.getItem("menu"));
  const profileCode = menu?.Role_Permission_Data?.Profile_Code;
  const headCells = [
    {
      id: "Business_Name",
      numeric: false,
      disablePadding: true,
      label: "Business Name",
      headClassName: classes.DBA_Name,
    },
    {
      id: "Dba",
      numeric: false,
      disablePadding: true,
      label: "DBA Name",
      headClassName: classes.E_mail,
    },
    {
      id: "uid",
      numeric: false,
      disablePadding: false,
      label: "UID",
      headClassName: classes.Assign_To,
    },
    {
      id: "Account_id",
      numeric: false,
      disablePadding: true,
      label: "Account ID",
      headClassName: classes.DBA_Name,
    },
    profileCode === profileCodePermissionCheck.profileCode.VCS && {
      id: "Manager_name",
      numeric: false,
      disablePadding: false,
      label: "Primary Manager Name",
      headClassName: classes.Assign_To,
    },
    {
      id: "Created_date",
      numeric: true,
      disablePadding: false,
      label: "Creation Date",
      headClassName: classes.Creation_Date,
    },
    {
      id: "Updated_date",
      numeric: true,
      disablePadding: false,
      label: "Updated Date",
      headClassName: classes.Update_Date,
    },
    {
      id: "Status_Code",
      numeric: false,
      disablePadding: false,
      label: "Business Status",
      headClassName: classes.status,
    },
    {
      id: "blank",
      numeric: false,
      disablePadding: false,
      label: "",
      headClassName: classes.status,
    },
  ].filter(Boolean);

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const ProviderReport = (props) => {
  const [statusDetails, setStatusPid] = React.useState({
    pid: "",
    name: "",
    status: "",
  });
  const [values, setValues] = React.useState({
    status: "",
    id: "",
  });
  const classes = useStyles();
  const [page, setPage] = React.useState( props.merchantListData?.merchantListPagination?.currentPage || 0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Updated_date");
  const [searchData, setSearchData] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [searchBar, setSearchBar] = React.useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const [openBoardingModel, setopenBoardingModel] = React.useState(false);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [rowsPerPage, setRowsPerPage] = React.useState(props.merchantListData?.merchantListPagination?.rowsPerPage || 5);
  const [openImportFileModal, setImportFileModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const profileCode = menu?.Role_Permission_Data?.Profile_Code;
  const [permission, setPermission] = React.useState("");
  const [transformedData, setTransformedData] = React.useState([]);
  const [rows, setRows] = React.useState([])
  const completeData = props?.merchantListData?.merchantListNames;
  const [totalCount, setTotalCount] = React.useState(0);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleToggle = () => {
    setImportFileModalOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setImportFileModalOpen(false);
    }
  }

  const handleCloseModel = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setImportFileModalOpen(false);
  };

  function providerregisterurl() {
    props.emptyReduxState();
    props.history.push(`/${props.userDetails.layout}/MerchantDetails`);
    props.changeProviderFormMode("Create");
  }
console.log("props",props)
  React.useEffect(() => {
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === profileCodePermissionCheck.profileCode.SA) {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 999999,
      };
      dispatch(getMerchantListDataName(payload));
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 999999,
      };
      dispatch(getMerchantListDataName(payload));
    }
  }, []);

  React.useEffect(() => {
    const result = completeData?.data?.map(items => ({
      Business_Name: items?.Business_Name,
      Dba: items?.Dba,
      uid: items.uid,
      Account_id: items?.Account_id,
      Business_Website: items?.Business_Website,
      Business_Description: items?.Business_Description,
      Business_Phone_Number: items?.Business_Phone_Number,
      Email: items?.Email,
      _id: items?._id,
      Created_By: items?.Created_By,
      Manager_id: items?.Manager_id,
      Invite_Date: items?.Invite_Date,
      Created_date: items?.Created_date,
      Updated_date: items?.Updated_date,
      First_Name: items?.Manager_Details?.First_Name,
      Last_Name: items?.Manager_Details?.Last_Name,
      Phone_Number: items?.Manager_Details?.Phone_Number,
      Status_Code: items.Status_Code,
    }));
  
    const resultWhenId = completeData?.data?.map(
      items => ({
        Business_Name: items?.Business_Name,
        Dba: items?.Dba,
        uid: items.uid,
        Account_id: items?.Account_id,
        Business_Website: items?.Business_Website,
        Business_Description: items?.Business_Description,
        Business_Phone_Number: items?.Business_Phone_Number,
        Email: items?.Email,
        _id: items?._id,
        Created_By: items?.Created_By,
        Manager_id: items?.Manager_id,
        Invite_Date: items?.Invite_Date,
        Created_date: items?.Created_date,
        Updated_date: items?.Updated_date,
        First_Name: items?.Manager_Details?.First_Name,
        Last_Name: items?.Manager_Details?.Last_Name,
        Phone_Number: items?.Manager_Details?.Phone_Number,
        Status_Code: items.Status_Code,
      })
    );
  
    const newResult =
      props.merchantListData &&
      props.merchantListData?.merchantListNames?.status === true
        ? result
        : resultWhenId;
  
    setTransformedData(newResult || []);
  }, [completeData]);

  React.useEffect(() => {
    const applyFilters = (data, filterCriteria) => {
      return data.filter(row => {
        const matchesBusinessName = filterCriteria?.searchBusinessName ? 
          row.Business_Name?.toLowerCase().includes(filterCriteria.searchBusinessName.toLowerCase()) : true;
        const matchesDbaName = filterCriteria?.searchDbaName ? 
          row.Dba?.toLowerCase().includes(filterCriteria.searchDbaName.toLowerCase()) : true;
        const matchesStatus = filterCriteria?.searchStatus ? 
          row.Status_Code === filterCriteria.searchStatus : true;
        const matchesManagerId = filterCriteria?.searchManagerId ? 
          row._id?.toLowerCase().includes(filterCriteria.searchManagerId.toLowerCase()) : true;
        return (
          matchesBusinessName &&
          matchesDbaName &&
          matchesStatus &&
          matchesManagerId
        );
      });
    };

    const sortData = (data) => {
      return data.sort((a, b) => {
        const aValue = getPropertyNested(a, orderBy);
        const bValue = getPropertyNested(b, orderBy);

        if (order === "asc") {
          if (aValue === null || aValue === undefined) return -1;
          if (bValue === null || bValue === undefined) return 1;
          return aValue > bValue ? 1 : -1;
        } else {
          if (aValue === null || aValue === undefined) return 1;
          if (bValue === null || bValue === undefined) return -1;
          return aValue < bValue ? 1 : -1;
        }
      });
    };

    const filterAndSortData = (data, filterCriteria) => {
      const filteredData = applyFilters(data, filterCriteria);
      const sortedData = sortData(filteredData);
      return sortedData;
    };
  
    const updateRows = () => {
      const backPressed = JSON.parse(localStorage.getItem('BackPressed'));
      const searchDataMerchant = JSON.parse(localStorage.getItem('searchDataMerchant'));
      const pageReset = localStorage.getItem("pageReset");
  
      let filterCriteria = props.merchantListData;
      const chips = [];
      if (backPressed && searchDataMerchant) {
        filterCriteria = searchDataMerchant;
        props.setFormData(searchDataMerchant);
        if (searchDataMerchant.searchBusinessName) {
          chips.push(`Business Name: ${searchDataMerchant.searchBusinessName}`);
        }
        if (searchDataMerchant.searchDbaName) {
          chips.push(`DBA Name: ${searchDataMerchant.searchDbaName}`);
        }
        if (searchDataMerchant.searchStatus) {
          let status = "";
          switch (searchDataMerchant.searchStatus) {
          case 1:
            status = "Invited";
            break;
          case 2:
            status = "Saved As Draft";
            break;
          case 3:
            status = "Submitted";
            break;
          default:
            status = "";
          }
          if (status) {
            chips.push(`Status: ${status}`);
          }
        }
        if (searchDataMerchant.searchManagerName) {
          chips.push(`Manager Name: ${searchDataMerchant.searchManagerName}`);
        }
        setChipValue(chips);
      }
  
      const filteredAndSortedData = filterAndSortData(transformedData, filterCriteria);
      setTotalCount(filteredAndSortedData.length);
      const paginatedData = filteredAndSortedData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      const newRows = paginatedData.map((v) => {
        const Id = v._id;
        const Business_Name = v.Business_Name;
        const Dba = v.Dba;
        const uid = v.uid;
        const Account_id = v.Account_id;
        const Manager_name = `${v.First_Name} ${v.Last_Name}`;
        const Creation_Date = v.Created_date
          ? moment(v.Created_date).tz('America/New_York').format("MM/DD/YYYY")
          : "";
        const Update_Date = moment(v.Updated_date).tz('America/New_York').format("MM/DD/YYYY");
        const Status_Code = v.Status_Code;
  
        return createData(
          Business_Name,
          Dba,
          uid,
          Account_id,
          Manager_name,
          Creation_Date,
          Update_Date,
          Status_Code,
          Id
        );
      });

      setRows(newRows);
      if(pageReset){
        setPage(0);
        dispatch(setMerchantListPage(0));
        localStorage.removeItem("pageReset");
      }

      // Clear BackPressed from localStorage
      localStorage.removeItem('BackPressed');
      localStorage.removeItem("searchDataMerchant");
    };

    updateRows();
  }, [page, rowsPerPage, order, orderBy, transformedData, props.merchantListData]);

  React.useEffect(() => {
    // Function to clear search data when component unmounts
    return () => {
      let updatedFormData = {
        searchBusinessName: "",
        searchDbaName: "",
        searchStatus: null,
        searchStatusValue: "",
        searchManagerName: "",
        searchManagerId:""
      };
      props.setFormData(updatedFormData);
      props.clearFormData();
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(setMerchantListPage(newPage));
  };

  const saveStatus = () => {
    props.changeSingleStatus(values);
  };
  const exportedBy = props.mainData.users.userDetailsById
    && props.mainData.users.userDetailsById?.Users_Data[0].Email;
  const exportedByFname = props.mainData.users.userDetailsById
    && props.mainData.users.userDetailsById?.Users_Data[0].FName;
  const exportedByLname = props.mainData.users.userDetailsById
    && props.mainData.users.userDetailsById?.Users_Data[0].LName;

  const exportListData = () => {
    const applyFilters = (data) => {
      return data?.filter(row => {
        const matchesBusinessName = searchData?.searchBusinessName ?
          row?.Business_Name?.toLowerCase().includes(searchData?.searchBusinessName?.toLowerCase()) : true;
        const matchesDbaName = searchData?.searchDbaName ?
          row?.Dba?.toLowerCase().includes(searchData?.searchDbaName?.toLowerCase()) : true;
        const matchesStatus = searchData?.searchStatus ?
          row?.Status_Code?.toString() === searchData?.searchStatus?.toString() : true;
        const matchesManagerId = searchData?.searchManagerId ?
          row._id?.toLowerCase().includes(searchData?.searchManagerId?.toLowerCase()) : true;
        return (
          matchesBusinessName &&
          matchesDbaName &&
          matchesStatus &&
          matchesManagerId
        );
      });
    };
  
    // Get all data from Redux
    let dataToExport = props?.merchantListData?.merchantListNames?.data;
  
    // Apply filters if any
    dataToExport = applyFilters(dataToExport);
  
    if (dataToExport.length > 0) {
      const mappedLogs = dataToExport.map(row => ({
        "Business Name": row.Business_Name,
        "DBA Name": row.Dba,
        UID: row.uid,
        "Primary Manager Name": row.full_name,
        "Creation Date": row.Created_date
          ? moment(row.Created_date).tz('America/New_York').format("MM/DD/YYYY")
          : "",
        "Update Date": moment(row.Updated_date).tz('America/New_York').format("MM/DD/YYYY"),
        Status:
          row.Status_Code === 1
            ? "Invited"
            : row.Status_Code === 3
              ? "Submitted"
              : row.Status_Code === 4
                ? "Awaiting Business Submission"
                : row.Status_Code === 2
                  ? "Save as Draft"
                  : "",
      }));
      exportToCSVProvider(
        mappedLogs,
        "Merchant Details",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname
      );
    }
  };

  const exportToPDFList = () => {
    const applyFilters = (data) => {
      return data?.filter(row => {
        const matchesBusinessName = searchData?.searchBusinessName ?
          row?.Business_Name?.toLowerCase().includes(searchData?.searchBusinessName?.toLowerCase()) : true;
        const matchesDbaName = searchData?.searchDbaName ?
          row?.Dba?.toLowerCase().includes(searchData?.searchDbaName?.toLowerCase()) : true;
        const matchesStatus = searchData?.searchStatus ?
          row?.Status_Code?.toString() === searchData?.searchStatus?.toString() : true;
        const matchesManagerId = searchData?.searchManagerId ?
          row._id?.toLowerCase().includes(searchData?.searchManagerId?.toLowerCase()) : true;
        return (
          matchesBusinessName &&
          matchesDbaName &&
          matchesStatus &&
          matchesManagerId
        );
      });
    };
  
    // Get all data from Redux
    let dataToExport = props?.merchantListData?.merchantListNames?.data;
  
    // Apply filters if any
    dataToExport = applyFilters(dataToExport);
  
    const totalCount = dataToExport.length;
    if (totalCount > 0) {
      const mappedLogs = dataToExport.map(row => [
        row.Business_Name,
        row.Dba,
        row.uid,
        `${row.Manager_Details.First_Name} ${row.Manager_Details.Last_Name}`,
        row.Created_date
          ? moment(row.Created_date).tz('America/New_York').format("MM/DD/YYYY")
          : "",
        moment(row.Updated_date).tz('America/New_York').format("MM/DD/YYYY"),
        row.Status_Code === 1
          ? "Invited"
          : row.Status_Code === 3
            ? "Submitted"
            : row.Status_Code === 4
              ? "Awaiting Business Submission"
              : row.Status_Code === 2
                ? "Save as Draft"
                : "",
      ]);
  
      const headersName = [
        "Business Name",
        "DBA Name",
        "UID",
        "Primary Manager Name",
        "Creation Date",
        "Update Date",
        "Status",
      ];
  
      exportToPDF(
        headersName,
        mappedLogs,
        "Merchant Details",
        chipValue,
        exportedBy,
        exportedByFname,
        exportedByLname,
        totalCount
      );
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(setMerchantListRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setMerchantListPage(0));
  };

  const displayAndDeleteChips = (searchData) => {
    const chips = [];
    setSearchData(searchData);
    if (searchData.searchBusinessName) {
      chips.push(`Business Name: ${searchData.searchBusinessName}`);
    }
    if (searchData.searchDbaName) {
      chips.push(`DBA Name: ${searchData.searchDbaName}`);
    }
    if (searchData.searchStatus) {
      let status = "";
      switch (searchData.searchStatus) {
      case 1:
        status = "Invited";
        break;
      case 2:
        status = "Saved As Draft";
        break;
      case 3:
        status = "Submitted";
        break;
      default:
        status = "";
      }
      if (status) {
        chips.push(`Status: ${status}`);
      }
    }
    if (searchData.searchManagerName) {
      chips.push(`Manager Name: ${searchData.searchManagerName}`);
    }
    setChipValue(chips);
  };

  const handleDeleteChip = (chipToDelete) => {
    const updatedChips = chipValue.filter((chip) => chip !== chipToDelete);
    setChipValue(updatedChips);

    // Update form data based on remaining chips
    let updatedFormData = {
      searchBusinessName: "",
      searchDbaName: "",
      searchStatus: null,
      searchStatusValue: "",
      searchManagerName: "",
      searchManagerId: "",
    };

    updatedChips.forEach((chip) => {
      const [key, value] = chip.split(":").map((item) => item.trim());
      switch (key) {
      case "DBA Name":
        updatedFormData["searchDbaName"] = value;
        break;
      case "Business Name":
        updatedFormData["searchBusinessName"] = value;
        break;
      case "Status":
        updatedFormData["searchStatusValue"] = value;
        updatedFormData["searchStatus"] = getStatusFromValue(value);
        break;
      case "Manager Name":
        updatedFormData["searchManagerName"] = value;
        updatedFormData["searchManagerId"] = props?.merchantListData?.searchManagerId;
        break;
      default:
        break;
      }
    });

    // Clear and set form data
    props.clearFormData();
    props.setFormData(updatedFormData);
    setPage(0);
  };

  const getStatusFromValue = (value) => {
    switch (value) {
    case "Invited":
      return 1;
    case "Saved As Draft":
      return 2;
    case "Submitted":
      return 3;
    default:
      return null;
    }
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    allState.id = statusDetails.id;
    setValues(allState);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const getPropertyNested = (object, property) => {
    const properties = property.split(".");
    let value = object;

    for (let prop of properties) {
      value = value[prop];
      if (value === undefined || value === null) return undefined;
      if (Array.isArray(value)) {
        value = value.length > 0 ? value[0] : undefined;
      }
    }

    return value;
  };

  const handleEdit = (type, providerId) => {
    localStorage.setItem(
      "searchDataMerchant",
      JSON.stringify({
        searchBusinessName: props.merchantListData.searchBusinessName,
        searchDbaName: props.merchantListData.searchDbaName,
        searchManagerId: props.merchantListData.searchManagerId,
        searchStatus: props.merchantListData.searchStatus,
        searchManagerName: props.merchantListData.searchManagerName,
      })
    );
    props.emptyReduxState();
    if (type === "edit") {
      props.history.push(
        `/${props.userDetails.layout}/MerchantDetails?id=${providerId}`
      );
    }
  };

  const handleSearchOpen = () => {
    setSearchBar(true);
  };

  const handleSearchClose = () => {
    setSearchBar(false);
  };

  const userType = localStorage.getItem("userType");
  React.useEffect(()=>{
    setPermission(props?.roles?.roleTab?.Roles_Data?.Master_Permission[0]?.Page_Menu[0]?.Options[1]?.permission)
  },[props.roles.roleTab.Roles_Data])

  const handleRefresh = () =>{
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === profileCodePermissionCheck.profileCode.SA) {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 999999,
      };
      dispatch(getMerchantListDataName(payload));
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 999999,
      };
      dispatch(getMerchantListDataName(payload));
    }
  }

  const copyToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar(`${text} copied to clipboard`, { variant: "success" });
      })
      .catch(error => {
        enqueueSnackbar("Failed to copy text to clipboard", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        <Modal
          open={openBoardingModel}
          onClose={() => setopenBoardingModel(false)}
          align="center"
        >
          <div
            className={classes.modalDiv}
            style={{ width: "48%" }}
            align="center"
          >
            <Card
              className={
                windowWidthSm ? classes.modalRoot : classes.modalRootXs
              }
            >
              <div>
                <ClearIcon
                  onClick={() => setopenBoardingModel(false)}
                  style={{ float: "right", pointer: "cursor" }}
                />
                <CardHeader subheader="Activity Response" />
              </div>
              <div style={{ margin: "3%" }}>
                <Grid container className={classes.margin}>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>DBA Name: </span>{" "}
                    {`${statusDetails.name}`}
                  </Grid>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>Email: </span>{" "}
                    {`${statusDetails.email}`}
                  </Grid>
                </Grid>
                <Grid container className={classes.margin}>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>
                      Sub Marchent Id:{" "}
                    </span>{" "}
                    {`${statusDetails.submarchecntId}`}
                  </Grid>
                  <Grid item xs={6} align="left">
                    <span style={{ fontWeight: "bold" }}>Status: </span>{" "}
                    {`${statusDetails.status}`}
                  </Grid>
                </Grid>
              </div>
              <div>
                <CardHeader subheader="Error Log" />
              </div>
              <div>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Error Code
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Field Name
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeader}
                        align="center"
                        style={{ fontWeight: "bold" }}
                      >
                        Description
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cellText} />
                      <TableCell className={classes.cellText} align="center" />
                      <TableCell className={classes.cellText} align="center" />
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Card>
          </div>
        </Modal>
        <Modal
          open={openModel}
          onClose={() => setOpenModel(false)}
          align="center"
        >
          <div className={classes.modalDiv} align="center">
            <Card
              className={
                windowWidthSm ? classes.modalRoot : classes.modalRootXs
              }
            >
              <div className={classes.statusModalHeader}>
                <div className={classes.statusHeadingText}>
                  Change Merchant Status
                </div>
                <ClearIcon
                  style={{ color: "#fff" }}
                  color="#fff"
                  onClick={() => setOpenModel(false)}
                />
              </div>
              <Grid
                container
                className={classes.margin}
                style={{ padding: "2%" }}
              >
                <Grid item className={classes.searchRoot}>
                  <strong>{`DBA Name: ${statusDetails.name}`}</strong>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.margin}
                style={{ padding: "2%" }}
              >
                <Grid item xs={12}>
                  <TextField
                    className={classes.statusRoot}
                    fullWidth
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="Status"
                    value={values.status}
                    onChange={(e) => onChange(e.target.value, "status")}
                    name="status"
                    id="status"
                    select
                    align="left"
                  >
                    {props.tableData.statusList
                      && props.tableData.statusList.Provider_Status_Data.map(
                        (key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.Status_Code}
                          >
                            {key.Provider_Status}
                          </MenuItem>
                        )
                      )}
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.marginTop}
                style={{
                  padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  color="primary"
                  className={classes.buttonStyle}
                  variant="contained"
                  onClick={() => saveStatus()}
                  size="lg"
                >
                  Update
                </Button>
              </Grid>
            </Card>
          </div>
        </Modal>
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <SimpleDialog
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />
                <IconsTooltip title="Refresh" placement="top">
                  <div className={classes.appSvgIcons}>
                    <SyncIcon style={{marginRight:"10px"}} fontSize = "large" color= "primary" onClick={handleRefresh}/>
                  </div>
                </IconsTooltip>
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                onClick={exportToPDFList}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                {userType === "Internal" && permission && (
                  <IconsTooltip title="Add" placement="top">
                    <div className={classes.appSvgIcons}>
                      <AddIconSvg onClick={() => providerregisterurl()} />
                    </div>
                  </IconsTooltip>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <div className={classes.searchWrapper}>
                <SearchFields
                  searchBar={searchBar}
                  handleSearchClose={handleSearchClose}
                  handleSearchOpen={handleSearchOpen}
                  Fields={() => (
                    <SimpleDialog
                      close={() => handleSearchClose()}
                      seacrhChipData={(data) => displayAndDeleteChips(data)}
                      clearChips={() => setChipValue([])}
                    />
                  )}
                />
                <IconsTooltip title="Export" placement="top">
                  <div className={classes.appSvgIcons}>
                    <ExportIcon
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleToggle}
                      ref={anchorRef}
                    />
                  </div>
                </IconsTooltip>
                <Popper
                  open={openImportFileModal}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{
                    zIndex: "10",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseModel}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToExcel
                            ) && (
                              <MenuItem
                                onClick={exportListData}
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to Excel
                              </MenuItem>
                            )}
                            {actionPermission(
                              permissionCheck.Category.setup,
                              permissionCheck.Page.merchant,
                              permissionCheck.option.exportToPdf
                            ) && (
                              <MenuItem
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Export to PDF
                              </MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                {userType === "Internal" && permission && (
                  <IconsTooltip title="Add" placement="top">
                    <div className={classes.appSvgIcons}>
                      <AddIconSvg onClick={() => providerregisterurl()} />
                    </div>
                  </IconsTooltip>
                )}
              </div>
            </Grid>
          </Grid>
        )}
        <SearchChips
          searchValues={chipValue}
          handleDeleteChip={(chip, index) => handleDeleteChip(chip, index)}
        />
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow className={classes.cellHeadSign} key={index}>
                  <TableCell
                    className={classes.cellText}
                    onClick={() => handleEdit("edit", row.Id)}
                    style={profileCode !== profileCodePermissionCheck.profileCode.VCS ? { width: "300px" } : {}}
                  >
                    {row.Business_Name}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                    style={profileCode !== profileCodePermissionCheck.profileCode.VCS ? { width: "300px" } : {}}
                  >
                    {row.Dba}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => copyToClipboard(row.uid)}
                    onDoubleClick={()=>handleEdit("edit", row.Id)}
                    style={profileCode !== profileCodePermissionCheck.profileCode.VCS ? { width: "250px" } : {}}
                  >
                    <IconsTooltip title={row.uid?"Click to copy":""} placement="top">
                      <span>{row.uid}</span>
                    </IconsTooltip>
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => copyToClipboard(row.Account_id)}
                    onDoubleClick={()=>handleEdit("edit", row.Id)}
                    style={profileCode !== profileCodePermissionCheck.profileCode.VCS ? { width: "250px" } : {}}
                  >
                    <IconsTooltip title={row.Account_id?"Click to copy":""} placement="top">
                      <span>{row.Account_id}</span>
                    </IconsTooltip>
                  </TableCell>
                  {profileCode === profileCodePermissionCheck.profileCode.VCS && (
                    <TableCell
                      className={classes.cellText}
                      align="left"
                      onClick={() => handleEdit("edit", row.Id)}
                    >
                      {row.Manager_name}
                    </TableCell>
                  )}
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                    style={profileCode !== profileCodePermissionCheck.profileCode.VCS ? { width: "250px" } : {}}
                  >
                    {row.Creation_Date}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                    style={profileCode !== profileCodePermissionCheck.profileCode.VCS ? { width: "250px" } : {}}
                  >
                    {row.Update_Date}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => handleEdit("edit", row.Id)}
                    style={profileCode !== profileCodePermissionCheck.profileCode.VCS ? { width: "250px" } : {}}
                  >
                    {row.Status_Code === 1
                      ? "Invited"
                      : row.Status_Code === 3
                        ? "Submitted"
                        : row.Status_Code === 4
                          ? "Awaiting Business Submission"
                          : row.Status_Code === 2
                            ? "Save as Draft"
                            : ""}
                  </TableCell>
                  <TableCell className={classes.cellText}>
                    <MerchantSetupMenu
                      id={row.Id}
                      setStatusPid={() =>
                        setStatusPid({
                          id: row.Id,
                          name: row.DBA_Name,
                          email: row.E_mail,
                          submarchecntId: row.Sub_MerchantID,
                          errorCode: row.Error_Code,
                          errorDescription: row.Error_Description,
                          status: row.status,
                          clientid: row.Client_Id,
                        })
                      }
                      details={row}
                      provider
                      {...props}
                      setValues={setValues}
                      openBoardingModel={() => setopenBoardingModel(true)}
                      openForm={() => setOpenModel(true)}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: totalCount,
                    },
                  ]}
                  colSpan={12}
                  count={totalCount}
                  rowsPerPage={rowsPerPage || 5}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    MenuProps: { classes: { paper: classes.selectDropdown } },
                  }}
                  classes={{ menuItem: classes.menuItem }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ProviderReport.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeProviderFormMode: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  userDetails: PropTypes.object,
  mainData: PropTypes.object,
  getMerchantSetupDataExport: PropTypes.func,
  getMerchantListData: PropTypes.func,
  emptyReduxState: PropTypes.func,
  getMerchantListDataName: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app,
  roles: app.roles,
  merchantListData: app.genrateOfferPatient,
});
export default connect(mapStateToProps, {
  changeProviderFormMode,
  changeSingleStatus,
  getMerchantSetupDataExport,
  getMerchantListData,
  emptyReduxState,
  setFormData,
  clearFormData,
})(ProviderReport);
