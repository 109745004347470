import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { createTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  getErrorCodeData,
  setFormData,
  clearFormData,
} from "../../Redux/Actions/genrateOfferPatient";
import { profileCodePermissionCheck } from "../../Utils/commonUtils";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#003c6e",
      light: "#003c6e",
      dark: "#003c6e",
    },
    secondary: {
      main: "#003c6e",
    },
  },
  overrides: {
    MuiPickersBasePicker: {
      container: {
        width: "300px",
        height: "370px",
      },
      pickerView: {
        width: "280px",
        height: "280px",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        fontSize: "0.9rem",
      },
      dayLabel: {
        fontSize: "0.7rem",
      },
    },
    MuiPickersDay: {
      day: {
        fontSize: "0.8rem",
      },
    },
    MuiTypography: {
      h4: {
        fontSize: "1rem",
      },
    },
  },
});
const useStyles = makeStyles(styles);
const searchButtonStyles = makeStyles(theme => ({
  searchButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%)`,
    },
  },
}));

function ErrorSearchDialog(props) {
  const classes = useStyles();
  const searchButtonClasses = searchButtonStyles();
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const [errorCode1, setErrorCode1] = React.useState("");
  const [description1, setDescription1] = React.useState("");
  const [date1, setDate1] = React.useState("");
  const [type1, setType1] = React.useState("");
  const clearFormData = () => {
    const initialFormData = {
      searchErrorCode: "",
      searchDescription: "",
      searchType: null,
      searchTypeValue: "",
      searchDate: "",
    };
    props.setFormData(initialFormData);
  };

  const handleFromDateChange = date => {
    let formattedDate = null;
    if (date instanceof Date) {
      formattedDate = format(date, "yyyy-MM-dd");
    } else if (typeof date === "string") {
      const parts = date.split("/");
      if (parts.length === 3) {
        formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      }
    }
    setDate1(formattedDate);
  };

  const handleClose = () => {
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === profileCodePermissionCheck.profileCode.SA) {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 5,
        pageNo: 1,
      };
      props.getErrorCodeData(payload);
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 5,
        pageNo: 1,
      };
      props.getErrorCodeData(payload);
    }
    props.clearFormData();
    clearFormData();
    props.clearChips();
  };

  const onChange = e => {
    const { name, value } = e.target;
    if (name === "searchType") {
      setType1(value);
    }
  };

  const onFormSubmit = event => {
    event.preventDefault();
    const submitFormData = {
      searchErrorCode: errorCode1.trim(),
      searchDescription: description1.trim(),
      searchDate: date1,
      searchType: type1,
    };
    props.setFormData(submitFormData);
    const profileCode = menu?.Role_Permission_Data?.Profile_Code;
    if (profileCode === profileCodePermissionCheck.profileCode.SA) {
      const payload = {
        Profile_id: profileId,
        recordPerPage: 5,
        searchErrorCode: errorCode1.trim(),
        searchDescription: description1.trim(),
        searchDate: date1,
        searchType: type1,
        pageNo: 1,
      };
      props.getErrorCodeData(payload);
      props.seacrhChipData(payload);
      props.close();
    } else {
      const payload = {
        Profile_Code: menu?.Role_Permission_Data?.Profile_Code,
        recordPerPage: 5,
        searchErrorCode: errorCode1.trim(),
        searchDescription: description1.trim(),
        searchDate: date1,
        searchType: type1,
        pageNo: 1,
      };
      props.getErrorCodeData(payload);
      props.seacrhChipData(payload);
      props.close();
    }
  };

  React.useEffect(() => {
    if (props.formData.searchErrorCode) {
      setErrorCode1(props.formData.searchErrorCode);
    }
    if (props.formData.searchDescription) {
      setDescription1(props.formData.searchDescription);
    }
    if (props.formData.searchDate) {
      setDate1(props.formData.searchDate);
    }
    if (props.formData.searchType) {
      setType1(props.formData.searchType);
    }
  }, [props.formData]);

  const handleInputChange = e => {
    setErrorCode1(e.target.value);
  };

  const handleInputChange1 = e => {
    setDescription1(e.target.value);
  };
  return (
    <MuiThemeProvider theme={customTheme}>
      <form onSubmit={e => onFormSubmit(e)}>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Error Code"
              value={errorCode1}
              onChange={handleInputChange}
              name="searchErrorCode"
              id="Error Code"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="Description"
              value={description1}
              onChange={handleInputChange1}
              name="searchDescription"
              id="searchDescription"
              style={{ fontSize: "82%" }}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.margin}>
          <Grid item xs container alignItems="center">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                variant="inline"
                label="Date"
                value={date1 ? new Date(date1) : null}
                onChange={handleFromDateChange}
                format="yyyy/MM/dd"
                maxDate={new Date()}
                style={{
                  width: "100%",
                }}
              />

              {inputProps => (
                <TextField
                  {...inputProps}
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLabelColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  label="Date"
                  style={{
                    width: "100%",
                    fontSize: "82%",
                  }}
                />
              )}
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ marginBottom: "5%" }}
        >
          <Grid item xs>
            <TextField
              className={classes.rootDialog}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              placeholder="Select Type"
              label="Type"
              value={type1}
              onChange={onChange}
              name="searchType"
              id="Current_Type"
              select
              style={{ fontSize: "82%" }}
            >
              <MenuItem className={classes.dialogBoxDropDown} value="FedNow">
                FedNow
              </MenuItem>
              <MenuItem className={classes.dialogBoxDropDown} value="RTP">
                RTP
              </MenuItem>
              <MenuItem
                className={classes.dialogBoxDropDown}
                value="FedNow/RTP"
              >
                FedNow/RTP
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.margin}
          style={{ justifyContent: "end" }}
        >
          <Button
            variant="contained"
            className={classes.clearButton}
            disabled={
              errorCode1 === ""
              && description1 === ""
              && date1 === ""
              && type1 === ""
            }
            onClick={() => handleClose()}
          >
            clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.buttonStyle} ${searchButtonClasses.searchButton}`}
            disabled={
              errorCode1 === ""
              && description1 === ""
              && date1 === ""
              && type1 === ""
            }
            type="submit"
          >
            Search
          </Button>
        </Grid>
      </form>
    </MuiThemeProvider>
  );
}

ErrorSearchDialog.propTypes = {
  close: PropTypes.func,
  tableData: PropTypes.object,
  seacrhChipData: PropTypes.func,
  getErrorCodeData: PropTypes.func,
};

const mapStateToProps = app => ({
  formData: app.genrateOfferPatient,
  tableData: app.provideRegistration,
  userDetails: app.app,
  errorCodeData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  setFormData,
  clearFormData,
  getErrorCodeData,
})(ErrorSearchDialog);
