/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";
import {
  ErrorMessage, Field, Form, Formik,
} from "formik";
import Card from "components/Card/Card";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import viking from "../../assets/img/vikingstransparent_logo.png";
import CommonDialog from "../../components/DialogBox/Common";
import Validations from "../../Utils/Validations";
import { profileCodePermissionCheck } from "../../Utils/commonUtils";
const useStyles = makeStyles(styles);
const ChangePassword = (props) => {
  const [preForgetPassword, setPrePassword] = React.useState(
    props.formData.forgetPassword
  );
  const [callStatus, setCallStatus] = React.useState("");
  const classes = useStyles();

  const onClose = () => {
    setCallStatus("");
    if (props?.formData?.changePassword?.data === profileCodePermissionCheck.profileCode.VCS) {
      props.history.push("/admin/login");
    } else {
      props.history.push("/");
    }
  };

  React.useState(() => {
    if (preForgetPassword !== props.formData.forgetPassword) {
      setPrePassword(props.formData.forgetPassword);
    }
  });
  React.useEffect(() => {
    if (
      props.formData.changePassword.status
      && props.formData.changePassword.message
    ) {
      setCallStatus(props.formData.changePassword.message);
    }
  }, [props]);

  const onFormSubmit = (values) => {
    const data = {
      userId: props.formData.userIdForgetPassword
        ? props.formData.userIdForgetPassword
        : props.formData.authTokenCheck.data,
      password: values.password,
    };
    props.changePasswordCall(data);
  };

  return (
    <Card style={{ width: "35%" }}>
      <Grid container style={{ marginTop: "8%" }}>
        <Grid item xs align="center">
          <img src={viking} width="30%" height="auto" alt="" />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "8%", marginBottom: "1%" }}>
        <Grid
          item
          xs
          align="center"
          style={{ color: "#094d81", fontSize: "18px", fontWeight: "bold" }}
        >
          Change your Password
        </Grid>
      </Grid>
      <Formik
        validationSchema={Validations.resetPassword}
        enableReinitialize
        onSubmit={onFormSubmit}
        initialValues={{ password: "", confirmPassword: "" }}
      >
        {({ values, errors }) => (
          <Form>
            <Grid container>
              <Grid item xs={2} />
              <Grid item xs={10}>
                <Field
                  as={TextField}
                  error={errors.password}
                  className={classes.root}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Password"
                  type="password"
                  name="password"
                  id="password"
                />
                <ErrorMessage name="password">
                  {(msg) => (
                    <Typography
                      component="span"
                      className="error"
                      variant="body2"
                    >
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "2%" }}>
              <Grid item xs={2} />
              <Grid item xs={10}>
                <Field
                  as={TextField}
                  error={errors.confirmPassword}
                  className={classes.root}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                />
                <ErrorMessage name="confirmPassword">
                  {(msg) => (
                    <Typography
                      component="span"
                      className="error"
                      variant="body2"
                    >
                      {msg}
                    </Typography>
                  )}
                </ErrorMessage>
              </Grid>
            </Grid>
            <div
              style={{
                paddingTop: "4%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a style={{ color: "#b37029" }} href="/">
                Back to login
              </a>
            </div>
            <Grid
              container
              align="center"
              style={{ marginTop: "6%", marginBottom: "10%" }}
            >
              <Grid item xs textAlign="center">
                <Button
                  variant="contained"
                  disabled={
                    !isEmpty(errors)
                    && values.password !== values.confirmPassword
                  }
                  type="submit"
                  size="large"
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <CommonDialog
        message={callStatus}
        open={!!callStatus}
        onClose={onClose}
      />
    </Card>
  );
};

ChangePassword.propTypes = {
  formData: PropTypes.object,
  changePasswordCall: PropTypes.func,
  history: PropTypes.object,
};

export default ChangePassword;
