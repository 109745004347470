import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import WebIcon from "@material-ui/icons/Web";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "components/Card/Card";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from "@material-ui/core/Divider";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import PublicIcon from "@material-ui/icons/Public";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import RoomIcon from "@material-ui/icons/Room";
import closeImage from "assets/img/Close_Image.png";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { CardActions } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Modal from "@material-ui/core/Modal";
import AppLoader from "components/AppLoader/Index";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getRoutingNumberData,
  emptyMerchantRTNReduxState,
  getAccountNumberData,
  emptyMerchantAccReduxState,
  getEmailList,
  getEmailData,
  emptyEmailListReduxState,
} from "Redux/Actions/genrateOfferPatient";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import CryptoJS from "crypto-js";
import { locationCity } from "../../constant/locationConstant";
import { getCountryTypeList } from "../../Redux/Actions/ProvideRegistration";
import { getMerchantListData } from "../../Redux/Actions/genrateOfferPatient";
import { sendInviteApiCall, submitApiCall } from "../../actions/Operations";
import { actionPermission, permissionCheck } from "../../Utils/commonUtils";
import EmailAutofill from "./EmailAutofill";
import { profileCodePermissionCheck } from "../../Utils/commonUtils";

const useStyles = makeStyles(styles);

function Signup(props) {
  const [validationModal, setValidationModal] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = React.useState(false);

  const theme = useTheme();
  const [id, setId] = React.useState(null);

  const smallDevice = useMediaQuery(theme.breakpoints.down("xs"));
  const [sameAsMailing, setSameAsMailing] = React.useState(true);
  // const [saveDraftClick, setSaveDraftClick] = React.useState(false);
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [managerOBJid, setManagerOBJid] = React.useState();
  const [businessOBJid, setBusinessOBJid] = React.useState();
  const profileId = localStorage.getItem("profileId");
  const menu = JSON.parse(localStorage.getItem("menu"));
  const profileCode = menu?.Role_Permission_Data?.Profile_Code;
  const windowWidthLG = useMediaQuery(theme.breakpoints.up("lg"));
  const [disabled1, setDisabled1] = React.useState(false);

  const [isRevealAcn, setIsRevealAcn] = React.useState(false);
  const [isRevealAcnConfirm, setIsRevealAcnConfirm] = React.useState(false);
  const [confirmAccountNumberVisible, setConfirmAccountNumberVisible] =
    React.useState(false);
  const [confirmRoutingNumberVisible, setConfirmRoutingNumberVisible] =
    React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});
  const dispatch = useDispatch();
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const idFromUrl = queryParams.get("id");
    setId(idFromUrl);
  }, []);

  React.useEffect(() => {
    if (id) {
      const payload = {
        _id: id,
      };
      props.getMerchantListData(payload);
    }
  }, [id]);

  const [values, handleInput] = React.useState({
    Requested_for_id: "",
    UpdateMg_id: "",
    Requested_by_id: "",
    Invite_Mail_Status: false,
    businessName: "",
    dba: "",
    businessWebsite: "",
    bsinessDescription: "",
    businessRoom: "",
    businessFloor: "",
    businessStreet: "",
    businessCountry: "US",
    businessState: "",
    businessCity: "",
    businessPostalCode: "",
    mailingRoom: "",
    mailingFloor: "",
    mailingStreet: "",
    mailingCountry: "US",
    mailingState: "",
    mailingCity: "",
    mailingPostalCode: "",
    routingNumber: "",
    accountNumber: "",
    confirmAccountNumber: "",
    confirmRoutingNumber: "",
    bankDetailsCannotBeEdited: false,
    AddressDetailsChecked: true,
    feeTypeSurcharge: true,
    feeRate: "",
    perTransactionFee: "",
    percentageFee: "",
    aboveTransactionValue: "",
    none: "",
    feeCanNotBeEdited: false,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    managerRoom: "",
    managerFloor: "",
    managerStreet: "",
    managerCountry: "",
    managerState: "",
    managerCity: "",
    managerPostalCode: "",
    Manager_id: "",
    userId: "",
    Status_Code: "",
  });
  const [validationError, setVlidationError] = React.useState({
    Invite_Mail_Status: false,
    businessName: false,
    dba: false,
    businessWebsite: false,
    bsinessDescription: false,
    businessRoom: false,
    businessFloor: false,
    businessStreet: false,
    businessCountry: false,
    businessState: false,
    businessCity: false,
    businessPostalCode: false,
    mailingRoom: false,
    mailingFloor: false,
    mailingStreet: false,
    mailingCountry: false,
    mailingState: false,
    mailingCity: false,
    mailingPostalCode: false,
    routingNumber: false,
    accountNumber: false,
    confirmAccountNumber: false,
    confirmRoutingNumber: false,
    feeRate: false,
    perTransactionFee: false,
    percentageFee: false,
    aboveTransactionValue: false,
    none: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
    managerRoom: false,
    managerFloor: false,
    managerStreet: false,
    managerCountry: false,
    managerState: false,
    managerCity: false,
    managerPostalCode: false,
    Manager_id: false,
    userId: false,
    Status_Code: false,
  });

  const checkValidation = (saveDraft = false) => {
    let validate = true;
    const error = { ...validationError };
    const {
      AddressDetailsChecked,
      mailingRoom,
      mailingFloor,
      mailingStreet,
      mailingCountry,
      mailingState,
      mailingCity,
      mailingPostalCode,
    } = values;

    if (
      !AddressDetailsChecked &&
      !mailingRoom &&
      !mailingFloor &&
      !mailingStreet &&
      !mailingCountry &&
      !mailingState &&
      !mailingCity &&
      !mailingPostalCode
    ) {
      values.AddressDetailsChecked = true;

      values.mailingRoom = values.businessRoom;
      values.mailingFloor = values.businessFloor;
      values.mailingStreet = values.businessStreet;
      values.mailingCountry = values.businessCountry;
      values.mailingState = values.businessState;
      values.mailingCity = values.businessCity;
      values.mailingPostalCode = values.businessPostalCode;
    }

    for (const key in values) {
      if (
        (values[key] === "" ||
          values[key] === null ||
          values[key] === undefined) &&
        (key === "businessName" ||
          key === "feeRate" ||
          key === "firstName" ||
          key === "lastName" ||
          key === "email")
      ) {
        error[key] = true;
        validate = false;
      }

      if (key === "lastName") {
        if (/^\d/.test(values[key])) {
          error[key] = true;
          validate = false;
        }
      }

      if (key === "firstName") {
        if (/^\d/.test(values[key])) {
          error[key] = true;
          validate = false;
        }
      }

      if (key === "phoneNumber") {
        if (values[key] !== "" && values[key] !== null) {
          if (values[key]?.length !== 10) {
            error[key] = true;
            validate = false;
          }
        }
      }

      if (key === "routingNumber") {
        if (confirmRoutingNumberVisible) {
          if (
            values[key] !== "" &&
            values[key] !== null &&
            (values[key] !== values.confirmRoutingNumber ||
              values[key].length !== 9 ||
              !props.routingNumberData?.available ||
              values.confirmRoutingNumber === "" ||
              values.confirmRoutingNumber === null)
          ) {
            error[key] = true;
            error.confirmRoutingNumber = true;
            validate = false;
          }
        } else if (
          values[key] !== "" &&
          values[key] !== null &&
          values[key].length !== 9
        ) {
          error[key] = true;
          validate = false;
        }
      }

      if (key === "accountNumber") {
        if (confirmAccountNumberVisible) {
          if (
            (values[key] !== "" &&
              values[key] !== null &&
              (values[key] !== values.confirmAccountNumber ||
                values[key].length < 8 ||
                values[key].length > 17 ||
                !props.accountNumberData.status)) ||
            values.confirmAccountNumber === "" ||
            values.confirmAccountNumber === null
          ) {
            error[key] = true;
            error.confirmAccountNumber = true;
            validate = false;
          }
        } else if (
          values[key] !== "" &&
          values[key] !== null &&
          (values[key].length < 8 || values[key].length > 17)
        ) {
          error[key] = true;
          validate = false;
        }
      }

      if (key === "feeRate") {
        if (values.feeRate === "Per Transaction" && !values.perTransactionFee) {
          error.perTransactionFee = true;
          validate = false;
        }
        if (values.feeRate === "Percentage" && !values.percentageFee) {
          error.percentageFee = true;
          validate = false;
        }
        if (
          values.feeRate === "Per transaction + Percentage" &&
          (!values.perTransactionFee ||
            !values.percentageFee ||
            !values.aboveTransactionValue)
        ) {
          error.perTransactionFee = !values.perTransactionFee;
          error.percentageFee = !values.percentageFee;
          error.aboveTransactionValue = !values.aboveTransactionValue;
          validate = false;
        }
      }
      if (
        key === "email" &&
        (!values[key] || !/^\S+@\S+\.\S+$/.test(values[key]))
      ) {
        error[key] = true;
        validate = false;
      }
    }
    setVlidationError(error);
    if (!saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    if (saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    return validate;
  };

  const checkValidationSubmit = (saveDraft = false) => {
    let validate = true;
    const error = { ...validationError };

    for (const key in values) {
      if (
        (values[key] === "" ||
          values[key] === null ||
          values[key] === undefined) &&
        (key === "businessName" ||
          key === "feeRate" ||
          key === "firstName" ||
          key === "lastName" ||
          key === "businessPostalCode" ||
          key === "managerCity" ||
          key === "managerPostalCode" ||
          key === "managerState" ||
          key === "managerCountry" ||
          key === "managerStreet" ||
          key === "phoneNumber" ||
          key === "accountNumber" ||
          key === "routingNumber" ||
          key === "businessCity" ||
          key === "businessState" ||
          key === "businessCountry" ||
          key === "businessStreet" ||
          (key === "mailingCity" && !values.AddressDetailsChecked) ||
          (key === "mailingState" && !values.AddressDetailsChecked) ||
          (key === "mailingCountry" && !values.AddressDetailsChecked) ||
          (key === "mailingStreet" && !values.AddressDetailsChecked) ||
          (key === "mailingPostalCode" && !values.AddressDetailsChecked) ||
          key === "email")
      ) {
        error[key] = true;
        validate = false;
      }

      if (values.AddressDetailsChecked) {
        error.mailingCity = false;
        error.mailingState = false;
        error.mailingCountry = false;
        error.mailingStreet = false;
        error.mailingPostalCode = false;
      }

      if (key === "businessPostalCode") {
        if (values[key] !== "" && values[key]?.length !== 5) {
          validate = false;
        }
        if (error[key]) {
          validate = false;
        }
      }
      if (key === "mailingPostalCode") {
        if (values[key] !== "" && values[key]?.length !== 5) {
          validate = false;
        }
        if (error[key]) {
          validate = false;
        }
      }
      if (key === "routingNumber") {
        if (values.bankDetailsCannotBeEdited) {
          if (
            (values[key] === "" && values[key] == null) ||
            values[key]?.length !== 9
          ) {
            error[key] = true;
            validate = false;
          }
        } else if (confirmRoutingNumberVisible) {
          if (
            values[key] === "" ||
            values[key]?.length !== 9 ||
            values[key] !== values.confirmRoutingNumber ||
            !props.routingNumberData?.available ||
            values.confirmRoutingNumber === "" ||
            values.confirmRoutingNumber === null
          ) {
            error[key] = true;
            error.confirmRoutingNumber = true;
            validate = false;
          }
        } else if (
          values[key] !== "" &&
          values[key] !== null &&
          values[key]?.length !== 9
        ) {
          error[key] = true;
          validate = false;
        } else if (
          values.routingNumber === "" ||
          values.routingNumber == null ||
          values.routingNumber === undefined
        ) {
          error[key] = true;
          validate = false;
        }
      }

      if (key === "accountNumber") {
        if (values.bankDetailsCannotBeEdited) {
          if (
            (values[key] === "" && values[key] == null) ||
            (values[key]?.length < 8 && values[key]?.length > 17)
          ) {
            error[key] = true;
            validate = false;
          }
        } else if (confirmAccountNumberVisible) {
          if (
            values[key] === "" ||
            values[key]?.length < 8 ||
            values[key]?.length > 17 ||
            values[key] !== values.confirmAccountNumber ||
            !props.accountNumberData.status ||
            values.confirmAccountNumber === "" ||
            values.confirmAccountNumber === null
          ) {
            error[key] = true;
            error.confirmAccountNumber = true;
            validate = false;
          }
        } else if (
          values[key] !== "" &&
          values[key] !== null &&
          (values[key]?.length < 8 || values[key]?.length > 17)
        ) {
          error[key] = true;
          validate = false;
        } else if (
          values.accountNumber === "" ||
          values.accountNumber == null ||
          values.accountNumber === undefined
        ) {
          error[key] = true;
          validate = false;
        }
      }
      if (key === "feeRate") {
        if (values.feeRate === "Per Transaction" && !values.perTransactionFee) {
          error.perTransactionFee = true;
          validate = false;
        }
        if (values.feeRate === "Percentage" && !values.percentageFee) {
          error.percentageFee = true;
          validate = false;
        }
        if (
          values.feeRate === "Per transaction + Percentage" &&
          (!values.perTransactionFee ||
            !values.percentageFee ||
            !values.aboveTransactionValue)
        ) {
          error.perTransactionFee = !values.perTransactionFee;
          error.percentageFee = !values.percentageFee;
          error.aboveTransactionValue = !values.aboveTransactionValue;
          validate = false;
        }
      }
    }
    setVlidationError(error);
    if (!saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    if (saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    return validate;
  };

  const fetchEmailList = React.useCallback(
    debounce((query) => {
      dispatch(getEmailList(query, "main"));
    }, 300),
    [dispatch]
  );

  const handleEmailChange = (email, agentsList) => {
    const normalizedEmail = email.toLowerCase();
    handleInput((prevValues) => ({ ...prevValues, email: normalizedEmail }));

    setVlidationError((prevError) => ({
      ...prevError,
      email: !/^\S+@\S+\.\S+$/.test(normalizedEmail),
    }));

    if (normalizedEmail.length >= 4) {
      fetchEmailList(normalizedEmail);
    } else {
      handleInput((prevValues) => ({
        ...prevValues,
        managerRoom: "",
        managerFloor: "",
        managerStreet: "",
        managerCountry: "",
        managerState: "",
        managerCity: "",
        managerPostalCode: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
      }));
      dispatch(emptyEmailListReduxState());
    }

    const emailExists = agentsList?.includes(normalizedEmail);

    if (emailExists && /^\S+@\S+\.\S+$/.test(normalizedEmail)) {
      dispatch(getEmailData(normalizedEmail));
    }
  };

  useEffect(() => {
    if (props?.merchantListData?.emailListData?.data && values.email) {
      const emailExists = props.merchantListData.emailListData.data.includes(
        values.email
      );
      if (emailExists) {
        dispatch(getEmailData(values.email));
      }
    }
  }, [props?.merchantListData?.emailListData?.data, values.email]);

  React.useEffect(() => {
    if (
      values.confirmAccountNumber !== "" &&
      values.accountNumber !== values.confirmAccountNumber
    ) {
      const error = { ...validationError, confirmAccountNumber: true };
      setVlidationError(error);
    }
  }, [values]);

  React.useEffect(() => {
    if (
      values.confirmRoutingNumber !== "" &&
      values.routingNumber !== values.confirmRoutingNumber
    ) {
      const error = { ...validationError, confirmRoutingNumber: true };
      setVlidationError(error);
    }
  }, [values]);

  React.useEffect(() => {
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      const addressChecked =
        props?.merchantListData?.merchantListData &&
        props?.merchantListData?.merchantListData?.data[0]
          ?.AddressDetailsChecked;
      if (addressChecked === true) {
        setSameAsMailing(true);
      } else {
        setSameAsMailing(false);
      }
    }
  }, [props]);

  React.useEffect(() => {
    setBusinessOBJid(
      props.signUpData.genrateOfferPatient.merchantListData &&
        props.signUpData?.genrateOfferPatient?.merchantListData?.data[0]?._id
    );
    setManagerOBJid(
      props.signUpData.genrateOfferPatient.merchantListData &&
        props.signUpData?.genrateOfferPatient?.merchantListData?.data[0]
          ?.Manager_Details?._id
    );
  }, [props.signUpData?.genrateOfferPatient?.merchantListData]);

  React.useEffect(() => {
    const result =
      props.merchantListData.merchantListData &&
      props.merchantListData.merchantListData.data.map((items) => ({
        uid: items?.uid,
        Account_id: items?.Account_id,
        AddressDetailsChecked: items?.AddressDetailsChecked,
        Business_Name: items?.Business_Name,
        Dba: items?.Dba,
        Business_Website: items?.Business_Website,
        Business_Description: items?.Business_Description,
        Business_Phone_Number: items?.Business_Phone_Number,
        Email: items?.Manager_Details?.Email,
        _id: items?._id,
        Created_By: items?.Created_By,
        Manager_id: items?.Manager_id,
        Invite_Date: items?.Invite_Date,
        Created_date: items?.Created_date,
        Updated_date: items?.Updated_date,
        First_Name: items?.Manager_Details?.First_Name,
        Last_Name: items?.Manager_Details?.Last_Name,
        Phone_Number: items?.Manager_Details?.Phone_Number,
        Status_Code: items.Status_Code,
        Business_Address_Room: items?.Business_Address?.Room_No,
        Business_Address_Floor: items?.Business_Address?.Floor,
        Business_Address_Street: items?.Business_Address?.Street,
        Business_Address_Country: items?.Business_Address?.Country,
        Business_Address_State: items?.Business_Address?.State,
        Business_Address_City: items?.Business_Address?.City,
        Business_Address_Postal_Code: items?.Business_Address?.Postal_Code,
        Mailing_Address_Room: items?.Mailing_Address?.Room_No,
        Mailing_Address_Floor: items?.Mailing_Address?.Floor,
        Mailing_Address_Street: items?.Mailing_Address?.Street,
        Mailing_Address_Country: items?.Mailing_Address?.Country,
        Mailing_Address_State: items?.Mailing_Address?.State,
        Mailing_Address_City: items?.Mailing_Address?.City,
        Mailing_Address_Postal_Code: items?.Mailing_Address?.PostalCode,
        Account_Number: items?.Banking_Details?.Account_Number,
        Routing_Number: items?.Banking_Details?.Routing_Number,
        Bank_Details_Cannot_Be_Edited:
          items?.Banking_Details?.bankDetailsCannotBeEdited,
        FeeType_Name: items?.FeeType?.FeeTypeName,
        FeeType_Discounted: items?.FeeType?.feeTypeDiscounted,
        FeeType_Surcharge: items?.FeeType?.feeTypeSurcharge,
        FeeType_perTransactionFee: items?.FeeType?.perTransactionFee,
        FeeType_percentageFee: items?.FeeType?.percentageFee,
        FeeType_aboveTransactionValue: items?.FeeType?.aboveTransactionValue,
        Fee_can_not_be_edited: items?.FeeType?.feeCanNotBeEdited,
        Manager_Details_Room: items?.Manager_Details?.Room,
        Manager_Details_Floor: items?.Manager_Details?.Floor,
        Manager_Details_Street: items?.Manager_Details?.Street,
        Manager_Details_Country: items?.Manager_Details?.Country,
        Manager_Details_State: items?.Manager_Details?.State,
        Manager_Details_City: items?.Manager_Details?.City,
        Manager_Details_PostalCode: items?.Manager_Details?.PostalCode,
        Requested_by_id: items?.Manager_Details?._id,
        UpdateMg_id: items?.Manager_Details?._id,
        Requested_for_id: items?._id,
      }));
    const newResult = result && result[0];
    if (newResult && values.businessName === "") {
      handleInput({
        ...values,
        Requested_by_id: newResult?.Requested_by_id,
        UpdateMg_id: newResult?.UpdateMg_id,
        Requested_for_id: newResult?.Requested_for_id,
        uid: newResult?.uid,
        Account_id: newResult?.Account_id,
        AddressDetailsChecked: newResult?.AddressDetailsChecked,
        businessName: newResult?.Business_Name,
        dba: newResult?.Dba,
        businessWebsite: newResult?.Business_Website,
        bsinessDescription: newResult?.Business_Description,
        businessRoom: newResult?.Business_Address_Room,
        businessFloor: newResult?.Business_Address_Floor,
        businessStreet: newResult?.Business_Address_Street,
        businessCountry: newResult?.Business_Address_Country,
        businessState: newResult?.Business_Address_State,
        businessCity: newResult?.Business_Address_City,
        businessPostalCode: newResult?.Business_Address_Postal_Code,
        mailingRoom: newResult?.Mailing_Address_Room,
        mailingFloor: newResult?.Mailing_Address_Floor,
        mailingStreet: newResult?.Mailing_Address_Street,
        mailingCountry: newResult?.Mailing_Address_Country,
        mailingState: newResult?.Mailing_Address_State,
        mailingCity: newResult?.Mailing_Address_City,
        mailingPostalCode: newResult?.Mailing_Address_Postal_Code,
        routingNumber: newResult?.Routing_Number,
        accountNumber: newResult?.Account_Number,
        feeRate: newResult?.FeeType_Name,
        perTransactionFee: newResult?.FeeType_perTransactionFee,
        percentageFee: newResult?.FeeType_percentageFee,
        aboveTransactionValue: newResult?.FeeType_aboveTransactionValue,
        feeCanNotBeEdited: newResult?.Fee_can_not_be_edited,
        bankDetailsCannotBeEdited: newResult?.Bank_Details_Cannot_Be_Edited,
        firstName: newResult?.First_Name,
        lastName: newResult?.Last_Name,
        email: newResult?.Email,
        phoneNumber: newResult?.Phone_Number,
        managerRoom: newResult?.Manager_Details_Room,
        managerFloor: newResult?.Manager_Details_Floor,
        managerStreet: newResult?.Manager_Details_Street,
        managerCountry: newResult?.Manager_Details_Country,
        managerState: newResult?.Manager_Details_State,
        managerCity: newResult?.Manager_Details_City,
        managerPostalCode: newResult?.Manager_Details_PostalCode,
        Status_Code: newResult?.Status_Code,
        Business_id: newResult?._id,
      });
    }
  }, [props]);

  React.useEffect(() => {
    if (values.accountNumber === "" || values.routingNumber === "") {
      setDisabled1(true);
      handleInput((prevState) => ({
        ...prevState,
        bankDetailsCannotBeEdited: false,
      }));
    } else {
      setDisabled1(false);
    }
  }, [values.accountNumber, values.routingNumber]);

  useEffect(() => {
    props.emptyMerchantAccReduxState();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(emptyEmailListReduxState());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (props?.merchantListData?.emailData) {
      const {
        City,
        Country,
        First_Name,
        Floor,
        Last_Name,
        Phone_Number,
        PostalCode,
        Room,
        State,
        Street,
      } = props?.merchantListData?.emailData;
      handleInput((prevValues) => ({
        ...prevValues,
        managerRoom: Room,
        managerFloor: Floor,
        managerStreet: Street,
        managerCountry: Country,
        managerState: State,
        managerCity: City,
        managerPostalCode: PostalCode,
        firstName: First_Name,
        lastName: Last_Name,
        phoneNumber: Phone_Number,
      }));
    }
  }, [props?.merchantListData?.emailData]);

  React.useEffect(() => {
    const updatedError = { ...validationError };
    if (values.firstName) {
      onChange(values.firstName, "firstName");

      if (/^\d/.test(values.firstName)) {
        updatedError.firstName = true;
      } else {
        updatedError.firstName = false;
      }
    }
    if (values.lastName) {
      onChange(values.lastName, "lastName");

      if (/^\d/.test(values.lastName)) {
        updatedError.lastName = true;
      } else {
        updatedError.lastName = false;
      }
    }
    setVlidationError(updatedError);
  }, [values.firstName, values.lastName]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    if (user) {
      dispatch(getEmailData(user));
    } else {
      handleInput((prevValues) => ({
        ...prevValues,
        managerRoom: "",
        managerFloor: "",
        managerStreet: "",
        managerCountry: "",
        managerState: "",
        managerCity: "",
        managerPostalCode: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
      }));
      dispatch(emptyEmailListReduxState());
    }
  };

  function formatPhoneNumber(v) {
    let r = v?.replace(/\D/g, "");
    if (r?.length > 11) {
      r = r?.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r?.length > 6) {
      r = r?.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r?.length > 3) {
      r = r?.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r?.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  const handleClose = () => {
    setValidationModal(false);
  };

  const handleFormSubmit = (value) => {
    setLoading(true);
    // setSaveDraftClick(true);
    handleClose();
    setValidationModal(false);
    if (checkValidationSubmit(true)) {
      if (value === "submit") {
        const payload = {
          ...values,
          Module_Name: "Merchant Submit Page",
          Module_Code: 2,
          Status_Code: 3,
          Manager_id: profileId,
          id,
        };
        const encryptedPayload = encryptPayload(payload);
        props.submitApiCall(
          { payload: encryptedPayload },
          (res) => {
            setLoading(false);
            if (res.status === true) {
              enqueueSnackbar(`${res.message}`, {
                variant: "success",
              });
              props.history.push(`/${props.mainData.app.layout}/Merchant`);
            }
          },
          (err) => {
            setLoading(false);
            enqueueSnackbar(`${err.message}`, {
              variant: "error",
            });
          }
        );
      }
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const encryptPayload = (payload) =>
    CryptoJS.AES.encrypt(JSON.stringify(payload), secretKey).toString();

  const onFormSubbmit = (value) => {
    setLoading(true);
    // setSaveDraftClick(true);
    handleClose();
    setValidationModal(false);
    if (checkValidation(true)) {
      if (value === "saveDraft") {
        props.sendInviteApiCall(
          {
            ...values,
            Status_Code: 2,
            Business_OBJid: businessOBJid,
            Manager_OBJid: managerOBJid,
          },
          (res) => {
            setLoading(false);
            if (res.status === true) {
              enqueueSnackbar(`${res.message}`, {
                variant: "success",
              });
              props.history.push(`/${props.mainData.app.layout}/Merchant`);
            }
          },
          (err) => {
            setLoading(false);
            enqueueSnackbar(`${err.message}`, {
              variant: "error",
            });
          }
        );
      } else if (value === "sendInvite") {
        props.sendInviteApiCall(
          { ...values, Status_Code: 1 },
          (res) => {
            setLoading(false);
            if (res.status === true) {
              enqueueSnackbar(`${res.message}`, {
                variant: "success",
              });

              props.history.push(`/${props.mainData.app.layout}/Merchant`);
            }
          },
          (err) => {
            setLoading(false);
            enqueueSnackbar(`${err.message}`, {
              variant: "error",
            });
          }
        );
      } else if (value === "resendInvite") {
        props.sendInviteApiCall(
          {
            ...values,
            Resend_Invite: true,
            Business_OBJid:
              props?.merchantListData?.merchantListData?.data[0]?._id,
            Manager_OBJid:
              props?.merchantListData?.merchantListData?.data[0]
                ?.Manager_Details?._id,
          },
          (res) => {
            setLoading(false);
            if (res.status === true) {
              enqueueSnackbar(`${res.message}`, {
                variant: "success",
              });

              props.history.push(`/${props.mainData.app.layout}/Merchant`);
            }
          },
          (err) => {
            setLoading(false);
            enqueueSnackbar(`${err.message}`, {
              variant: "error",
            });
          }
        );
      }
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const handleErrorMessage = () => {
    setLoading(false);
    setValidationModal(false);
    setErrorMessage(false);
    // setSaveDraftClick(false);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    if (
      type === "firstName" ||
      type === "lastName" ||
      type === "businessName" ||
      type === "dba"
    ) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (type === "email") {
      value = value.toLowerCase();
    }
    allState[type] = value;
    allState.Manager_id = profileId;
    allState.userId = "";
    if (sameAsMailing) {
      allState.mailingRoom = allState.businessRoom;
      allState.mailingFloor = allState.businessFloor;
      allState.mailingStreet = allState.businessStreet;
      allState.mailingCountry = allState.businessCountry;
      allState.mailingState = allState.businessState;
      allState.mailingCity = allState.businessCity;
      allState.mailingPostalCode = allState.businessPostalCode;
    }

    let accountNumberEdited = false;
    let routingNumberEdited = false;

    if (type === "businessWebsite") {
      const res = value.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "mailingPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "managerPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "accountNumber") {
      accountNumberEdited = true;
      if (value.length < 8 || value.length > 17) {
        error[type] = true;
        allState[type] = value;
      }
      if (value.length === 8) {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "confirmAccountNumber") {
      if (value !== values.accountNumber) {
        error[type] = true;
        allState[type] = value;
      }
      if (value === values.accountNumber) {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "confirmRoutingNumber") {
      if (value !== values.routingNumber) {
        error[type] = true;
        allState[type] = value;
      }
      if (value === values.routingNumber) {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "email") {
      const res = value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "routingNumber") {
      routingNumberEdited = true;
      if (!/^[0-9]{9}$/.test(value)) {
        if (value.length !== 9) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 9) {
          error[type] = false;
          allState[type] = value;
        }
      }
    }
    if (accountNumberEdited) {
      setConfirmAccountNumberVisible(true);
    }
    if (routingNumberEdited) {
      setConfirmRoutingNumberVisible(true);
    }
    if (type === "feeRate") {
      allState.feeRate = value;
      allState.perTransactionFee = "";
      allState.percentageFee = "";
      allState.aboveTransactionValue = "";
      allState.none = "";
    }
    if (type === "feeTypeSurcharge" && value) {
      allState.feeTypeDiscounted = false;
    }
    if (type === "feeTypeDiscounted" && value) {
      allState.feeTypeSurcharge = false;
    }
    if (type === "phoneNumber") {
      const formattedNumber = value.replace(/\D/g, "").slice(0, 10);
      allState[type] = formattedNumber;
    }
    handleInput(allState);
    setVlidationError(error);
  };

  const displayAccountNumber = () => {
    const { accountNumber } = values;
    if (!accountNumber) {
      return "";
    }
    if (isRevealAcn || values?.accountNumber?.length <= 4) {
      return values?.accountNumber;
    } else {
      const maskedNumber =
        "*".repeat(values?.accountNumber?.length - 4) +
        values?.accountNumber?.slice(-4);
      return maskedNumber;
    }
  };

  const displayConfirmAccountNumber = () => {
    if (isRevealAcnConfirm || values?.confirmAccountNumber?.length <= 4) {
      return values?.confirmAccountNumber;
    } else {
      const maskedNumber =
        "*".repeat(values?.confirmAccountNumber?.length - 4) +
        values?.confirmAccountNumber?.slice(-4);
      return maskedNumber;
    }
  };

  const handleEditFeeCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    handleInput((prevState) => ({
      ...prevState,
      feeCanNotBeEdited: isChecked,
    }));
  };

  const handleBankDetailsCheckboxChange = (event) => {
    if (!disabled1) {
      const isChecked1 = event.target.checked;
      handleInput((prevState) => ({
        ...prevState,
        bankDetailsCannotBeEdited: isChecked1,
      }));
    }
  };

  const handleSameAsMailingChange = (event) => {
    setSameAsMailing(event.target.checked);
    const isChecked = event.target.checked;
    handleInput((values) => ({
      ...values,
      AddressDetailsChecked: isChecked,
      mailingRoom: values.businessRoom,
      mailingFloor: values.businessFloor,
      mailingStreet: values.businessStreet,
      mailingCountry: values.businessCountry,
      mailingState: values.businessState,
      mailingCity: values.businessCity,
      mailingPostalCode: values.businessPostalCode,
    }));
    if (!event.target.checked) {
      handleInput((prevValues) => ({
        ...prevValues,
        mailingRoom: "",
        mailingFloor: "",
        mailingStreet: "",
        mailingCountry: "",
        mailingState: "",
        mailingCity: "",
        mailingPostalCode: "",
      }));
    }
  };

  const clearRoutingNumberData = () => {
    props.emptyMerchantRTNReduxState();
  };

  const clearAccountNumberData = () => {
    props.emptyMerchantAccReduxState();
  };

  const handleBack = () => {
    localStorage.setItem("BackPressed", true);
    props.history.push(`/${props.mainData.app.layout}/MerchantSetup`);
  };

  return (
    <div style={{ paddingBottom: "40px" }}>
      <AppLoader loader={!validationModal && loading} />
      <Modal open={validationModal} onClose={() => setValidationModal(false)}>
        <div className={classes.modalDiv}>
          <Card
            style={{
              width: "50%",
              maxWidth: "40%",
              height: "auto",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                minInlineSize: "-webkit-fill-available",
                textAlign: "start",
                height: "auto",
                maxHeight: "70vh",
                display: "flex",
                overflow: "auto",
                flexWrap: "nowrap",
                marginTop: "10%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={closeImage}
                style={{ width: "10%", marginBottom: "3%" }}
                alt=""
              />
              <div className={classes.successText}>
                {!errorMessage ? (
                  <Typography
                    style={{ textAlign: "center" }}
                    color="error"
                    variant="subtitle2"
                    gutterBottom
                  >
                    Please correct all validation errors before proceeding. Note
                    the highlighted fields.
                  </Typography>
                ) : (
                  ""
                )}
              </div>
              {errorMessage && (
                <div
                  style={{ marginTop: "2%" }}
                  className={classes.successText}
                >
                  {errorMessage &&
                    errorMessage.map((key) => (
                      <div className={classes.successText}>
                        -{` ${key.msg}`}{" "}
                      </div>
                    ))}
                </div>
              )}
              <br />
            </div>

            <CardActions style={{ margin: "5%" }}>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => handleErrorMessage()}
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </div>
      </Modal>
      <Box
        style={{
          alignItems: "center",
          marginTop: "30px",
        }}
        className={classes.tittleColor}
        height={40}
      >
        <Typography className={classes.tittleTextColor}>
          Business Details
        </Typography>
      </Box>
      <Box className={classes.marginBox}>
        <Grid container className={classes.margin}>
          <Grid item lg={4} sm={6} xs={12}>
            <BusinessCenterIcon className={classes.inputBox} fontSize="small" />
            <TextField
              required
              error={validationError.businessName}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title="As it appear on your income tax return"
                      placement="top"
                    >
                      <HelpOutlineOutlinedIcon
                        classes={{
                          root: "HelpIcon",
                          icon: "withHelpOutline",
                        }}
                        color="action"
                        fontSize="small"
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
                required: true,
              }}
              label="Business Name"
              value={values.businessName}
              helperText={
                validationError.businessName && "Business Name is required."
              }
              onChange={(e) => {
                (/^[A-Za-z0-9\d.\-/&,\s]*$/g.test(e.target.value) ||
                  e.target.value === "") &&
                  onChange(e.target.value, "businessName");
              }}
              name="businessName"
              id="businessName"
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <PersonIcon className={classes.inputBox} fontSize="small" />
            <TextField
              error={validationError.dba}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  focused: "focused",
                  input: classes.resize,
                },
              }}
              label="DBA Name"
              value={values.dba}
              onChange={(e) =>
                (/^[A-Za-z0-9\d.\-/&,\s]*$/g.test(e.target.value) ||
                  e.target.value === "") &&
                onChange(e.target.value, "dba")
              }
              name="dba"
              id="dba"
              helperText={validationError.dba && "Provide Legal DBA Name"}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <WebIcon className={classes.inputBox} fontSize="small" />
            <TextField
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              label="Website Url"
              error={validationError.businessWebsite}
              value={values.businessWebsite}
              onChange={(e) => onChange(e.target.value, "businessWebsite")}
              name="businessWebsite"
              id="businessWebsite"
              helperText={
                validationError.businessWebsite &&
                "please enter a valid web url"
              }
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
            <TextField
              className={classes.root}
              inputProps={{ maxLength: 50 }}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              label="Business Description"
              error={validationError.bsinessDescription}
              value={values.bsinessDescription}
              onChange={(e) => onChange(e.target.value, "bsinessDescription")}
              name="bsinessDescription"
              id="bsinessDescription"
              helperText={
                validationError.bsinessDescription &&
                "please enter a valid description"
              }
            />
          </Grid>

          {values.uid && (
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#696969",
              }}
              className={classes.activeInputLabelColor}
            >
              <BusinessCenterIcon
                className={classes.inputBox}
                fontSize="small"
              />
              <Typography>Business UID : {values.uid} </Typography>
            </Grid>
          )}
        </Grid>
        <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
      </Box>
      <Box
        style={{
          alignItems: "center",
          marginTop: "30px",
        }}
        className={classes.tittleColor}
        height={40}
      >
        <Typography className={classes.tittleTextColor}>
          Address Details
        </Typography>
      </Box>
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <div>
          <Typography className={classes.ownerTittle}>
            <b>Business Address</b>
          </Typography>
        </div>
      </div>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={3}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            className={classes.root}
            inputProps={{ maxLength: 50 }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Room"
            error={validationError.businessRoom}
            value={values.businessRoom}
            onChange={(e) => onChange(e.target.value, "businessRoom")}
            helperText={
              validationError.businessRoom && "Please enter a valid value."
            }
            name="businessRoom"
            id="businessRoom"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            className={classes.root}
            inputProps={{ maxLength: 50 }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Floor"
            error={validationError.businessFloor}
            value={values.businessFloor}
            onChange={(e) => onChange(e.target.value, "businessFloor")}
            helperText={
              validationError.businessFloor && "Please enter a valid value."
            }
            name="businessFloor"
            id="businessFloor"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            className={classes.root}
            inputProps={{ maxLength: 50 }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Street Name"
            error={validationError.businessStreet}
            value={values.businessStreet}
            onChange={(e) => onChange(e.target.value, "businessStreet")}
            helperText={
              validationError.businessStreet && "Business City is required."
            }
            name="businessStreet"
            id="businessStreet"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <PublicIcon className={classes.inputBox} fontSize="small" />
          <TextField
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Country"
            value={values.businessCountry}
            error={validationError.businessCountry}
            onChange={(e) => onChange(e.target.value, "businessCountry")}
            name="businessCountry"
            id="businessCountry"
            select
          >
            {props.formData.countryTypeList &&
              props.formData.countryTypeList.Country_Data.map((key) => [
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={key.Country_Code}
                >
                  {key.Country_Name}
                </MenuItem>,
              ])}
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <NearMeRoundedIcon className={classes.inputBox} fontSize="small" />
          {values.businessCountry === "US" ? (
            <TextField
              required={actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.merchant,
                permissionCheck.option.asterisk
              )}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              label="State"
              value={values.businessState}
              error={validationError.businessState}
              onChange={(e) => onChange(e.target.value, "businessState")}
              helperText={
                validationError.businessState && "Business State is required."
              }
              name="businessState"
              id="businessState"
              select
            >
              {locationCity
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((key) => [
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value={key.value}
                  >
                    {key.name}
                  </MenuItem>,
                ])}
            </TextField>
          ) : (
            <TextField
              required={actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.merchant,
                permissionCheck.option.asterisk
              )}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              label="State"
              error={validationError.businessState}
              value={values.businessState}
              onChange={(e) => onChange(e.target.value, "businessState")}
              helperText={
                validationError.businessState && "Business State is required."
              }
              name="businessState"
              id="businessState"
            />
          )}
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="City"
            error={validationError.businessCity}
            value={values.businessCity}
            onChange={(e) => onChange(e.target.value, "businessCity")}
            helperText={
              validationError.businessCity && "Business City is required."
            }
            name="businessCity"
            id="businessCity"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            style={{
              width: windowWidthLG ? "74%" : "80%",
              "&.focused": {
                color: theme.palette.primary.main,
                fontSize: "14px",
                fontStyle: "Helvetica, Arial,sans-serif",
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              inputProps: { min: 0 },
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Postal Code"
            error={validationError.businessPostalCode}
            value={values.businessPostalCode}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value === "") &&
              onChange(e.target.value, "businessPostalCode")
            }
            helperText={
              validationError.businessPostalCode &&
              "5 Digit Business Postal Code is required."
            }
            name="businessPostalCode"
            id="businessPostalCode"
            type="text"
          />
        </Grid>
        <Grid item lg={12} sm={6} xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sameAsMailing}
                onChange={handleSameAsMailingChange}
                color="primary"
              />
            }
            label="Mailing address is same as business address"
          />
        </Grid>
        {!sameAsMailing && (
          <>
            <div
              style={{
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <div>
                <Typography className={classes.ownerTittle}>
                  <b>Mailing Address</b>
                </Typography>
              </div>
            </div>
            <Grid container className={classes.margin}>
              <Grid item lg={4} sm={6} xs={3}>
                <LocationCityRoundedIcon
                  className={classes.inputBox}
                  fontSize="small"
                />
                <TextField
                  className={classes.root}
                  inputProps={{ maxLength: 50 }}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Room"
                  error={validationError.mailingRoom}
                  value={values.mailingRoom}
                  onChange={(e) => onChange(e.target.value, "mailingRoom")}
                  helperText={
                    validationError.mailingRoom && "Please enter a valid value."
                  }
                  name="mailingRoom"
                  id="mailingRoom"
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={3}>
                <LocationCityRoundedIcon
                  className={classes.inputBox}
                  fontSize="small"
                />
                <TextField
                  className={classes.root}
                  inputProps={{ maxLength: 50 }}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Floor"
                  error={validationError.mailingFloor}
                  value={values.mailingFloor}
                  onChange={(e) => onChange(e.target.value, "mailingFloor")}
                  helperText={
                    validationError.mailingFloor &&
                    "Please enter a valid value."
                  }
                  name="mailingFloor"
                  id="mailingFloor"
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={3}>
                <LocationCityRoundedIcon
                  className={classes.inputBox}
                  fontSize="small"
                />
                <TextField
                  required={actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.merchant,
                    permissionCheck.option.asterisk
                  )}
                  className={classes.root}
                  inputProps={{ maxLength: 50 }}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Street Name"
                  error={validationError.mailingStreet}
                  value={values.mailingStreet}
                  onChange={(e) => onChange(e.target.value, "mailingStreet")}
                  helperText={
                    validationError.mailingStreet &&
                    "Mailing Street is required."
                  }
                  name="mailingStreet"
                  id="mailingStreet"
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={3}>
                <PublicIcon className={classes.inputBox} fontSize="small" />
                <TextField
                  required={actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.merchant,
                    permissionCheck.option.asterisk
                  )}
                  className={classes.root}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Country"
                  value={values.mailingCountry}
                  error={validationError.mailingCountry}
                  onChange={(e) => onChange(e.target.value, "mailingCountry")}
                  name="mailingCountry"
                  id="mailingCountry"
                  select
                >
                  {props.formData.countryTypeList &&
                    props.formData.countryTypeList.Country_Data.map((key) => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={key.Country_Code}
                      >
                        {key.Country_Name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item lg={4} sm={6} xs={3}>
                <NearMeRoundedIcon
                  className={classes.inputBox}
                  fontSize="small"
                />
                {values.mailingCountry === "US" ? (
                  <TextField
                    required={actionPermission(
                      permissionCheck.Category.setup,
                      permissionCheck.Page.merchant,
                      permissionCheck.option.asterisk
                    )}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="State"
                    value={values.mailingState}
                    error={validationError.mailingState}
                    onChange={(e) => onChange(e.target.value, "mailingState")}
                    helperText={
                      validationError.mailingState &&
                      "Mailing State is required."
                    }
                    name="mailingState"
                    id="mailingState"
                    select
                  >
                    {locationCity
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key.value}
                        >
                          {key.name}
                        </MenuItem>
                      ))}
                  </TextField>
                ) : (
                  <TextField
                    required={actionPermission(
                      permissionCheck.Category.setup,
                      permissionCheck.Page.merchant,
                      permissionCheck.option.asterisk
                    )}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="State"
                    error={validationError.mailingState}
                    value={values.mailingState}
                    onChange={(e) => onChange(e.target.value, "mailingState")}
                    helperText={
                      validationError.mailingState &&
                      "Mailing State is required."
                    }
                    name="mailingState"
                    id="mailingState"
                  />
                )}
              </Grid>
              <Grid item lg={4} sm={6} xs={3}>
                <LocationCityRoundedIcon
                  className={classes.inputBox}
                  fontSize="small"
                />
                <TextField
                  required={actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.merchant,
                    permissionCheck.option.asterisk
                  )}
                  className={classes.root}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="City"
                  error={validationError.mailingCity}
                  value={values.mailingCity}
                  onChange={(e) => onChange(e.target.value, "mailingCity")}
                  helperText={
                    validationError.mailingCity && "Mailing City is required."
                  }
                  name="mailingCity"
                  id="mailingCity"
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={3}>
                <RoomIcon className={classes.inputBox} fontSize="small" />
                <TextField
                  required={actionPermission(
                    permissionCheck.Category.setup,
                    permissionCheck.Page.merchant,
                    permissionCheck.option.asterisk
                  )}
                  style={{
                    width: windowWidthLG ? "74%" : "80%",
                    "&.focused": {
                      color: theme.palette.primary.main,
                      fontSize: "14px",
                      fontStyle: "Helvetica, Arial,sans-serif",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  label="Postal Code"
                  error={validationError.mailingPostalCode}
                  value={values.mailingPostalCode}
                  onChange={(e) =>
                    (/^[0-9]\d*$/g.test(e.target.value) ||
                      e.target.value === "") &&
                    onChange(e.target.value, "mailingPostalCode")
                  }
                  helperText={
                    validationError.mailingPostalCode &&
                    "5 Digit Mailing Postal Code is required."
                  }
                  name="mailingPostalCode"
                  id="mailingPostalCode"
                  type="text"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
      <Box
        style={{
          alignItems: "center",
          marginTop: "30px",
        }}
        className={classes.tittleColor}
        height={40}
      >
        <Typography className={classes.tittleTextColor}>
          Banking Details
        </Typography>
      </Box>
      <Box className={classes.marginBox}>
        <Grid container className={classes.margin}>
          <Grid item lg={6} sm={3} xs={12}>
            <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
            <TextField
              disabled={
                profileCode === profileCodePermissionCheck.profileCode.SA &&
                props?.merchantListData?.merchantListData &&
                props?.merchantListData?.merchantListData?.data[0]
                  ?.Banking_Details?.bankDetailsCannotBeEdited
              }
              required={actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.merchant,
                permissionCheck.option.asterisk
              )}
              className={classes.quaterInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                inputProps: { min: 0 },
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              label="Routing Number"
              error={validationError.routingNumber}
              value={values.routingNumber}
              onChange={(e) => {
                const routingNumber = e.target.value;
                const confirmRoutingNumber = values.confirmRoutingNumber;
                if (e.target.value.length === 9) {
                  const data = {
                    routingNumber: e.target.value,
                    Module_Name:
                      profileCode === profileCodePermissionCheck.profileCode.VCS
                        ? "Invite Page"
                        : "Merchant Submit Page",
                    Module_Code:
                      profileCode === profileCodePermissionCheck.profileCode.VCS
                        ? 1
                        : 2,
                    Business_uid: values.uid,
                    Business_Name: values.businessName,
                    Requested_for_id: values?.Requested_for_id,
                    Requested_by_id: values?.Requested_by_id,
                    Manager_id: values?.Manager_id,
                    Manager_Name: `${values.firstName}${values.lastName}`,
                  };
                  // const encryptedPayload = CryptoJS.AES.encrypt(
                  //   JSON.stringify(data),
                  //   secretKey
                  // ).toString();
                  // let payloadNew = {};
                  // if (encryptionKey == "NO") {
                  //   payloadNew = { payload: data };
                  // } else {
                  //   payloadNew = { payload: encryptedPayload };
                  // }
                  props.getRoutingNumberData(data);
                }
                if (e.target.value.length !== 9) {
                  clearRoutingNumberData();
                }
                if (
                  /^[0-9]\d*$/g.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  onChange(e.target.value, "routingNumber");
                  if (props.accountNumberData?.status === false) {
                    clearAccountNumberData();
                  }
                }
                if (
                  values.accountNumber !== "" &&
                  values.confirmAccountNumber !== "" &&
                  values.accountNumber !== null &&
                  values.confirmAccountNumber !== null &&
                  routingNumber === confirmRoutingNumber &&
                  values.accountNumber === values.confirmAccountNumber &&
                  routingNumber.length === 9
                ) {
                  const data = {
                    routingNumber: routingNumber,
                    accountNumber: values.accountNumber,
                  };
                  props.getAccountNumberData(data);
                } else if (
                  values.Status_Code === 1 ||
                  values.Status_Code === 4
                ) {
                  if (
                    values.accountNumber !== "" &&
                    values.accountNumber !== null &&
                    routingNumber === confirmRoutingNumber &&
                    routingNumber.length === 9
                  ) {
                    const data = {
                      routingNumber: routingNumber,
                      accountNumber: values.accountNumber,
                    };
                    props.getAccountNumberData(data);
                  }
                }
              }}
              helperText={
                validationError.routingNumber &&
                values?.routingNumber?.length !== 9
                  ? "9 Digit Routing Number is Required."
                  : null
              }
              name="routingNumber"
              id="routingNumber"
              type="text"
            />
            <Typography style={{ paddingLeft: "38px", color: "#e67452" }}>
              {props.routingNumberData && props?.routingNumberData?.network}
              &nbsp;&nbsp;
              {props.routingNumberData && props?.routingNumberData?.rdfi_name}
              {props.routingNumberData &&
                !props?.routingNumberData?.available &&
                "This routing number is not supported"}
            </Typography>
          </Grid>
          {confirmRoutingNumberVisible && (
            <Grid item lg={6} sm={3} xs={12}>
              <AccountBalanceIcon
                className={classes.inputBox}
                fontSize="small"
              />
              <TextField
                disabled={
                  profileCode === profileCodePermissionCheck.profileCode.SA &&
                  props?.merchantListData?.merchantListData &&
                  props?.merchantListData?.merchantListData?.data[0]
                    ?.Banking_Details?.bankDetailsCannotBeEdited
                }
                required={values.routingNumber !== ""}
                className={classes.quaterInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                label="Confirm Routing Number"
                error={
                  validationError.confirmRoutingNumber &&
                  (values.confirmRoutingNumber === "" ||
                    values.confirmRoutingNumber !== values.routingNumber)
                }
                onChange={(e) => {
                  const confirmRoutingNumber = e.target.value;
                  const routingNumber = values.routingNumber;
                  if (
                    /^[0-9]\d*$/g.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    onChange(e.target.value, "confirmRoutingNumber");
                    if (props.accountNumberData?.status === false) {
                      clearAccountNumberData();
                    }
                  }
                  if (
                    values.accountNumber !== "" &&
                    values.confirmAccountNumber !== "" &&
                    values.accountNumber !== null &&
                    values.confirmAccountNumber !== null &&
                    routingNumber === confirmRoutingNumber &&
                    values.accountNumber === values.confirmAccountNumber &&
                    confirmRoutingNumber.length === 9
                  ) {
                    const data = {
                      routingNumber: confirmRoutingNumber,
                      accountNumber: values.accountNumber,
                    };
                    props.getAccountNumberData(data);
                  } else if (
                    values.Status_Code === 1 ||
                    values.Status_Code === 4
                  ) {
                    if (
                      values.accountNumber !== "" &&
                      values.accountNumber !== null &&
                      routingNumber === confirmRoutingNumber &&
                      confirmRoutingNumber.length === 9
                    ) {
                      const data = {
                        routingNumber: confirmRoutingNumber,
                        accountNumber: values.accountNumber,
                      };
                      props.getAccountNumberData(data);
                    }
                  }
                }}
                helperText={
                  validationError.confirmRoutingNumber &&
                  values.confirmRoutingNumber !== "" &&
                  values.routingNumber !== values.confirmRoutingNumber &&
                  "Routing number does not match"
                }
                name="confirmRoutingNumber"
                id="confirmRoutingNumber"
                type="text"
              />
            </Grid>
          )}
        </Grid>

        <Grid container className={classes.margin}>
          <Grid item lg={6} sm={3} xs={12}>
            <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
            <TextField
              disabled={
                profileCode === profileCodePermissionCheck.profileCode.SA &&
                props?.merchantListData?.merchantListData &&
                props?.merchantListData?.merchantListData?.data[0]
                  ?.Banking_Details?.bankDetailsCannotBeEdited
              }
              required={actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.merchant,
                permissionCheck.option.asterisk
              )}
              className={classes.quaterInput}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                inputProps: { min: 0 },
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              onFocus={() => {
                setIsRevealAcn(true);
              }}
              onBlur={() => {
                setIsRevealAcn(false);
              }}
              onClick={() => {
                setIsRevealAcn(true);
              }}
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              label="Account Number"
              error={
                validationError.accountNumber &&
                values.accountNumber !== values.confirmAccountNumber
              }
              helperText={
                validationError.accountNumber &&
                (values?.accountNumber?.length < 8 ||
                  values?.accountNumber?.length > 17)
                  ? "Min 8 to Max 17 Digit Account Number is required."
                  : null
              }
              value={displayAccountNumber()}
              onChange={(e) => {
                const accountNumber = e.target.value;
                const confirmAccountNumber = values.confirmAccountNumber;
                if (
                  values.routingNumber !== "" &&
                  values.confirmRoutingNumber !== "" &&
                  confirmAccountNumber !== "" &&
                  values.routingNumber !== null &&
                  values.confirmRoutingNumber !== null &&
                  confirmAccountNumber === accountNumber &&
                  values.routingNumber === values.confirmRoutingNumber &&
                  accountNumber.length >= 8 &&
                  accountNumber.length <= 17
                ) {
                  const data = {
                    routingNumber: values.routingNumber,
                    accountNumber: accountNumber,
                  };
                  props.getAccountNumberData(data);
                } else if (
                  values.Status_Code === 1 ||
                  values.Status_Code === 4
                ) {
                  if (
                    values.routingNumber !== "" &&
                    values.routingNumber !== null &&
                    confirmAccountNumber === accountNumber &&
                    accountNumber.length >= 8 &&
                    accountNumber.length <= 17
                  ) {
                    const data = {
                      routingNumber: values.routingNumber,
                      accountNumber: accountNumber,
                    };
                    props.getAccountNumberData(data);
                  }
                }
                if (/^[0-9]*$/g.test(e.target.value) || e.target.value === "") {
                  onChange(e.target.value, "accountNumber");
                  if (props.accountNumberData?.status === false) {
                    clearAccountNumberData();
                  }
                }
              }}
              name="accountNumber"
              id="accountNumber"
              type="text"
            />
            {!confirmAccountNumberVisible &&
              values.Status_Code === 1 &&
              ((values.routingNumber === values.confirmRoutingNumber ||
                values.accountNumber === values.confirmAccountNumber) &&
              props?.accountNumberData?.status ? (
                <CheckIcon style={{ color: "green" }} />
              ) : props?.accountNumberData?.status ? (
                clearAccountNumberData()
              ) : (
                ""
              ))}
          </Grid>
          {confirmAccountNumberVisible && (
            <Grid item lg={6} sm={3} xs={12}>
              <AccountBalanceIcon
                className={classes.inputBox}
                fontSize="small"
              />
              <TextField
                disabled={
                  profileCode === profileCodePermissionCheck.profileCode.SA &&
                  props?.merchantListData?.merchantListData &&
                  props?.merchantListData?.merchantListData?.data[0]
                    ?.Banking_Details?.bankDetailsCannotBeEdited
                }
                required={values.accountNumber !== ""}
                className={classes.quaterInput}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  inputProps: { min: 0 },
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                label="Confirm Account Number"
                error={
                  validationError.confirmAccountNumber &&
                  (values.confirmAccountNumber === "" ||
                    values.confirmAccountNumber !== values.accountNumber)
                }
                onFocus={() => {
                  setIsRevealAcnConfirm(true);
                }}
                onBlur={() => {
                  setIsRevealAcnConfirm(false);
                }}
                onClick={() => {
                  setIsRevealAcnConfirm(true);
                }}
                helperText={
                  validationError.confirmAccountNumber &&
                  values.confirmAccountNumber !== "" &&
                  values.accountNumber !== values.confirmAccountNumber &&
                  "Account number does not match"
                }
                onChange={(e) => {
                  const accountNumber = values.accountNumber;
                  const confirmAccountNumber = e.target.value;

                  if (
                    (/^[0-9]*$/g.test(confirmAccountNumber) ||
                      confirmAccountNumber === "") &&
                    confirmAccountNumber.length <= 17
                  ) {
                    onChange(confirmAccountNumber, "confirmAccountNumber");
                    if (props.accountNumberData?.status === false) {
                      clearAccountNumberData();
                    }
                  }

                  if (
                    values.routingNumber !== "" &&
                    values.confirmRoutingNumber !== "" &&
                    values.routingNumber !== null &&
                    values.confirmRoutingNumber !== null &&
                    accountNumber === confirmAccountNumber &&
                    values.routingNumber === values.confirmRoutingNumber &&
                    confirmAccountNumber.length >= 8 &&
                    confirmAccountNumber.length <= 17
                  ) {
                    const data = {
                      routingNumber: values.routingNumber,
                      accountNumber: confirmAccountNumber,
                    };
                    props.getAccountNumberData(data);
                  } else if (
                    values.Status_Code === 1 ||
                    values.Status_Code === 4
                  ) {
                    if (
                      values.routingNumber !== "" &&
                      values.routingNumber !== null &&
                      accountNumber === confirmAccountNumber &&
                      confirmAccountNumber.length >= 8 &&
                      confirmAccountNumber.length <= 17
                    ) {
                      const data = {
                        routingNumber: values.routingNumber,
                        accountNumber: confirmAccountNumber,
                      };
                      props.getAccountNumberData(data);
                    }
                  }
                }}
                value={displayConfirmAccountNumber()}
                name="confirmAccountNumber"
                id="confirmAccountNumber"
                type="text"
              />
              {values.Status_Code === 1 || values.Status_Code === 4 ? (
                (values.routingNumber === values.confirmRoutingNumber ||
                  values.accountNumber === values.confirmAccountNumber) &&
                props?.accountNumberData?.status ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : props?.accountNumberData?.status ? (
                  clearAccountNumberData()
                ) : (
                  ""
                )
              ) : values.accountNumber === values.confirmAccountNumber &&
                values.routingNumber === values.confirmRoutingNumber &&
                props?.accountNumberData?.status ? (
                <CheckIcon style={{ color: "green" }} />
              ) : props?.accountNumberData?.status ? (
                clearAccountNumberData()
              ) : (
                ""
              )}
            </Grid>
          )}
          {props.accountNumberData?.status === false &&
          props?.accountNumberData.Business_Name == null ? (
            <Typography style={{ paddingLeft: "38px", color: "red" }}>
              {`"${props?.accountNumberData?.message}"`}
            </Typography>
          ) : (
            ""
          )}
          {props.accountNumberData?.status === false &&
          props?.accountNumberData?.Business_Name != null ? (
            <Typography style={{ paddingLeft: "38px", color: "red" }}>
              {`This Account and Routing Number combination is already linked
              with business "${props?.accountNumberData?.Business_Name}",
              managed by "${props?.accountNumberData?.Manager_Name}"`}
            </Typography>
          ) : (
            ""
          )}
          {profileCode !== profileCodePermissionCheck.profileCode.SA && (
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "center",
                  lg: "center",
                  md: "flex-start",
                },
                alignItems: "center",
                alignContent: "center",
              }}
              lg={12}
              sm={6}
              xs={3}
            >
              <FormControl
                className={classes.root}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused",
                  },
                }}
                component="fieldset"
              >
                <FormGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                  row
                >
                  <FormLabel
                    style={{ marginTop: "10px", fontSize: "14px" }}
                    component="span"
                  >
                    Bank details cannot be edited by business
                  </FormLabel>
                  <FormControlLabel
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.bankDetailsCannotBeEdited && !disabled1}
                        disabled={
                          values.accountNumber === "" ||
                          values.routingNumber === "" ||
                          disabled1
                        }
                        onChange={handleBankDetailsCheckboxChange}
                        name="bankDetailsCannotBeEdited"
                      />
                    }
                  />
                </FormGroup>
                <FormHelperText>
                  {validationError.bankDetailsCannotBeEdited}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
        {values.Account_id && (
          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
            style={{ display: "flex", alignItems: "center", color: "#696969" }}
            className={classes.margin}
          >
            <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
            <Typography>Account ID : {values.Account_id} </Typography>
          </Grid>
        )}

        <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
      </Box>
      <Box
        style={{
          alignItems: "center",
          marginTop: "30px",
        }}
        className={classes.tittleColor}
        height={40}
      >
        <Typography className={classes.tittleTextColor}>Fee Details</Typography>
      </Box>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
          <TextField
            select
            disabled={
              profileCode === profileCodePermissionCheck.profileCode.SA &&
              props?.merchantListData?.merchantListData &&
              props?.merchantListData?.merchantListData?.data[0]?.FeeType
                ?.feeCanNotBeEdited
            }
            required
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLabelColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
              required: true,
            }}
            label="Fee Rate"
            error={validationError.feeRate}
            value={values.feeRate}
            onChange={(e) => onChange(e.target.value, "feeRate")}
            helperText={validationError.feeRate && "Fee Rate is required."}
            name="feeRate"
            id="feeRate"
          >
            <MenuItem
              className={classes.dialogBoxDropDown}
              value="Per Transaction"
            >
              Per Transaction
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Percentage">
              Percentage
            </MenuItem>
            <MenuItem
              className={classes.dialogBoxDropDown}
              value="Per transaction + Percentage"
            >
              Per transaction + Percentage
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="None">
              None
            </MenuItem>
          </TextField>
        </Grid>
        {values.feeRate === "Per Transaction" && (
          <Grid item lg={4} sm={6} xs={12}>
            <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
            <TextField
              disabled={
                profileCode === profileCodePermissionCheck.profileCode.SA &&
                props?.merchantListData?.merchantListData &&
                props?.merchantListData?.merchantListData?.data[0]?.FeeType
                  ?.feeCanNotBeEdited
              }
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLabelColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              label="Per Transaction Fee"
              value={values.perTransactionFee}
              name="perTransactionFee"
              id="perTransactionFee"
              type="number"
              onChange={(e) => onChange(e.target.value, "perTransactionFee")}
              error={validationError.perTransactionFee}
              helperText={
                validationError.perTransactionFee && "Fee Rate is required."
              }
            />
          </Grid>
        )}
        {values.feeRate === "Percentage" && (
          <Grid item lg={4} sm={6} xs={12}>
            <AccountBalanceIcon className={classes.inputBox} fontSize="small" />
            <TextField
              disabled={
                profileCode === profileCodePermissionCheck.profileCode.SA &&
                props?.merchantListData?.merchantListData &&
                props?.merchantListData?.merchantListData?.data[0]?.FeeType
                  ?.feeCanNotBeEdited
              }
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLabelColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              label="Percentage Fee"
              value={values.percentageFee}
              name="percentageFee"
              id="percentageFee"
              type="number"
              onChange={(e) => onChange(e.target.value, "percentageFee")}
              error={validationError.percentageFee}
              helperText={
                validationError.percentageFee && "Fee Rate is required."
              }
            />
          </Grid>
        )}
        {values.feeRate === "Per transaction + Percentage" && (
          <>
            <Grid item lg={4} sm={6} xs={12} style={{ display: "flex" }}>
              <div>
                <Typography className={classes.feetype}>
                  <b>Per tran fee</b>
                </Typography>
              </div>
              <AccountBalanceIcon
                className={classes.inputBox}
                fontSize="small"
              />
              <TextField
                disabled={
                  profileCode === profileCodePermissionCheck.profileCode.SA &&
                  props?.merchantListData?.merchantListData &&
                  props?.merchantListData?.merchantListData?.data[0]?.FeeType
                    ?.feeCanNotBeEdited
                }
                className={classes.root}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLabelColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                label="Per Transaction Fee"
                value={values.perTransactionFee}
                name="perTransactionFee"
                id="perTransactionFee"
                type="number"
                onChange={(e) => onChange(e.target.value, "perTransactionFee")}
                error={validationError.perTransactionFee}
                helperText={
                  validationError.perTransactionFee && "Fee Rate is required."
                }
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12} style={{ display: "flex" }}>
              <div>
                <Typography className={classes.feetype}>
                  <b>Plus</b>
                </Typography>
              </div>
              <AccountBalanceIcon
                className={classes.inputBox}
                fontSize="small"
              />
              <TextField
                disabled={
                  profileCode === profileCodePermissionCheck.profileCode.SA &&
                  props?.merchantListData?.merchantListData &&
                  props?.merchantListData?.merchantListData?.data[0]?.FeeType
                    ?.feeCanNotBeEdited
                }
                className={classes.root}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLabelColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                label="Percentage Fee"
                value={values.percentageFee}
                name="percentageFee"
                id="percentageFee"
                type="number"
                onChange={(e) => onChange(e.target.value, "percentageFee")}
                error={validationError.percentageFee}
                helperText={
                  validationError.percentageFee && "Fee Rate is required."
                }
              />
              <div>
                <Typography className={classes.feetype}>
                  <b>%</b>
                </Typography>
              </div>
            </Grid>
            <Grid item lg={4} sm={6} xs={12} style={{ display: "flex" }}>
              <div>
                <Typography className={classes.feetype}>
                  <b>Above</b>
                </Typography>
              </div>
              <AccountBalanceIcon
                className={classes.inputBox}
                fontSize="small"
              />
              <TextField
                disabled={
                  profileCode === profileCodePermissionCheck.profileCode.SA &&
                  props?.merchantListData?.merchantListData &&
                  props?.merchantListData?.merchantListData?.data[0]?.FeeType
                    ?.feeCanNotBeEdited
                }
                className={classes.root}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLabelColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                label="Transaction Value"
                value={values.aboveTransactionValue}
                name="aboveTransactionValue"
                id="aboveTransactionValue"
                type="text"
                onChange={(e) =>
                  onChange(e.target.value, "aboveTransactionValue")
                }
                error={validationError.aboveTransactionValue}
                helperText={
                  validationError.aboveTransactionValue &&
                  "Fee Rate is required."
                }
              />
            </Grid>
          </>
        )}
        {profileCode !== profileCodePermissionCheck.profileCode.SA && (
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: {
                xl: "center",
                lg: "center",
                md: "flex-start",
              },
              alignItems: "center",
              alignContent: "center",
            }}
            lg={12}
            sm={6}
            xs={3}
          >
            <FormControl
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              component="fieldset"
            >
              <FormGroup
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
                row
              >
                <FormLabel
                  style={{ marginTop: "10px", fontSize: "14px" }}
                  component="span"
                >
                  Fee cannot be edited by business
                </FormLabel>
                <FormControlLabel
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.feeCanNotBeEdited}
                      disabled={
                        values.feeRate !== "Per Transaction" &&
                        values.feeRate !== "Percentage" &&
                        values.feeRate !== "Per transaction + Percentage" &&
                        values.feeRate !== "None"
                      }
                      onChange={handleEditFeeCheckboxChange}
                      name="feeCanNotBeEdited"
                    />
                  }
                />
              </FormGroup>
              <FormHelperText>{validationError.readOnly}</FormHelperText>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
      <Box
        style={{
          alignItems: "center",
          marginTop: "30px",
        }}
        className={classes.tittleColor}
        height={40}
        marginBottom={"10px"}
      >
        <Typography className={classes.tittleTextColor}>
          Primary Manager Details
        </Typography>
      </Box>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <Box mb={2} style={{ display: "flex" }}>
            <StayCurrentPortraitIcon
              className={classes.inputBox}
              fontSize="small"
            />
            <EmailAutofill
              email={values.email}
              onEmailChange={handleEmailChange}
              agentsList={props?.merchantListData?.emailListData?.data || []}
              selectedUser={selectedUser}
              setSelectedUser={handleUserSelect}
              allowManualInput={!props?.merchantListData?.emailData}
              emailError={validationError.email}
              setEmailError={(error) =>
                setVlidationError((prevError) => ({
                  ...prevError,
                  email: error,
                }))
              }
              values={values}
            />
          </Box>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            required
            disabled={props.disabled}
            error={validationError.firstName}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
              required: true,
            }}
            label="First Name"
            value={values.firstName}
            onChange={(e) => onChange(e.target.value, "firstName")}
            helperText={
              validationError.firstName &&
              "First Name is required and can not start with a number."
            }
            name="firstName"
            id="firstName"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            error={validationError.lastName}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            required
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
              required: true,
            }}
            label="Last Name"
            value={values.lastName}
            onChange={(e) => onChange(e.target.value, "lastName")}
            helperText={
              validationError.lastName &&
              "Last Name is required and can not start with a number."
            }
            name="lastName"
            id="lastName"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={profileCode === profileCodePermissionCheck.profileCode.SA}
            className={classes.root}
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Phone"
            helperText={
              validationError.phoneNumber &&
              "Enter a Valid Phone Number with 10 Digits Only"
            }
            error={validationError.phoneNumber}
            value={formatPhoneNumber(values.phoneNumber)}
            onChange={(e) => {
              const formattedNumber = e.target.value
                .replace(/\D/g, "")
                .slice(0, 10);
              onChange(formattedNumber, "phoneNumber");
            }}
            name="phoneNumber"
            id="phoneNumber"
            type="text"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={3}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            className={classes.root}
            inputProps={{ maxLength: 50 }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Room"
            error={validationError.managerRoom}
            value={values.managerRoom}
            onChange={(e) => onChange(e.target.value, "managerRoom")}
            helperText={
              validationError.managerRoom && "Please enter a valid value."
            }
            name="managerRoom"
            id="managerRoom"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            className={classes.root}
            inputProps={{ maxLength: 50 }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Floor"
            error={validationError.managerFloor}
            value={values.managerFloor}
            onChange={(e) => onChange(e.target.value, "managerFloor")}
            helperText={
              validationError.managerFloor && "Please enter a valid value."
            }
            name="managerFloor"
            id="managerFloor"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            className={classes.root}
            inputProps={{ maxLength: 50 }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Street Name"
            error={validationError.managerStreet}
            value={values.managerStreet}
            onChange={(e) => onChange(e.target.value, "managerStreet")}
            helperText={validationError.managerStreet && "Street is required."}
            name="managerStreet"
            id="managerStreet"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <PublicIcon className={classes.inputBox} fontSize="small" />
          <TextField
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Country"
            value={values.managerCountry}
            error={validationError.managerCountry}
            onChange={(e) => onChange(e.target.value, "managerCountry")}
            name="managerCountry"
            id="managerCountry"
            select
          >
            {props.formData.countryTypeList &&
              props.formData.countryTypeList.Country_Data.map((key) => (
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={key.Country_Code}
                >
                  {key.Country_Name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <NearMeRoundedIcon className={classes.inputBox} fontSize="small" />
          {values.managerCountry === "US" ? (
            <TextField
              required={actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.merchant,
                permissionCheck.option.asterisk
              )}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              label="State"
              value={values.managerState}
              error={validationError.managerState}
              onChange={(e) => onChange(e.target.value, "managerState")}
              helperText={
                validationError.managerState && "Please enter correct  state."
              }
              name="managerState"
              id="managerState"
              select
            >
              {locationCity
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((key) => (
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value={key.value}
                  >
                    {key.name}
                  </MenuItem>
                ))}
            </TextField>
          ) : (
            <TextField
              required={actionPermission(
                permissionCheck.Category.setup,
                permissionCheck.Page.merchant,
                permissionCheck.option.asterisk
              )}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused",
                },
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused",
                },
              }}
              label="State"
              error={validationError.managerState}
              value={values.managerState}
              onChange={(e) => onChange(e.target.value, "managerState")}
              helperText={validationError.managerState && "State is required."}
              name="managerState"
              id="managerState"
            />
          )}
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="City"
            error={validationError.managerCity}
            value={values.managerCity}
            onChange={(e) => onChange(e.target.value, "managerCity")}
            helperText={validationError.managerCity && "City is required."}
            name="managerCity"
            id="managerCity"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={3}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            required={actionPermission(
              permissionCheck.Category.setup,
              permissionCheck.Page.merchant,
              permissionCheck.option.asterisk
            )}
            style={{
              width: windowWidthLG ? "74%" : "80%",
              "&.focused": {
                color: theme.palette.primary.main,
                fontSize: "14px",
                fontStyle: "Helvetica, Arial,sans-serif",
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              inputProps: { min: 0 },
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
              required: true,
            }}
            label="Postal Code"
            error={validationError.managerPostalCode}
            value={values.managerPostalCode}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value === "") &&
              onChange(e.target.value, "managerPostalCode")
            }
            helperText={
              validationError.managerPostalCode &&
              "5 Digit Postal Code is required."
            }
            name="managerPostalCode"
            id="managerPostalCode"
            type="text"
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
      <Grid
        container
        style={{ justifyContent: "center", margin: "20px 0" }}
        className={classes.margin}
      >
        <Grid
          container
          spacing={2}
          style={{
            textAlign: "center",
            marginTop: "10px",
            justifyContent: "inherit",
            flexWrap: smallDevice ? "wrap" : "nowrap",
          }}
        >
          <Button
            variant="contained"
            className={classes.buttonStyle}
            onClick={handleBack}
          >
            Back
          </Button>
          {(actionPermission(
            permissionCheck.Category.setup,
            permissionCheck.Page.merchant,
            permissionCheck.option.submit
          ) &&
            values.Status_Code !== 3 &&
            values.Status_Code === 1) ||
            (values.Status_Code === 4 && (
              <Button
                variant="contained"
                className={classes.buttonStyle}
                id="saveDraftBusinessForm"
                onClick={() => handleFormSubmit("submit")}
                disabled={
                  values.Status_Code === 3 ||
                  profileCode !== profileCodePermissionCheck.profileCode.SA
                }
              >
                Submit
              </Button>
            ))}

          {(actionPermission(
            permissionCheck.Category.setup,
            permissionCheck.Page.merchant,
            permissionCheck.option.inviteManager
          ) &&
            values.Status_Code !== 1 &&
            values.Status_Code !== 3 &&
            values.Status_Code === 1) ||
            ((values.Status_Code == null ||
              values.Status_Code === "" ||
              values.Status_Code === 2) && (
              <Button
                variant="contained"
                className={classes.buttonStyle}
                onClick={() => onFormSubbmit("sendInvite")}
              >
                Send Invite
              </Button>
            ))}
          {/* {actionPermission(
            permissionCheck.Category.setup,
            permissionCheck.Page.merchant,
            permissionCheck.option.saveDraft
          ) &&
           ( values.Status_Code == 1 &&
            values.Status_Code !== 3 ) && (
              <Button
                variant="contained"
                className={classes.buttonStyle}
                id="saveDraftBusinessForm"
                onClick={() => onFormSubbmit("saveDraft")}
                disabled={values.Status_Code == 3}
              >
                Save Draft
              </Button>
            )} */}
          {/* {actionPermission(
            permissionCheck.Category.setup,
            permissionCheck.Page.merchant,
            permissionCheck.option.saveDraft
          ) &&
            (values.Status_Code == null ||
              values.Status_Code == "") && (
              <Button
                variant="contained"
                className={classes.buttonStyle}
                id="saveDraftBusinessForm"
                onClick={() => onFormSubbmit("saveDraft")}
                disabled={values.Status_Code == 3}
              >
                Save Draft
              </Button>
            )} */}
          {actionPermission(
            permissionCheck.Category.setup,
            permissionCheck.Page.merchant,
            permissionCheck.option.resendInvite
          ) &&
            values.Status_Code === 1 &&
            values.Status_Code !== 3 && (
              <Button
                variant="contained"
                className={classes.buttonStyle}
                onClick={() => onFormSubbmit("resendInvite")}
              >
                Resend Invite
              </Button>
            )}
        </Grid>
      </Grid>
    </div>
  );
}

Signup.propTypes = {
  disabled: PropTypes.bool,
  validationError: PropTypes.object,
  index: PropTypes.any,
  data: PropTypes.object,
  add: PropTypes.func,
  remove: PropTypes.func,
  kycCheckListFlag: PropTypes.bool,
  createPermissionFlag: PropTypes.bool,
  editPermissionFlag: PropTypes.bool,
  formData: PropTypes.object,
  getCountryTypeList: PropTypes.func,
  mainData: PropTypes.object,
  history: PropTypes.object,
  roles: PropTypes.object,
  app: PropTypes.object,
  sendInviteApiCall: PropTypes.func,
  getMerchantListData: PropTypes.func,
  submitApiCall: PropTypes.func,
  getRoutingNumberData: PropTypes.func,
  emptyMerchantRTNReduxState: PropTypes.func,
  getAccountNumberData: PropTypes.func,
  emptyMerchantAccReduxState: PropTypes.func,
  getEmailList: PropTypes.func,
  getEmailData: PropTypes.func,
  emptyEmailListReduxState: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app,
  roles: app.roles,
  app: app.app,
  signUpData: app,
  merchantListData: app.genrateOfferPatient,
  routingNumberData: app.genrateOfferPatient.routingNumberData,
  accountNumberData: app.genrateOfferPatient.accountNumberData,
});

export default connect(mapStateToProps, {
  getCountryTypeList,
  sendInviteApiCall,
  getMerchantListData,
  submitApiCall,
  getRoutingNumberData,
  emptyMerchantRTNReduxState,
  getAccountNumberData,
  emptyMerchantAccReduxState,
  emptyEmailListReduxState,
  getEmailList,
  getEmailData,
})(Signup);
