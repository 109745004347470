import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { getEmailData } from "Redux/Actions/genrateOfferPatient";
import { useDispatch } from "react-redux";

const EmailAutofillManager = ({
  email,
  onEmailChange,
  agentsList,
  selectedUser,
  setSelectedUser,
  allowManualInput,
  emailError,
  values,
}) => {
    const dispatch = useDispatch();
  
    const handleInputChange = (event) => {
      const { value } = event.target;
      onEmailChange(value.toLowerCase());
      setSelectedUser(null);
    };
  
    return (
      <Box >
        <Grid container>
          <Grid item xs={12}>
            <Autocomplete
              disabled={ !values.businessType }
              name="agentId"
              id="agentId"
              style={{ fontSize: "14px" }}
              value={email}
              autoHighlight
              fullWidth
              onInputChange={(e, newInputValue) => {
                onEmailChange(newInputValue.toLowerCase());
                setSelectedUser(null);
              }}
              onChange={(_, value) => {
                setSelectedUser(value);
                if (value) {
                  dispatch(getEmailData(value));
                } else {
                  onEmailChange("");
                  setSelectedUser(null);
                }
              }}
              options={agentsList}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Email"
                  value={email || ""}
                  onChange={handleInputChange}
                  required
                  error={emailError}
                  helperText={emailError ? "Invalid email" : ""}
                //   disabled={profileCode == profileCodePermissionCheck.profileCode.SA}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      backgroundColor: !values.businessType
                        ? "white" 
                        : "inherit",
                    //   color: !values.businessType ? "#9e9e9e" : "inherit",
                    },
                  }}
                />
              )}
              renderOption={(option, { inputValue }) => {
                const matches = match(option, inputValue);
                const parts = parse(option, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          backgroundColor: part.highlight ? "#f3ff0070" : "",
                          fontWeight: part.highlight ? 400 : "",
                          fontSize: "14px",
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              freeSolo={allowManualInput} // Allow manual input if specified
            />
          </Grid>
        </Grid>
      </Box>
    );
};

EmailAutofillManager.propTypes = {
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  agentsList: PropTypes.array.isRequired,
  selectedUser: PropTypes.any,
  setSelectedUser: PropTypes.func.isRequired,
  allowManualInput: PropTypes.bool,
  getEmailData: PropTypes.func,
  emailError: PropTypes.bool,
//   setEmailError: PropTypes.func.isRequired,
//   values: PropTypes.array.isRequired,
};

export default EmailAutofillManager;
