import "./style.scss";
import "../../assets/css/CountryCode.scss";

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import CryptoJS from "crypto-js";
import Divider from "@material-ui/core/Divider";
import {
  Paper,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { CardActions } from "@material-ui/core";
import closeImage from "assets/img/Close_Image.png";
import Card from "components/Card/Card";
import Modal from "@material-ui/core/Modal";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { getCountryTypeList } from "../../Redux/Actions/ProvideRegistration";
import {
  managerUpdateCall,
  addIndividualApiCall,
  sendOTP,
} from "../../actions/Operations";
import AppLoader from "../../components/AppLoader/Index";
import useStyles from "./Style";
import companyLogo from "../../assets/img/vikingstransparent_logo.png";
import { locationCity } from "../../constant/locationConstant";

function InviteManager(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("vishal+7@vaminfosys.com");
  const [emailOtp, setEmailOtp] = useState();
  const [showEmailOTPField, setShowEmailOTPField] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileError, setMobileError] = useState("");
  const theme = useTheme();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [reenterPasswordError, setReenterPasswordError] = useState("");

  const [emailOTPError, setEmailOTPError] = useState("");

  const [showSignupForm, setShowSignupForm] = useState(true);
  const [alreadyReg, setAlreadyReg] = useState(false);
  const [valid, setValid] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(30);
  const version = process.env.REACT_APP_BUILD_VERSION_NUMBER;
  const [mobileButtonClicked, setMobileButtonClicked] = useState(false);
  const [resendMobileCountdown, setMobileResendCountdown] = useState(30);
  const [validationModal, setValidationModal] = React.useState(false);
  const secretKey = process.env.REACT_APP_ENCRIPTION_KEY;
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_ENVIRONMENT;
  const [values, handleInput] = React.useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    managerRoom: "",
    managerFloor: "",
    managerStreet: "",
    managerCountry: "US",
    managerState: "",
    managerCity: "",
    managerPostalCode: "",
    checkStatus: null,
    Business_id: "",
  });

  const [validationError, setVlidationError] = React.useState({
    Invite_Mail_Status: false,
    businessName: false,
    dba: false,
    businessWebsite: false,
    bsinessDescription: false,
    businessRoom: false,
    businessFloor: false,
    businessStreet: false,
    businessCountry: false,
    businessState: false,
    businessCity: false,
    businessPostalCode: false,
    mailingRoom: false,
    mailingFloor: false,
    mailingStreet: false,
    mailingCountry: false,
    mailingState: false,
    mailingCity: false,
    mailingPostalCode: false,
    routingNumber: false,
    accountNumber: false,
    confirmAccountNumber: false,
    confirmRoutingNumber: false,
    feeRate: false,
    perTransactionFee: false,
    percentageFee: false,
    aboveTransactionValue: false,
    none: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
    email: false,
    managerRoom: false,
    managerFloor: false,
    managerStreet: false,
    managerCountry: false,
    managerState: false,
    managerCity: false,
    managerPostalCode: false,
    Manager_id: false,
    userId: false,
    Status_Code: false,
  });
  const [showEmailVerification, setShowEmailVerification] = useState(false);

  const encryptPayload = (payload) => {
    return CryptoJS.AES.encrypt(JSON.stringify(payload), secretKey).toString();
  };

  // Function to decrypt data
  const decryptData = (data, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(data, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };
  React.useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    let encryptedObject = queryParams.get("id");

    encryptedObject = encryptedObject?.replace(/ /g, "+");

    const encryptionKey = process.env.REACT_APP_ENCRIPTION_KEY;
    if (encryptedObject && encryptionKey) {
      try {
        const decryptedObject = decryptData(encryptedObject, encryptionKey);
        setEmail(decryptedObject.Email);
        if (decryptedObject.Phone_Number !== null) {
          setMobileNumber(decryptedObject.Phone_Number.replaceAll("-", ""));
        }
        handleInput({
          managerRoom: decryptedObject.Room,
          managerFloor: decryptedObject.Floor,
          managerStreet: decryptedObject.Street,
          managerCountry: "US",
          managerState: decryptedObject.State,
          managerCity: decryptedObject.City,
          managerPostalCode: decryptedObject.PostalCode,
          Business_id: decryptedObject.Business_id,
        });
        setFirstName(decryptedObject.First_Name);
        setLastName(decryptedObject.Last_Name);
        const payload = {
          email: decryptedObject.Email,
          checkStatus: true,
        };
        const encryptedPayload = encryptPayload(payload);
        props.addIndividualApiCall(
          { payload: encryptedPayload },
          (res) => {
            setLoading(false);
            if (!res.status) {
              history.push("/login");
              enqueueSnackbar(`${res.message}`, {
                variant: "success",
              });
            }
          },
          (err) => {
            setLoading(false);
            setAlreadyReg(true);
            setEmailError(
              <>
                <div style={{ textAlign: "center" }}>
                  <h2>
                    You are already registered.
                    <br /> Please click here to
                    <br />{" "}
                    <Button
                      variant="contained"
                      style={{
                        color: "#fff",
                        backgroundColor: theme.palette.primary.main,
                      }}
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Login
                    </Button>
                  </h2>
                </div>
              </>
            );
          }
        );
      } catch (error) {
        console.error("Error decrypting object:", error);
        setValid(false);
      }
    }
  }, [history.location.search]);

  React.useEffect(() => {
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
  }, []);

  React.useEffect(() => {
    let timer;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);

  React.useEffect(() => {
    let intervalId;
    if (mobileButtonClicked) {
      intervalId = setInterval(() => {
        setMobileResendCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [mobileButtonClicked]);

  React.useEffect(() => {
    if (resendMobileCountdown === 0) {
      setMobileButtonClicked(false);
    }
  }, [resendMobileCountdown]);

  const handleMobileNumberChange = (e) => {
    const input = e.target.value?.replace(/\D/g, "");
    const isValidMobileNumber = /^\d{0,10}$/.test(input); // Allow up to 10 digits
    if (isValidMobileNumber || input === "") {
      setMobileNumber(input);
      if (input.length < 10) {
        setMobileError("Mobile number must be 10 digits");
      } else {
        setMobileError("");
      }
    }
  };

  function formatPhoneNumber(v) {
    let r = v?.replace(/\D/g, "");
    if (r?.length > 11) {
      r = r?.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r?.length > 6) {
      r = r?.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r?.length > 3) {
      r = r?.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r?.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  const validateMobileNumber = (mobileNumber) =>
    // Updated validation to allow +1 followed by 10 digits for US numbers
    /^\s?\d{10}$/.test(mobileNumber);
  const validatePassword = (password) => {
    // Password must be at least 8 characters long
    if (password.length < 8) {
      return false;
    }

    // Check if password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return false;
    }

    // Check if password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    // Check if password contains at least one digit
    if (!/\d/.test(password)) {
      return false;
    }

    // Check if password contains at least one special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return false;
    }

    return true;
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    if (
      type === "firstName" ||
      type === "lastName" ||
      type === "businessName" ||
      type === "dba"
    ) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (type === "email") {
      value = value.toLowerCase();
    }
    allState[type] = value;

    if (type === "businessWebsite") {
      const res = value.match(
        /(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "mailingPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "managerPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "accountNumber") {
      if (value.length < 9 || value.length > 17) {
        error[type] = true;
        allState[type] = value;
      }
      if (value.length === 9) {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "confirmAccountNumber") {
      if (value !== values.accountNumber) {
        error[type] = true;
        allState[type] = value;
      }
      if (value === values.accountNumber) {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "confirmRoutingNumber") {
      if (value !== values.routingNumber) {
        error[type] = true;
        allState[type] = value;
      }
      if (value === values.routingNumber) {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "email") {
      const res = value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "routingNumber") {
      if (!/^[0-9]{9}$/.test(value)) {
        if (value.length !== 9) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 9) {
          error[type] = false;
          allState[type] = value;
        }
      }
    }
    if (type === "feeRate") {
      allState.feeRate = value;
      allState.perTransactionFee = "";
      allState.percentageFee = "";
      allState.aboveTransactionValue = "";
      allState.none = "";
    }
    // If "Surcharge" checkbox is clicked, uncheck "Discounted"
    if (type === "feeTypeSurcharge" && value) {
      allState.feeTypeDiscounted = false;
    }
    // If "Discounted" checkbox is clicked, uncheck "Surcharge"
    if (type === "feeTypeDiscounted" && value) {
      allState.feeTypeSurcharge = false;
    }
    if (type === "phoneNumber") {
      const formattedNumber = value?.replace(/\D/g, "").slice(0, 10);
      allState[type] = formattedNumber;
    }
    handleInput(allState);
    setVlidationError(error);
  };

  const checkValidationSubmit = (saveDraft = false, autoValidate = false) => {
    let validate = true;
    const error = { ...validationError };

    for (const key in values) {
      if (
        (values[key] === "" ||
          values[key] === null ||
          values[key] === undefined) &&
        (key === "firstName" ||
          key === "lastName" ||
          key === "managerCity" ||
          key === "managerPostalCode" ||
          key === "managerState" ||
          key === "managerCountry" ||
          key === "managerStreet" ||
          key === "email")
      ) {
        error[key] = true;
        validate = false;
      }
    }
    setVlidationError(error);
    if (!saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    return validate;
  };

  const isSignUpButtonDisabled = () => {
    const hasValidationError = Object.values(validationError).some(
      (error) => error
    );
    const isAnyFieldEmpty =
      !firstName ||
      !lastName ||
      !password ||
      !reenterPassword ||
      !agreeToTerms ||
      !mobileNumber ||
      !values.managerStreet ||
      !values.managerCountry ||
      !values.managerState ||
      !values.managerCity ||
      !values.managerPostalCode ||
      password !== reenterPassword;
    return hasValidationError || isAnyFieldEmpty;
  };

  const handleSignUp = () => {
    const isValidMobileNumber = validateMobileNumber(mobileNumber);
    const isValidForm = checkValidationSubmit(true);
    if (!isValidMobileNumber) {
      setValidationModal(true);
      return;
    }

    if (isValidForm) {
      const payload = {
        ...values,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: mobileNumber,
        password,
        checkStatus: false,
      };
      const encryptedPayload = encryptPayload(payload);
      props.addIndividualApiCall(
        { payload: encryptedPayload },
        (res) => {
          setLoading(false);
          if (res.status === true) {
            const bytes = CryptoJS.AES.decrypt(res.data, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            const parsedData =
              encryptionKey == "NO" ? res.data : JSON.parse(decryptedData);
            const payloadUpdate = {
              ...values,
              Profile_Id: parsedData.id,
              firstName: firstName,
              lastName: lastName,
              email: email,
              Phone_Number: mobileNumber,
              Password: password,
            };
            const encryptedPayloadUpdate = encryptPayload(payloadUpdate);
            props.managerUpdateCall(
              { payload: encryptedPayloadUpdate },
              (res) => {
                setLoading(false);
                if (res.status === true) {
                  setShowSignupForm(false);
                  setShowSuccessMessage(true);
                }
              },
              (err) => {
                setLoading(false);
                enqueueSnackbar(`${err.message}`, {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                });
              }
            );
            enqueueSnackbar(`${res.message}`, {
              variant: "success",
            });
          }
        },
        (err) => {
          setLoading(false);
          enqueueSnackbar(`${err.message}`, {
            variant: "error",
          });
        }
      );
    } else {
      setValidationModal(true);
    }
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleEmailOtpChange = (e) => setEmailOtp(e.target.value);
  const handleSendOTP = () => {
    const payload = {
      Email: email,
      OTP: null,
      Resend_OTP: false,
      First_Name: firstName,
      Last_Name: lastName,
    };
    props.sendOTP(
      payload,
      () => {
        setShowEmailOTPField(true);
        setEmailOTPError("");
        setResendCountdown(30);
      },
      (err) => {
        setEmailOTPError("Invalid email address or error sending OTP.");
      }
    );
  };

  const handleResendOTP = () => {
    const payload = {
      Email: email,
      OTP: null,
      Resend_OTP: true,
      First_Name: firstName,
      Last_Name: lastName,
    };
    props.sendOTP(
      payload,
      () => {
        setResendCountdown(30);
      },
      (err) => {
        setEmailOTPError("Error resending OTP.");
      }
    );
  };

  const handleEmailVerification = () => {
    const payload = {
      Email: email,
      OTP: emailOtp,
      Resend_OTP: false,
      firstName,
      lastName,
    };
    props.sendOTP(
      payload,
      () => {
        setShowEmailVerification(true);
        setEmailOTPError("");
      },
      (err) => {
        setEmailOTPError("Invalid OTP.");
      }
    );
  };

  const handlePasswordChange = (password) => {
    setPassword(password.trim());
    if (reenterPassword && password !== reenterPassword) {
      setReenterPasswordError("Passwords do not match.");
    } else {
      setReenterPasswordError("");
    }
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleReenterPasswordChange = (reenterPassword) => {
    setReenterPassword(reenterPassword.trim());
    if (password && password !== reenterPassword) {
      setReenterPasswordError("Passwords do not match.");
    } else {
      setReenterPasswordError("");
    }
  };

  const handleNext = () => {
    setIsEmailVerified(true);
    setShowSignupForm(true);
  };

  const handleErrorMessage = () => {
    setValidationModal(false);
  };

  return (
    <div
      className="dashboardTiles"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <Modal
        open={validationModal}
        onClose={() => setValidationModal(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className={classes.modalDiv}
          style={{ width: "1200px", height: "1000px" }}
        >
          <Card
            style={{
              width: "50%",
              maxWidth: "40%",
              height: "auto",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                minInlineSize: "-webkit-fill-available",
                textAlign: "start",
                height: "auto",
                maxHeight: "70vh",
                display: "flex",
                overflow: "auto",
                flexWrap: "nowrap",
                marginTop: "10%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={closeImage}
                style={{ width: "10%", marginBottom: "3%" }}
                alt=""
              />
              <div className={classes.successText}>
                <Typography
                  style={{ textAlign: "center" }}
                  color="error"
                  variant="subtitle2"
                  gutterBottom
                >
                  Please correct all validation errors before proceeding. Note
                  the highlighted fields.
                </Typography>
              </div>
              <br />
            </div>

            <CardActions style={{ margin: "5%" }}>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                size="sm"
                onClick={() => handleErrorMessage()}
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </div>
      </Modal>
      {valid === true ? (
        <div
          style={{
            background: "rgb(29, 33, 54)",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper
            elevation={3}
            style={{
              padding: "35px 35px",
              background: "#fff",
              width: "100%",
              maxWidth: "760px",
              height: "auto",
              minHeight: "440px",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <AppLoader loader={loading} />
              {/* Left Grid */}
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                container
                justifyContent="center"
                alignItems="center"
              >
                <div
                  className={classes.brandLogo}
                  style={{
                    width: "100%",
                    height: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={companyLogo}
                    alt="Company Logo"
                    style={{ maxWidth: "100%", height: "auto" }}
                    className="imgf"
                  />
                </div>
              </Grid>
              {/* Right Grid */}
              <Grid
                item
                xs={12}
                sm={6}
                md={8}
                style={{ borderLeft: "1px solid #EFEFF4" }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    disabled: classes.disabledInput,
                  },
                }}
              >
                {!isEmailVerified && !emailError && (
                  <>
                    <Grid item xs={8} style={{ display: "flex" }}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        disabled
                        InputProps={{
                          classes: { root: classes.activeInputColor },
                        }}
                      />
                      {showEmailVerification && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                          }}
                        >
                          <CheckCircleIcon style={{ color: "green" }} />
                          <span style={{ width: "10px", marginLeft: "5px" }}>
                            Verified
                          </span>
                        </div>
                      )}
                    </Grid>
                    {!alreadyReg && !showEmailVerification && (
                      <Grid
                        item
                        xs={8}
                        style={{
                          display: "flex",
                          margin: "10px 0px 10px 0px",
                          justifyContent: "center",
                        }}
                      >
                        {showEmailOTPField ? (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Grid item xs={12}>
                              <TextField
                                label="Email OTP"
                                variant="outlined"
                                fullWidth
                                value={emailOtp}
                                onChange={handleEmailOtpChange}
                                error={!!emailOTPError}
                                helperText={emailOTPError}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                              }}
                            >
                              <Grid
                                item
                                xs={5}
                                style={{
                                  margin: "10px 5px 10px 0px",
                                  alignSelf: "center",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    color: "#fff",
                                    backgroundColor: !emailOtp
                                      ? "fffff"
                                      : theme.palette.primary.main,
                                  }}
                                  InputProps={{
                                    classes: {
                                      disabled: classes.disabledInput,
                                    },
                                  }}
                                  fullWidth
                                  onClick={handleEmailVerification}
                                  disabled={!emailOtp}
                                >
                                  Verify
                                </Button>
                              </Grid>
                              <Grid item xs={5} style={{ alignSelf: "center" }}>
                                {resendCountdown > 0 ? (
                                  <p style={{ fontSize: "13px" }}>
                                    Resend One Time Code in {resendCountdown}{" "}
                                    seconds
                                  </p>
                                ) : (
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "#fff",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                    }}
                                    fullWidth
                                    onClick={handleResendOTP}
                                  >
                                    Resend
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Button
                            variant="contained"
                            style={{
                              color: "#fff",
                              backgroundColor: theme.palette.primary.main,
                            }}
                            onClick={handleSendOTP}
                          >
                            Send One Time Code
                          </Button>
                        )}
                      </Grid>
                    )}
                    {showEmailVerification && (
                      <Grid
                        item
                        xs={8}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            color: "#fff",
                            backgroundColor: theme.palette.primary.main,
                          }}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
                {isEmailVerified && showSignupForm && !alreadyReg && (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        InputProps={{
                          readOnly: true, // Make the field read-only
                          style: {
                            backgroundColor: "#f2f2f2", // Grey background color
                            color: "#666", // Grey text color
                            cursor: "not-allowed", // Disable cursor
                            height: "45px",
                          },
                        }}
                      />
                    </Grid>

                    {showSignupForm && showSignupForm && (
                      <>
                        {/* Additional Fields */}
                        <Grid item xs={6} style={{ height: "70px" }}>
                          <TextField
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            value={firstName}
                            onChange={(e) =>
                              setFirstName(e.target.value.trim())
                            }
                            InputProps={{
                              readOnly: true, // Make the field read-only
                              style: {
                                backgroundColor: "#f2f2f2", // Grey background color
                                color: "#666", // Grey text color
                                cursor: "not-allowed", // Disable cursor
                                height: "45px",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} style={{ height: "70px" }}>
                          <TextField
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value.trim())}
                            InputProps={{
                              readOnly: true, // Make the field read-only
                              style: {
                                backgroundColor: "#f2f2f2", // Grey background color
                                color: "#666", // Grey text color
                                cursor: "not-allowed", // Disable cursor
                                height: "45px",
                              },
                            }}
                          />
                        </Grid>
                        {showSignupForm && (
                          <>
                            <Grid item xs={6} style={{ height: "70px" }}>
                              <TextField
                                label="Mobile Number"
                                required
                                variant="outlined"
                                fullWidth
                                value={formatPhoneNumber(mobileNumber)}
                                onChange={handleMobileNumberChange}
                                error={!!mobileError}
                                helperText={
                                  mobileError
                                    ? "Please enter a 10 digit mobile number"
                                    : ""
                                }
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={6} style={{ height: "70px" }}>
                          <TextField
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused",
                              },
                              // required: true,
                              style: {
                                height: "45px",
                              },
                            }}
                            label="Room"
                            error={validationError.managerRoom}
                            value={values.managerRoom}
                            onChange={(e) =>
                              onChange(e.target.value, "managerRoom")
                            }
                            helperText={
                              validationError.managerRoom &&
                              "Please enter a valid value."
                            }
                            name="managerRoom"
                            id="managerRoom"
                          />
                        </Grid>
                        <Grid item xs={6} style={{ height: "70px" }}>
                          <TextField
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused",
                              },
                              // required: true,
                              style: {
                                height: "45px",
                              },
                            }}
                            label="Floor"
                            error={validationError.managerFloor}
                            value={values.managerFloor}
                            onChange={(e) =>
                              onChange(e.target.value, "managerFloor")
                            }
                            helperText={
                              validationError.managerFloor &&
                              "Please enter a valid value."
                            }
                            name="managerFloor"
                            id="managerFloor"
                          />
                        </Grid>
                        <Grid item xs={6} style={{ height: "70px" }}>
                          <TextField
                            required
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused",
                              },
                              style: {
                                height: "45px",
                              },
                            }}
                            label="Street Name"
                            error={validationError.managerStreet}
                            value={values.managerStreet}
                            onChange={(e) =>
                              onChange(e.target.value, "managerStreet")
                            }
                            helperText={
                              validationError.managerStreet &&
                              "Street is required."
                            }
                            name="managerStreet"
                            id="managerStreet"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          sm={6}
                          xs={6}
                          style={{ height: "70px" }}
                        >
                          <TextField
                            required
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused",
                              },
                              // required: true,
                              style: {
                                height: "45px",
                              },
                            }}
                            label="Country"
                            value={values.managerCountry}
                            error={validationError.managerCountry}
                            onChange={(e) =>
                              onChange(e.target.value, "managerCountry")
                            }
                            name="managerCountry"
                            id="managerCountry"
                            select
                          >
                            {props.formData.countryTypeList &&
                              props.formData.countryTypeList.Country_Data.map(
                                (key) => (
                                  <MenuItem
                                    className={classes.dialogBoxDropDown}
                                    value={key.Country_Code}
                                  >
                                    {key.Country_Name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          sm={6}
                          xs={6}
                          style={{ height: "70px" }}
                        >
                          {values.managerCountry === "US" ? (
                            <TextField
                              required
                              fullWidth
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused",
                                },
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused",
                                },
                                // required: true,
                                style: {
                                  height: "45px",
                                },
                              }}
                              label="State"
                              value={values.managerState}
                              error={validationError.managerState}
                              onChange={(e) =>
                                onChange(e.target.value, "managerState")
                              }
                              helperText={
                                validationError.managerState &&
                                "Please enter correct  state."
                              }
                              name="managerState"
                              id="managerState"
                              select
                            >
                              {locationCity
                                .sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((key) => (
                                  <MenuItem
                                    className={classes.dialogBoxDropDown}
                                    value={key.value}
                                  >
                                    {key.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          ) : (
                            <TextField
                              required
                              fullWidth
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused",
                                },
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused",
                                },
                                // required: true,
                                style: {
                                  height: "45px",
                                },
                              }}
                              label="State"
                              error={validationError.managerState}
                              value={values.managerState}
                              onChange={(e) =>
                                onChange(e.target.value, "managerState")
                              }
                              helperText={
                                validationError.managerState &&
                                "State is required."
                              }
                              name="managerState"
                              id="managerState"
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          sm={6}
                          xs={6}
                          style={{ height: "70px" }}
                        >
                          <TextField
                            required
                            fullWidth
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused",
                              },
                              // required: true,
                              style: {
                                height: "45px",
                              },
                            }}
                            label="City"
                            error={validationError.managerCity}
                            value={values.managerCity}
                            onChange={(e) =>
                              onChange(e.target.value, "managerCity")
                            }
                            helperText={
                              validationError.managerCity && "City is required."
                            }
                            name="managerCity"
                            id="managerCity"
                          />
                        </Grid>

                        <Grid
                          item
                          lg={6}
                          sm={6}
                          xs={6}
                          style={{ height: "70px" }}
                        >
                          <TextField
                            required
                            fullWidth
                            variant="outlined"
                            InputProps={{
                              inputProps: { min: 0 },
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused",
                              },
                              style: {
                                height: "45px",
                              },
                            }}
                            label="Postal Code"
                            error={validationError.managerPostalCode}
                            value={values.managerPostalCode}
                            onChange={(e) =>
                              (/^[0-9]\d*$/g.test(e.target.value) ||
                                e.target.value === "") &&
                              onChange(e.target.value, "managerPostalCode")
                            }
                            helperText={
                              validationError.managerPostalCode &&
                              "5 Digit Postal Code is required."
                            }
                            name="managerPostalCode"
                            id="managerPostalCode"
                            type="text"
                          />
                        </Grid>
                        <Divider
                          style={{
                            width: "100%",
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                        />

                        <Grid item xs={6}>
                          <TextField
                            label="Password"
                            variant="outlined"
                            fullWidth
                            type="password"
                            value={password}
                            onChange={(e) =>
                              handlePasswordChange(e.target.value)
                            }
                            error={!!passwordError}
                            style={{ height: "45px" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip
                                    title="Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one digit, and one special character."
                                    placement="top"
                                  >
                                    <HelpOutlineOutlinedIcon
                                      classes={{
                                        root: "HelpIcon",
                                        icon: "withHelpOutline",
                                      }}
                                      color="action"
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                </InputAdornment>
                              ),
                              required: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Confirm Password"
                            variant="outlined"
                            fullWidth
                            type="password"
                            value={reenterPassword}
                            onChange={(e) =>
                              handleReenterPasswordChange(e.target.value)
                            }
                            error={!!reenterPasswordError}
                            helperText={reenterPasswordError}
                            style={{ height: "45px" }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={agreeToTerms}
                                onChange={(e) =>
                                  setAgreeToTerms(e.target.checked)
                                }
                                color="primary"
                              />
                            }
                            label="Agree to terms and conditions"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            style={{
                              color: "#fff",
                              backgroundColor: isSignUpButtonDisabled()
                                ? "#E5E4E2"
                                : theme.palette.primary.main,
                            }}
                            fullWidth
                            onClick={handleSignUp}
                            disabled={isSignUpButtonDisabled()}
                          >
                            Sign Up
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
                {}
                {emailError}
                {showSuccessMessage && (
                  <div style={{ textAlign: "center" }}>
                    <h2>Sign Up Completed Successfully!!</h2>
                    <Button
                      variant="contained"
                      style={{
                        color: "#fff",
                        backgroundColor: theme.palette.primary.main,
                      }}
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Login
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </div>
      ) : (
        history.push("/invalidURL")
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 2rem",
          position: "absolute",
          bottom: "2rem",
          color: "white",
        }}
      >
        <i>Version: {version}</i>
        <i
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "7px",
            position: "absolute",
            right: "7rem",
          }}
        >
          <div>Powered By:</div>

          <img
            style={{
              width: "154px",
            }}
            className="imgf"
            src="/images/tsc_logo.png"
            alt="appName"
          />
        </i>
      </div>
    </div>
  );
}

InviteManager.propTypes = {
  managerUpdateCall: PropTypes.func,
  addIndividualApiCall: PropTypes.func,
  getCountryTypeList: PropTypes.func,
  sendOTP: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app,
  roles: app.roles,
  app: app.app,
  signUpData: app,
  merchantListData: app.genrateOfferPatient,
});

export default connect(mapStateToProps, {
  managerUpdateCall,
  addIndividualApiCall,
  getCountryTypeList,
  sendOTP,
})(InviteManager);
